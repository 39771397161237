import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Paper,
  Button,
  Divider,
  InputLabel,
} from "@mui/material";
import dayjs from "dayjs";
import moment from "moment";
import { Grid2 } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useSelector } from "react-redux";
import { Form, Formik, ErrorMessage  } from "formik";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { api, companyInfo, roleInfo } from "../../API/config";
import { axiosPrivate } from "../../../axios/axios";
import { toast } from "react-toastify";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { commonCss } from "../../../StyleConfig/CommonCss";
import Loading from "../../../Component/Loading";
import { useSearchParams } from "react-router-dom";
import * as Yup from 'yup';
import ErrorProps from "../../../Component/Errorprops";

const RadiationCertificate = ({
  handleCloseClick,
  certificateDetail,
  approvedData,
}) => {
  const [searchParams,setSearchParams]=useSearchParams()
  const [showFields, setShowFields] = useState(false);
  const [showFieldsforother, setShowFieldsOthers] = useState(false);
  const login = useSelector((state) => state.auth.loginInfo);
  const loginRoleId=String(login.roleIds).split(",")
  const [loading, setLoading] = useState(false);
  const [certificateInfo, setCertificateInfo] = useState({});
  const [approveInfo, setApproveInfo] = useState({
    engineerName: "",
    lpiName:"",
    certificateClosedName: "",
  });

  const [roleAccess, setRoleAccess] = useState({
    contractStaff:false,
    engineer:false,
    lpi:false,
    certClose:false,
    piContract:false
  });
  const [closedRemark,setClosedRemark]=useState("")

  const handleChange = (xRaybool, otherbool, setFieldValue) => {

    setFieldValue("isXRayMachine", xRaybool)
    setFieldValue("otherEquipment", otherbool)

  };

  // const handleChangeOther = (event) => {
  //   const isChecked = event.target.checked;
  //   setCertificateInfo((prevState) => ({
  //     ...prevState,
  //     isXRayMachine: false,
  //     otherEquipment: isChecked,
  //   }));
  //   setShowFields(false);
  //   setShowFieldsOthers(isChecked);
  // };
  useEffect(() => {
    if (certificateDetail && certificateDetail.id) {
      console.log("certificateDetail.id:", certificateDetail.id);
      getData();
    } else {
      console.log("certificateDetail is not available or doesn't have an id.");
    }
  }, [certificateDetail]);

  const getData = async () => {
    setLoading(true);
    const getDataLink = api.radiation.getById;
    await axiosPrivate
      .get(`${getDataLink}/${certificateDetail.id}`)
      .then((res) => {
        console.log("responce api", res.data.data);
        setCertificateInfo(res.data.data);
        setClosedRemark(res.data.data.certificateClosedReason)
        setApproveInfo({
          engineerName: res.data.data.isEngineerApproved? res.data.data?.engineerUserDetail?.firstName+" "+res.data.data?.engineerUserDetail?.lastName:"",
          lpiName: res.data.data.isLPIApproved? res.data.data?.lpiUserDetail?.firstName+" "+res.data.data?.lpiUserDetail?.lastName:"",
          certificateClosedName: res.data.data.isCertificateClosed? res.data.data?.certificateClosedByUserDetail?.firstName+" "+res.data.data?.certificateClosedByUserDetail?.lastName:"",
        })
        setRoleAccess({
    
          piContract:(loginRoleId.includes(roleInfo.contractStaff) && !approvedData.isContractApproved)||(loginRoleId.includes(roleInfo.pi)&&!approvedData.isPermitIssuerApproved),
      contractStaff:!loginRoleId.includes(roleInfo.contractStaff) || approvedData.isContractApproved,
      engineer:!loginRoleId.includes(roleInfo.engineer) || approvedData.isEngineerApproved || res.data.data.isEngineerApproved,
      lpi:!loginRoleId.includes(roleInfo.lpi) || approvedData.isLPIApproved || res.data.data.isLPIApproved,
      certClose:login.userId!==approvedData.permitHolderId || res.data.data.isCertificateClosed|| !res.data.data.isLPIApproved,
      })
        setLoading(false);
      })
      .catch((e) => {
        setCertificateInfo({});
        setLoading(false);
        console.log(e);
      });
  };
  const initialValue = {
    id: certificateInfo.id,
    formId: certificateInfo.formId,
    radioactiveMaterialSpecified: certificateInfo.radioactiveMaterialSpecified,
    activityOfMaterial: certificateInfo.activityOfMaterial,
    containedWithin: certificateInfo.containedWithin,
    isXRayMachine: certificateInfo.isXRayMachine,
    otherEquipment: certificateInfo.otherEquipment,
    additionalInformation: certificateInfo.additionalInformation,
    measuresToPrevent: certificateInfo.measuresToPrevent,
    additionalControlsRequired: certificateInfo.additionalControlsRequired,
    radiationEquipmentFromDate: certificateInfo.radiationEquipmentFromDate,
    radiationEquipmentToDate: certificateInfo.radiationEquipmentToDate,
    engineerId: certificateInfo.engineerId,
    isEngineerApproved: certificateInfo.isEngineerApproved,
    engineerApprovedDate: certificateInfo.engineerApprovedDate,
    engineerRemarks: certificateInfo.engineerRemarks,
    isCertificateClosed: certificateInfo.isCertificateClosed,
    certificateClosedBy: certificateInfo.certificateClosedBy,
    certificateClosedAt: certificateInfo.certificateClosedAt,
    certificateClosedReason: certificateInfo.certificateClosedReason,
    lpiId: certificateInfo.lpiId,
    isLPIApproved: certificateInfo.isLPIApproved,
    lpiRemarks: certificateInfo.lpiRemarks,
    lpiApprovedDate: certificateInfo.lpiApprovedDate,
    certificateValidFrom: certificateInfo.certificateValidFrom,
    certificateValidTo: certificateInfo.certificateValidTo,
    // have to do in backend
    manufacturer: certificateInfo.manufacturer,
    voltage: certificateInfo.voltage,
    currentValue: certificateInfo.currentValue,
    otherEquipmentText: certificateInfo.otherEquipmentText,
  };
  const onSubmit = async (value) => {
    let engineerLPIPI=loginRoleId.includes(roleInfo.engineer)||loginRoleId.includes(roleInfo.lpi)
   
    const payload = {
      ...value, // form values if any
      // isXRayMachine: certificateInfo.isXRayMachine, // ensure the updated checkbox value is included
      // otherEquipment: certificateInfo.otherEquipment, // include the updated otherEquipment value
      // [engineer?"isEngineerApproved":lpi?""]
      isApproved:engineerLPIPI?true:null
    };

    console.log(payload);

    const editLink = api.radiation.edit;
    await axiosPrivate
      .put(`${editLink}/${certificateDetail.id}`, payload)
      .then((res) => {
        console.log(res);
       
        toast.success("Successfully Updated");
        handleCloseClick()
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleCloseCertificate=async()=>{
    const closeCertLink=api.radiation.closeCertificate
    const postData={
        id: certificateInfo.id,
        isCertificateClosed: true,
        certificateClosedReason: closedRemark
    }
    await axiosPrivate.put(`${closeCertLink}/${certificateInfo.id}`,postData).then(res=>{
      toast.success("Certificate Closed")
      handleCloseClick()
    }).catch(e=>{
      console.log(e)
    })
  }
const validationSchema = Yup.object().shape({
  radioactiveMaterialSpecified: Yup.string()
    .required('This field is required'),
  activityOfMaterial: Yup.string()
    .required('This field is required'),
  containedWithin: Yup.string()
    .required('This field is required'),
   
  manufacturer: Yup.string().when('isXRayMachine', {
      is: (value) => value === true,
      then: (schema) => schema.required('This field is required'),
      otherwise:  (schema) => schema.notRequired(),
    }),

  voltage: Yup.string().when('isXRayMachine', {
    is: (value) => value === true,
    then: (schema) => schema.required('This field is required'),
    otherwise:  (schema) => schema.notRequired(),
    }),

  currentValue: Yup.string().when('isXRayMachine', {
    is: (value) => value === true,
    then: (schema) => schema.required('This field is required'),
    otherwise:  (schema) => schema.notRequired(),
    }),
  otherEquipmentText: Yup.string().when('otherEquipment', {
    is: (value) => value === true,
      then: (schema) => schema.required('This field is required'),
      otherwise:  (schema) => schema.notRequired(),
    }),

  additionalInformation: Yup.string()
    .required('This field is required'),

  radiationEquipmentToDate: Yup.string()
    .required('This field is required'),

  radiationEquipmentFromDate: Yup.string()
    .required('This field is required'),

  additionalControlsRequired: Yup.string()
    .required('This field is required'),

  measuresToPrevent: Yup.string()
    .required('This field is required'),

  certificateValidFrom: Yup.string()
    .required('This field is required'),

  certificateValidTo: Yup.string()
    .required('This field is required'),
});
  return (
    <>
      {!loading ? (
        <Formik initialValues={initialValue} onSubmit={onSubmit}  validationSchema={validationSchema}>
          {({ setFieldValue, values, touched, errors, setFieldTouched }) => (
            <Form>
              <Grid2 container size={12} spacing={"1rem"}>
                <Grid2 container size={12} alignItems={"center"}>
                  <Grid2 container size={4}>
                    <Box
                      sx={{
                        backgroundColor: commonCss.shadeGreen,
                        display: "flex",
                        alignItems: "cente",
                        p: 1,
                        cursor: "pointer",
                      }}
                      onClick={handleCloseClick}
                    >
                      <ArrowBack color={commonCss.green} />
                    </Box>
                  </Grid2>
                  <Grid2 container size={4} justifyContent={"center"}>
                    <Typography variant="h5" fontWeight="bold" color="white">
                      Radiation Certificate
                    </Typography>
                  </Grid2>
                  <Grid2 container size={4} justifyContent={"flex-end"}>
                    <Box
                      sx={{
                        backgroundColor: commonCss.shadeGreen,
                        p: 1,
                      }}
                    >
                      <Typography variant="body1" fontWeight="bold">
                        Permit To Work No.{" "}
                        <span style={{ color: "#e53e3e" }}>{searchParams.get("no")}</span>
                      </Typography>
                    </Box>
                  </Grid2>
                  <Divider
                    sx={{
                      borderColor: commonCss.red,
                      borderWidth: 1,
                      width: "100%",
                    }}
                  />
                </Grid2>
                <Grid2 container size={12} alignItems={"center"}>
                  <Typography color="white" variant="body2">
                    Valid only for routine activities involving ionise radiation
                    source (To be completed only by authorised persons)
                  </Typography>
                </Grid2>
                <Grid2 container size={12} alignItems={"center"}>
                  <Typography color="white" variant="body2">
                    (To be completed only by authorised persons)
                  </Typography>
                </Grid2>
                <Grid2 container size={12}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >

                   <Grid2 container spacing={2} mb={2}>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                                <Typography
                                  variant="body2"
                                  fontWeight={commonCss.labelFontWeight}
                                  color={commonCss.labelInputColor}
                                >
                                  Radioactive material being used, specify
                                  <Typography
                                    component="span"
                                    color="error"
                                    fontWeight="bold"
                                    sx={{ ml: 0.5 }}
                                  >
                                    *
                                  </Typography>
                                </Typography>
                              </InputLabel>

                              <TextField
                                fullWidth
                                variant="outlined"
                                name="radioactiveMaterialSpecified"
                                value={values.radioactiveMaterialSpecified}
                                onChange={(e) =>
                                  setFieldValue("radioactiveMaterialSpecified", e.target.value)
                                }
                                //onBlur={() => setFieldTouched("radioactiveMaterialSpecified", true)}
                                slotProps={{
                                  input: {
                                    readOnly: roleAccess.contractStaff,
                                  },
                                }}
                                size="small"
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    backgroundColor: roleAccess.contractStaff
                                      ? commonCss.readOnlyBGColor
                                      : commonCss.inputBackgroundColor,
                                    borderRadius: commonCss.inputBorderRadius,
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: commonCss.inputBorderColor,
                                  },
                                }}
                              />
                                 { errors.radioactiveMaterialSpecified && (
                          <ErrorMessage name="radioactiveMaterialSpecified">
                           {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                          )}
                            </Grid2>


                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Activity of material
                            <Typography
                            component="span"
                            color="error"
                            fontWeight="bold"
                            sx={{ ml: 0.5 }}
                          >
                            *
                          </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          name="activityOfMaterial"
                          value={values.activityOfMaterial}
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          onChange={(e) =>
                            setFieldValue("activityOfMaterial", e.target.value)
                          }
                          //onBlur={() => setFieldTouched("activityOfMaterial", true)}
                          size="small"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                            {errors.activityOfMaterial && (
                          <ErrorMessage name="activityOfMaterial">
                           {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                          )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Contained within
                            <Typography
                            component="span"
                            color="error"
                            fontWeight="bold"
                            sx={{ ml: 0.5 }}
                          >
                            *
                          </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          name="containedWithin"
                          value={values.containedWithin}
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          onChange={(e) =>
                            setFieldValue("containedWithin", e.target.value)
                          }
                         // onBlur={() => setFieldTouched("containedWithin", true)}
                          size="small"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                             { errors.containedWithin && (
                          <ErrorMessage name="containedWithin">
                           {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                          )}
                      </Grid2>
                    </Grid2>
                     {/* {JSON.stringify({xy:values.isXRayMachine, yx:values.otherEquipment})} */}
                    <Grid2 container size={{ xs: 12}} spacing={2} mb={2}>
                      <Grid2 size={{ xs: 12, md: 3 }}>
                        <Grid2 item>
                          <FormControlLabel
                           sx={{"& .MuiFormControlLabel-label.Mui-disabled":{
                            color:"black"
                           }}}
                            control={
                              <Checkbox
                                disabled={roleAccess.contractStaff}
                                checked={values.isXRayMachine} // ensure it doesn't throw error when undefined
                                onChange={(e) => { handleChange(e.target.checked, false, setFieldValue)}}
                                sx={{
                                  color: commonCss.green,
                                  "&.Mui-checked": {
                                    color: commonCss.green,
                                  },
                                }}
                              />
                            }
                            label="X-ray machine"
                          />
                        </Grid2>
                        <Grid2 item>
                          <FormControlLabel
                           sx={{"& .MuiFormControlLabel-label.Mui-disabled":{
                            color:"black"
                           }}}
                            control={
                              <Checkbox
                              disabled={roleAccess.contractStaff}
                                checked={
                                  values.otherEquipment
                                } 
                                onChange={(e) => {handleChange(false, e.target.checked, setFieldValue)}}
                                sx={{
                                  color: commonCss.green,
                                  "&.Mui-checked": {
                                    color: commonCss.green,
                                  },
                                }}
                              />
                            }
                            label="Other equipment"
                          />
                        </Grid2>
                      </Grid2>

                      {values.isXRayMachine && (
                        <>
                          <Grid2 size={{ xs: 12, md: 5 }}>
                            <InputLabel sx={{ pl: commonCss.labelPadding }}>
                              <Typography
                                variant="body2"
                                fontWeight={commonCss.labelFontWeight}
                                color={commonCss.labelInputColor}
                              >
                                Manufacturer/Model
                                <Typography
                            component="span"
                            color="error"
                            fontWeight="bold"
                            sx={{ ml: 0.5 }}
                          >
                            *
                          </Typography>
                              </Typography>
                            </InputLabel>
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              name="manufacturer"
                              value={values.manufacturer}
                           //   onBlur={() => setFieldTouched("manufacturer", true)}
                              slotProps={{
                                input:{
                                  readOnly:roleAccess.contractStaff
                                }
                              }}
                              onChange={(e) =>
                                setFieldValue("manufacturer", e.target.value)
                              }
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  backgroundColor:roleAccess.contractStaff?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor,
                                  borderRadius: commonCss.inputBorderRadius,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                            />
                              { errors.manufacturer && (
                          <ErrorMessage name="manufacturer">
                           {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                          )}
                          </Grid2>
                          <Grid2 size={{ xs: 12, md: 2 }}>
                            <InputLabel sx={{ pl: commonCss.labelPadding }}>
                              <Typography
                                variant="body2"
                                fontWeight={commonCss.labelFontWeight}
                                color={commonCss.labelInputColor}
                              >
                                Voltage
                                <Typography
                            component="span"
                            color="error"
                            fontWeight="bold"
                            sx={{ ml: 0.5 }}
                          >
                            *
                          </Typography>
                              </Typography>
                            </InputLabel>
                            <TextField
                              fullWidth
                              variant="outlined"
                              placeholder="KV"
                              size="small"
                              name="voltage"
                              value={values.voltage}
                              slotProps={{
                                input:{
                                  readOnly:roleAccess.contractStaff
                                }
                              }}
                              onChange={(e) =>
                                setFieldValue("voltage", e.target.value)
                              }
                              // onBlur={() => setFieldTouched("voltage", true)}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  backgroundColor:roleAccess.contractStaff?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                                  borderRadius: commonCss.inputBorderRadius,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              inputProps={{
                                style: { textAlign: "right" },
                              }}
                            />
                          {errors.voltage && (
                          <ErrorMessage name="voltage">
                           {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                          )}
                          </Grid2>
                          <Grid2 size={{ xs: 12, md: 2 }}>
                            <InputLabel sx={{ pl: commonCss.labelPadding }}>
                              <Typography
                                variant="body2"
                                fontWeight={commonCss.labelFontWeight}
                                color={commonCss.labelInputColor}
                              >
                                Current
                                <Typography
                            component="span"
                            color="error"
                            fontWeight="bold"
                            sx={{ ml: 0.5 }}
                          >
                            *
                          </Typography>
                              </Typography>
                            </InputLabel>
                            <TextField
                              fullWidth
                              variant="outlined"
                              placeholder="mA"
                              size="small"
                              name="currentValue"
                              value={values.currentValue}
                              slotProps={{
                                input:{
                                  readOnly:roleAccess.contractStaff
                                }
                              }}
                              onChange={(e) =>
                                setFieldValue("currentValue", e.target.value)
                              }
                            //  onBlur={() => setFieldTouched("currentValue", true)}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  backgroundColor:roleAccess.contractStaff?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor,
                                  borderRadius: commonCss.inputBorderRadius,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              inputProps={{
                                style: { textAlign: "right" },
                              }}
                            />
                                 { errors.currentValue && (
                          <ErrorMessage name="currentValue">
                           {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                          )}
                          </Grid2>
                        </>
                      )}

                      {values.otherEquipment && (
                        <Grid2 size={{ xs: 12, md: 6 }}>
                          <Grid2 item xs={6} md={6}>
                            <InputLabel>
                              <Typography
                                variant="body2"
                                fontWeight="bold"
                                color="black"
                              >  <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography></Typography>
                            </InputLabel>
                            <TextField
                              fullWidth
                              variant="outlined"
                              multiline
                              size="small"
                              name="otherEquipmentText"
                              value={values.otherEquipmentText}
                              slotProps={{
                                input:{
                                  readOnly:roleAccess.contractStaff
                                }
                              }}
                              onChange={(e) =>
                                setFieldValue(
                                  "otherEquipmentText",
                                  e.target.value
                                )
                              }
                             // onBlur={() => setFieldTouched("otherEquipmentText", true)}
                              rows={2}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  backgroundColor:roleAccess.contractStaff?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor,
                                  borderRadius: commonCss.inputBorderRadius,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                            />
                                   {errors.otherEquipmentText && (
                          <ErrorMessage name="otherEquipmentText">
                           {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                          )}
                          </Grid2>
                        </Grid2>
                      )}
                    </Grid2>
                    <Grid2 container size={{ xs: 12}} spacing={2} mb={2}>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: 0.5 }}>
                          <Typography
                            variant="body2"
                            fontWeight="500"
                            color="black"
                          >
                            Additional information about operation of equipment
                            <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          name="additionalInformation"
                          value={values.additionalInformation}
                          slotProps={{
                            input:{
                              readOnly:roleAccess.contractStaff
                            }
                          }}
                          onChange={(e) =>
                            setFieldValue(
                              "additionalInformation",
                              e.target.value
                            )
                          }
                          //onBlur={() => setFieldTouched("additionalInformation", true)}
                          multiline
                          size="small"
                          rows={2}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor:roleAccess.contractStaff?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                                  {errors.additionalInformation && (
                          <ErrorMessage name="additionalInformation">
                           {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                          )}
                      </Grid2>

                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: 0.5 }}>
                          <Typography
                            variant="body2"
                            fontWeight="500"
                            color="black"
                          >
                            Measures to prevent and mitigate exposure
                            <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          name="measuresToPrevent"
                          value={values.measuresToPrevent}
                          slotProps={{
                            input:{
                              readOnly:roleAccess.contractStaff
                            }
                          }}
                          onChange={(e) =>
                            setFieldValue("measuresToPrevent", e.target.value)
                          }
                          //onBlur={() => setFieldTouched("measuresToPrevent", true)}
                          multiline
                          size="small"
                          rows={2}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor:roleAccess.contractStaff?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                        { errors.measuresToPrevent && (
                          <ErrorMessage name="measuresToPrevent">
                           {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                          )}
                      </Grid2>
                    </Grid2>

                    <Grid2 container size={{ xs: 12}} spacing={2} mb={2} alignItems={"flex-end"}>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: 0.5 }}>
                          <Typography
                            variant="body2"
                            fontWeight="500"
                            color="black"
                          >
                            Additional controls required
                            <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={values.additionalControlsRequired}
                          name="additionalControlsRequired"
                          onChange={(e) =>
                            setFieldValue(
                              "additionalControlsRequired",
                              e.target.value
                            )
                          }
                          //onBlur={() => setFieldTouched("additionalControlsRequired", true)}
                          slotProps={{
                            input:{
                              readOnly:roleAccess.contractStaff
                            }
                          }}
                          multiline
                          size="small"
                          rows={2}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor:roleAccess.contractStaff?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                         { errors.additionalControlsRequired && (
                          <ErrorMessage name="additionalControlsRequired">
                           {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                          )}
                      </Grid2>
                      <Grid2 container spacing={2} size={{ xs: 12, md: 6 }}>
                        <Grid2 size={12}>
                          <InputLabel sx={{ pl: commonCss.labelPadding }}>
                            <Typography
                              variant="body2"
                              fontWeight={commonCss.labelFontWeight}
                              color={commonCss.labelInputColor}
                            >
                              Radiation equipment will be used between
                            </Typography>
                          </InputLabel>
                        </Grid2>

                        <Grid2 size={{ xs: 12, md: 6 }}>
                          <InputLabel sx={{ pl: commonCss.labelPadding }}>
                            <Typography
                              variant="body2"
                              fontWeight={commonCss.labelFontWeight}
                              color={commonCss.labelInputColor}
                            >
                              From
                              <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                            </Typography>
                          </InputLabel>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoItem>
                              <DateTimePicker
                                ampm={false}
                                format="DD/MM/YYYY HH:mm"
                                readOnly={roleAccess.contractStaff}
                                value={
                                  values.radiationEquipmentFromDate !== null
                                    ? dayjs(values.radiationEquipmentFromDate)
                                    : null
                                }
                                viewRenderers={{
                                  hours: renderTimeViewClock,
                                  minutes: renderTimeViewClock,
                                  seconds: renderTimeViewClock,
                                }}
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    variant: "outlined",
                                  },
                                }}
                                onChange={(newValue) => {
                                  console.log(newValue);
                                  if (newValue !== null) {
                                    if (`${newValue.$d}` !== "Invalid Date") {
                                      const dateFrom = moment(
                                        newValue.$d
                                      ).utc();
                                      setFieldValue(
                                        "radiationEquipmentFromDate",
                                        dateFrom
                                      );

                                      // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                      // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                      // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                      // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                      // setFieldValue("installationDate", dateFormat);
                                    }
                                  }
                                }}
                             //   onClose={() => setFieldTouched("radiationEquipmentFromDate", true)}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    borderRadius: commonCss.inputBorderRadius,
                                    backgroundColor:roleAccess.contractStaff?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor,
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: commonCss.inputBorderColor,
                                  },
                                }}
                                fullWidth
                              />
                                     { errors.radiationEquipmentFromDate && (
                          <ErrorMessage name="radiationEquipmentFromDate">
                           {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                          )}
                            </DemoItem>
                          </LocalizationProvider>
                        </Grid2>

                        <Grid2 size={{ xs: 12, md: 6 }}>
                          <InputLabel sx={{ pl: commonCss.labelPadding }}>
                            <Typography
                              variant="body2"
                              fontWeight={commonCss.labelFontWeight}
                              color={commonCss.labelInputColor}
                            >
                              Until
                              <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                            </Typography>
                          </InputLabel>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoItem>
                              <DateTimePicker
                                ampm={false}
                                format="DD/MM/YYYY HH:mm"
                                readOnly={roleAccess.contractStaff}
                                value={
                                  certificateInfo.radiationEquipmentToDate
                                    ? dayjs(
                                        certificateInfo.radiationEquipmentToDate
                                      )
                                    : null
                                }
                                onChange={(newValue) => {
                                  console.log(newValue);
                                  if (newValue !== null) {
                                    if (`${newValue.$d}` !== "Invalid Date") {
                                      const dateFrom = moment(
                                        newValue.$d
                                      ).utc();
                                      setFieldValue(
                                        "radiationEquipmentToDate",
                                        dateFrom
                                      );
                                    }
                                  }
                                }}
                               // onClose={() => setFieldTouched("radiationEquipmentToDate", true)}
                                viewRenderers={{
                                  hours: renderTimeViewClock,
                                  minutes: renderTimeViewClock,
                                  seconds: renderTimeViewClock,
                                }}
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    variant: "outlined",
                                  },
                                }}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    backgroundColor:roleAccess.contractStaff?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                                    borderRadius: commonCss.inputBorderRadius,
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: commonCss.inputBorderColor,
                                  },
                                }}
                                fullWidth
                              />
                              { errors.radiationEquipmentToDate && (
                          <ErrorMessage name="radiationEquipmentToDate">
                           {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                          )}
                            </DemoItem>
                          </LocalizationProvider>
                        </Grid2>
                      </Grid2>
                    </Grid2>
                    <Grid2 container spacing={2} mb={2}>
                    <Grid2 size={{ xs: 12,md:6 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Validity From 
                          
                          <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                            </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DatePicker
                              ampm={false}
                              format="DD/MM/YYYY"
                              readOnly={!roleAccess.piContract}
                              // minDate={approvedData.piPermitValidFrom}
                              // maxDate={approvedData.piPermitValidTo}
                              value={
                                values.certificateValidFrom !== null
                                  ? dayjs(values.certificateValidFrom)
                                  : null
                              }
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              onChange={(newValue) => {
                                console.log(newValue);
                                if (newValue !== null) {
                                  if (`${newValue.$d}` !== "Invalid Date") {
                                    const dateFrom = moment(newValue.$d)
                                    setFieldValue(
                                      "certificateValidFrom",
                                      dateFrom
                                    );
                                   

                                    // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                    // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                    // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                    // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                    // setFieldValue("installationDate", dateFormat);
                                  }
                                }
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor:!roleAccess.piContract?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 size={{ xs: 12,md:6 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Validity Utill
                         
                          <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                            </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DatePicker
                              ampm={false}
                              format="DD/MM/YYYY"
                              readOnly={!roleAccess.piContract}
                              // minDate={approvedData.piPermitValidFrom}
                              // maxDate={approvedData.piPermitValidTo}
                              value={
                                values.certificateValidTo !== null
                                  ? dayjs(values.certificateValidTo)
                                  : null
                              }
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              onChange={(newValue) => {
                                console.log(newValue);
                                if (newValue !== null) {
                                  if (`${newValue.$d}` !== "Invalid Date") {
                                    const dateFrom = moment(newValue.$d)
                                    setFieldValue(
                                      "certificateValidTo",
                                      dateFrom
                                    );

                                    // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                    // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                    // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                    // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                    // setFieldValue("installationDate", dateFormat);
                                  }
                                }
                              }}
// <<<<<<< Feature_22/11/24ScreenWork
// =======
//                               onClose={() => setFieldTouched("certificateValidTo", true)}
// >>>>>>> main
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor:!roleAccess.piContract?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={4}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"1rem"}>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.mainLabelHeading}
                          color={commonCss.red}
                          textAlign={"center"}
                        >
                          Engineer
                        </Typography>
                        <Divider
                          sx={{
                            borderColor: commonCss.red,
                            borderWidth: 1,
                            width: "100%",
                            mt: 1,
                          }}
                        />
                      </Grid2>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.normalSentenceWeight}
                          color={commonCss.normalSenternceColor}
                          sx={{ textTransform: "none" }}
                        >
                          I certify that I have reviewed the proposed work and I
                          am satisfied that it is adequately described on the
                          permit to work and that the precautions detailed are
                          adequate and clearly defined
                        </Typography>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Name
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          value={approveInfo.engineerName}
                          slotProps={{
                            input: {
                              readOnly: true, // Set readOnly to true
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.engineer?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Date/Time
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              format="DD/MM/YYYY HH:mm"
                              value={
                                values.isEngineerApproved
                                  ? dayjs(values.engineerApprovedDate)
                                  : null
                              }
                              readOnly
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor:roleAccess.engineer?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 container size={12} justifyContent={"center"}>
                        <Button
                        type="submit"
                          variant="contained"
                          // disabled={values.isEngineerApproved}
                          sx={{
                            backgroundColor: commonCss.green,
                            borderRadius: commonCss.buttonBorderRadius,
                            textTransform: "none",
                            padding: "0.6rem 1rem",
                            color: commonCss.buttonTextColor,
                            width: "15vw",
                            display: roleAccess.engineer ?"none":"default"
                          }}
                          // onClick={() => {
                          //   setFieldValue("isEngineerApproved", true);
                          //   setFieldValue("engineerId", login.userId);
                          //   setFieldValue(
                          //     "engineerApprovedDate",
                          //     moment().format("YYYY-MM-DDTHH:mm:ss")
                          //   );
                          //   setApproveInfo({
                          //     ...approveInfo,
                          //     engineerName:
                          //       login.firstName + " " + login.lastName,
                          //   });
                          // }}
                        >
                          Approve
                        </Button>
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>

                <Grid2 container size={4}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"1rem"}>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.mainLabelHeading}
                          color={commonCss.red}
                          textAlign={"center"}
                        >
                          LPI
                        </Typography>
                        <Divider
                          sx={{
                            borderColor: commonCss.red,
                            borderWidth: 1,
                            width: "100%",
                            mt: 1,
                          }}
                        />
                      </Grid2>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.normalSentenceWeight}
                          color={commonCss.normalSenternceColor}
                          sx={{ textTransform: "none" }}
                        >
                          Hot Work is described in the permit to work is allowed
                          between:
                        </Typography>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Name
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          value={approveInfo.lpiName}
                          slotProps={{
                            input: {
                              readOnly: true, // Set readOnly to true
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor:roleAccess.lpi?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Date/Time
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              readOnly
                              value={
                                values.isLPIApproved
                                  ? dayjs(values.lpiApprovedDate)
                                  : null
                              }
                              format="DD/MM/YYYY HH:mm"
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor:roleAccess.lpi?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            From
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DatePicker
                              ampm={false}
                              format="DD/MM/YYYY"
                              readOnly={roleAccess.lpi}
                              minDate={approvedData.piPermitValidFrom!==null?dayjs(approvedData.piPermitValidFrom):dayjs(moment().add(1,"year"))}
                              maxDate={approvedData.piPermitValidUpTo!==null?dayjs(approvedData.piPermitValidUpTo):dayjs(moment().add(1,"year"))}
                              value={
                                values.certificateValidFrom !== null
                                  ? dayjs(values.certificateValidFrom)
                                  : null
                              }
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              onChange={(newValue) => {
                                console.log(newValue);
                                if (newValue !== null) {
                                  if (`${newValue.$d}` !== "Invalid Date") {
                                    const dateFrom = moment(newValue.$d)
                                    setFieldValue(
                                      "certificateValidFrom",
                                      dateFrom
                                    );

                                    // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                    // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                    // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                    // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                    // setFieldValue("installationDate", dateFormat);
                                  }
                                }
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor:roleAccess.lpi?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Until
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DatePicker
                              ampm={false}
                              format="DD/MM/YYYY"
                              readOnly={roleAccess.lpi}
                              minDate={approvedData.piPermitValidFrom!==null?dayjs(approvedData.piPermitValidFrom):dayjs(moment().add(1,"year"))}
                              maxDate={approvedData.piPermitValidUpTo!==null?dayjs(approvedData.piPermitValidUpTo):dayjs(moment().add(1,"year"))}
                              value={
                                values.certificateValidTo !== null
                                  ? dayjs(values.certificateValidTo)
                                  : null
                              }
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              onChange={(newValue) => {
                                console.log(newValue);
                                if (newValue !== null) {
                                  if (`${newValue.$d}` !== "Invalid Date") {
                                    const dateFrom = moment(newValue.$d)
                                    setFieldValue(
                                      "certificateValidTo",
                                      dateFrom
                                    );

                                    // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                    // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                    // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                    // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                    // setFieldValue("installationDate", dateFormat);
                                  }
                                }
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor:roleAccess.lpi?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 container size={12} justifyContent={"center"}>
                        <Button
                        type="submit"
                          variant="contained"
                          disabled={values.isLPIApproved}
                          sx={{
                            backgroundColor: commonCss.green,
                            borderRadius: commonCss.buttonBorderRadius,
                            textTransform: "none",
                            padding: "0.6rem 1rem",
                            color: commonCss.buttonTextColor,
                            width: "15vw",
                            display:roleAccess.lpi?"none":"default"
                          }}
                          // onClick={() => {
                          //   setFieldValue("isLPIApproved", true);
                          //   setFieldValue("lpiId", login.userId);
                          //   setFieldValue(
                          //     "lpiApprovedDate",
                          //     moment().format("YYYY-MM-DDTHH:mm:ss")
                          //   );
                          //   setApproveInfo({
                          //     ...approveInfo,
                          //     lpiName: login.firstName + " " + login.lastName,
                          //   });
                          // }}
                        >
                          Approve
                        </Button>
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={4} >
            <Paper elevation={3}
            sx={{borderRadius:commonCss.paperBorderRadius,p:1.5,background:commonCss.shadeLinearGradient,width:"100%"}}
            >
          <Grid2 container size={12} spacing={"1rem"} >
              <Grid2 item xs={12}>
                <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none"}}>
                Radiation Certificate is certificate and permit to work are
                  withdrawn:
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Name
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          slotProps={{
                            input: {
                              readOnly: true,
                            },
                          }}
                          value={
                            values.isCertificateClosed !== null
                              ? approveInfo.certificateClosedName
                              : null
                          }
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor:roleAccess.certClose?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Date/Time
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              format="DD/MM/YYYY HH:mm"
                              readOnly
                              value={
                                values.isCertificateClosed
                                  ? dayjs(values.certificateClosedAt)
                                  : null
                              }
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor:roleAccess.certClose?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Reason
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="reason"
                          value={closedRemark}
                          slotProps={{
                            input:{
                              readOnly:roleAccess.certClose
                            }
                          }}
                          onChange={(e)=>{setClosedRemark(e.target.value)}}
                          multiline
                          minRows={2}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.certClose?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 container size={12} justifyContent={"center"}>
                        <Button
                          variant="contained"
                          disabled={values.isCertificateClosed}
                          sx={{
                            backgroundColor: commonCss.green,
                            borderRadius: commonCss.buttonBorderRadius,
                            textTransform: "none",
                            padding: "0.6rem 1rem",
                            color: commonCss.buttonTextColor,
                            width: "15vw",
                            display:roleAccess.certClose?"none":"default"
                          }}
                          onClick={() => {
                            handleCloseCertificate()
                          }}
                        >
                          Close
                        </Button>
                      </Grid2>
            </Grid2>
            </Paper>
         
        </Grid2>
                <Grid2 container size={12} justifyContent={"flex-end"} sx={{display:approvedData.isContractApproved?"none":"default"}}>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      backgroundColor: commonCss.green,
                      borderRadius: commonCss.buttonBorderRadius,
                      textTransform: "none",
                      padding: "0.6rem 1rem",
                      color: commonCss.buttonTextColor,
                      width: "10vw",
                    }}
                  >
                    Submit
                  </Button>
                </Grid2>
              </Grid2>
            </Form>
          )}
        </Formik>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default RadiationCertificate;
