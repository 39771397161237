import {
  Button,
  Divider,
  Grid2,
  Paper,
  Typography,
  TextField,
  Select,
  Checkbox,
  ListItem,
  ListItemText,
  ListItemIcon,
  MenuItem,
  List,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Box,
  IconButton,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { commonCss } from "../../../StyleConfig/CommonCss";
import { ArrowBack, EditOutlined, FileUploadOutlined } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import NormalTableWithRadius from "../../../Component/NormalTableWithRadius";
import dayjs from "dayjs";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { Form, Formik, ErrorMessage } from "formik";
import { useSelector } from "react-redux";
import { api, companyInfo, roleInfo } from "../../API/config";
import { axiosPrivate } from "../../../axios/axios";
import GasTestComponent from "./GasTestComponent";
import Loading from "../../../Component/Loading"
import { toast } from "react-toastify";
import * as Yup from 'yup';
import ErrorProps from "../../../Component/Errorprops";
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';

const HotWork = ({ handleCloseClick,certificateDetail, approvedData }) => {
  const [searchParams,setSearchParams]=useSearchParams()
  const [certificateInfo,setCertificateInfo]=useState({})
  const login = useSelector((state) => state.auth.loginInfo);
  const loginRoleId=String(login.roleIds).split(",")
  const [agt,setAgt]=useState({agtBol:false,agtType:"",agtCom:"",agtId:null})
  const [confirmIndex, setConfirmIndex] = useState(null);
  const [autoAgt,setAutoAgt]=useState("")
  const [agtData,setAgtData]=useState([])
  const [loading,setLoading]=useState(false)
  const [specifiedFrequency,setSpecifiedFreq]=useState(false)
  const [reassignagt,setReAssignAgt]=useState({agtBol:false,agtType:"",agtCom:"",agtId:null})
  const [reAssign,setReassign]=useState(false)

  
  const [editGas, setEditGas] = useState({ bol: false, data: {} });
  const [gasData, setGasData] = useState([]);
  const [approveInfo, setApproveInfo] = useState({
    engineerName: "",
    lpiName:"",
    certificateClosedName: "",
  });

  const [roleAccess, setRoleAccess] = useState({
    contractStaff:false,
    engineer:false,
    lpi:false,
    certClose:false,
    agt:false,
    adminAGT:false,
    piContract:false,
    reAssignButton:false,
    submitButton:false
  });
  const [closedRemark,setClosedRemark]=useState("")

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };
  
useEffect(()=>{
  if(certificateInfo.agtId!==null){
    getDataHotWork()
    
    
  }
  getData()

},[])
const getDataHotWork=async()=>{
  const getGasDataLink=api.hotWork.getByHotWorkId
  await axiosPrivate.get(`${getGasDataLink}?hotWorkId=${certificateDetail.id}`).then(res=>{
   const filteredData = res.data.data.filter(item => !item.isDeleted);
    setGasData(filteredData)
  }).catch(e=>{
    console.log(e)
    setGasData([])
  })
}

const handleDeleteGas = (id) => {
  const deletegas = api.hotWork.delete;
  axiosPrivate
    .delete(`${deletegas}/${id}`)
    .then(() => {
      toast.success("Successfully Deleted")
      getDataHotWork(); 
    })
    .catch((e) => {
      console.error(e);
    });
};




const getData=async()=>{
  setLoading(true)
const getDataLink=api.hotWork.getById
await axiosPrivate.get(`${getDataLink}/${certificateDetail.id}`).then(res=>{
  console.log(res)
setCertificateInfo(res.data.data)
setClosedRemark(res.data.data.certificateClosedReason)
setSpecifiedFreq(res.data.data?.gasTestWhileWorkInProgress)
setApproveInfo({
  engineerName: res.data.data.isEngineerApproved? res.data.data?.engineerUserDetail?.firstName+" "+res.data.data?.engineerUserDetail?.lastName:"",
  lpiName: res.data.data.isLPIApproved? res.data.data?.lpiUserDetail?.firstName+" "+res.data.data?.lpiUserDetail?.lastName:"",
  certificateClosedName: res.data.data.isCertificateClosed? res.data.data?.certificateClosedByUserDetail?.firstName+" "+res.data.data?.certificateClosedByUserDetail?.lastName:"",
})
setRoleAccess({
  
     piContract:(loginRoleId.includes(roleInfo.contractStaff) && !approvedData.isContractApproved)||(loginRoleId.includes(roleInfo.pi)&&!approvedData.isPermitIssuerApproved),
      contractStaff:!loginRoleId.includes(roleInfo.contractStaff) || approvedData.isContractApproved,
      engineer:!loginRoleId.includes(roleInfo.engineer) || approvedData.isEngineerApproved || res.data.data.isEngineerApproved,
      lpi:!loginRoleId.includes(roleInfo.lpi) || approvedData.isLPIApproved || res.data.data.isLPIApproved,
      certClose:login.userId!==approvedData.permitHolderId || res.data.data.isCertificateClosed|| !res.data.data.isLPIApproved,
      agt:!loginRoleId.includes(roleInfo.agt),
      adminAGT:(loginRoleId.includes(roleInfo.admin)||approvedData.isContractApproved?!approvedData.isContractApproved:loginRoleId.includes(roleInfo.contractStaff)||loginRoleId.includes(roleInfo.agt))&&!res.data.data.isAGTApproved,
      reAssignButton:res.data.data.agtId!==null?(approvedData.isContractApproved&&res.data.data.isAGTApproved?false:approvedData.isContractApproved?roleAccess.adminAGT||approvedData.isContractApproved:roleAccess.adminAGT&&approvedData.isContractApproved):false,
      submitButton:res.data.data.agtId!==null?(((loginRoleId.includes(roleInfo.pi)&&!approvedData.isPermitIssuerApproved)||!approvedData.isContractApproved||!res.data.data.isAGTApproved||loginRoleId.includes(roleInfo.admin))):((loginRoleId.includes(roleInfo.pi)&&!approvedData.isPermitIssuerApproved)||!approvedData.isContractApproved)
  })
if(res.data.data.agtId!==null){
   getAgt(res.data.data.agtUserDetail?.companyId)
}

setAgt({agtBol:res.data.data.agtId!==null?true:false,agtType:res.data.data.agtUserDetail?.companyId,agtCom:res.data.data.agtId!==null?res.data.data.agtUserDetail?.firstName+" "+res.data.data.agtUserDetail?.lastName:"",agtId:res.data.data.agtId})

setLoading(false)
}).catch(e=>{
  setCertificateInfo({})
  setLoading(false)
  console.log(e)
})
}
  // const handleGas = () => {
  //   if (editGas.bol) {
  //     let update = [...gasData];
  //     update[editGas.i] = getGas;
  //     setGasData(update);
  //     setEditGas({ bol: false, i: null });
  //     setGas({
  //       o: "",
  //       c: "",
  //       h: "",
  //       lel: "",
  //       date: null,
  //       appro: "",
  //     });
  //   } else {
  //     setGasData([...gasData, getGas]);
  //     setGas({
  //       o: "",
  //       c: "",
  //       h: "",
  //       lel: "",
  //       date: null,
  //       appro: "",
  //     });
  //   }
  // };

  const gasColumns = [
    {
      Header: "O2",
      accessor: "o2",
    },

    {
      Header: "CO2",
      accessor: "cO2",
    },
    {
      Header: "H2S",
      accessor: "h2S",
    },
    {
      Header: "LEL",
      accessor: "lel",
    },
    {
      Header: "Date/Time",
      accessor: "agtApprovedDate",
    },
    {
      accessor: "id",
      Cell: ({ cell }) => {
        return (
          <Box
            sx={{
              whiteSpace: "nowrap",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
            }}
          >
            <IconButton
            disabled={roleAccess.agt}
              onClick={() => {
                setEditGas({ bol: true, data: cell.row.original });
                
              }}
            >
              <EditOutlined sx={{ color: commonCss.red }} />
            </IconButton>

            {confirmIndex === cell.row.index ? (
                                         
                              
                                         <Box
                                             sx={{
                                                 display: 'flex',
                                                 justifyContent: 'flex-end',
                                                 alignItems: 'center',
                                                 gap: 1, 
                                             }}
                                         >
                                             <ErrorProps>Are you sure wants to delete</ErrorProps>
                                             <Button
                                                 onClick={() => { 
                                                  handleDeleteGas(cell.row.original.id);
                                                  setConfirmIndex(null)
                                                 }}
                                             sx={{
                                                 color: commonCss.red, 
                                                 borderColor: commonCss.red, }}
                                                 size="small"
                                                 variant="outlined"
                                             >
                                                 Yes
                                             </Button>
                                             <Button
                                                 onClick={() => setConfirmIndex(null)}
                                                 sx={{
                                                   color: commonCss.green, 
                                                   borderColor: commonCss.green, }}
                                                 size="small"
                                                 variant="outlined"
                                             >
                                                 No
                                             </Button>
                                         </Box>

                                 ) : (
                                    
                                     <IconButton
                                     disabled={roleAccess.agt}
                                         onClick={() => setConfirmIndex(cell.row.index)}
                                     >
                                         <DeleteOutlined sx={{ color: commonCss.red }} />
                                     </IconButton>
                                 )}
          </Box>
        );
      },
    },
  ];

  const initialValue={
  id: certificateInfo.id,
  formId: certificateInfo.formId,
  locationSpecified: certificateInfo.locationSpecified,
  ignitionSources: certificateInfo.ignitionSources,
  isFreeFlammable: certificateInfo.isFreeFlammable,
  sewerSafe: certificateInfo.sewerSafe,
  workingAreaSign: certificateInfo.workingAreaSign,
  isFireFightingMaterialExist: certificateInfo.isFireFightingMaterialExist,
  isGasDetectorInstalled: certificateInfo.isGasDetectorInstalled,
  isSafetyOfficierPresent: certificateInfo.isSafetyOfficierPresent,
  ptwHolderId: certificateInfo.ptwHolderId===null?login.userId:certificateInfo.ptwHolderId,
  ptwHolderApprovedDate: certificateInfo.ptwHolderApprovedDate===null?moment().utc():certificateInfo.ptwHolderApprovedDate,
  gasTestOncebeforeCommencementOfTheWork: certificateInfo.gasTestOncebeforeCommencementOfTheWork,
  gasTestWhileWorkInProgress: certificateInfo.gasTestWhileWorkInProgress,
  agtId: certificateInfo.agtId,
  engineerId: certificateInfo.engineerId,
  isEngineerApproved: certificateInfo.isEngineerApproved,
  engineerApprovedDate: certificateInfo.engineerApprovedDate,
  isCertificateClosed: certificateInfo.isCertificateClosed,
  certificateClosedBy: certificateInfo.certificateClosedBy,
  certificateClosedAt: certificateInfo.certificateClosedAt,
  certificateClosedReason: certificateInfo.certificateClosedReason,
  lpiId: certificateInfo.lpiId,
  isLPIApproved: certificateInfo.isLPIApproved,
  lpiApprovedDate: certificateInfo.lpiApprovedDate,
  certificateValidFrom: certificateInfo.certificateValidFrom,
  certificateValidTo: certificateInfo.certificateValidTo,
  isAGTApproved:certificateInfo.isAGTApproved,
  specifiedFrequency: certificateInfo.specifiedFrequency,
  flammableGases: certificateInfo.flammableGases,
  }

  const operationInfoList=[
    {
      name:"isFreeFlammable",
      label:"It is free of flammable material",
    },
    {
      name:"sewerSafe",
      label:"Sewer and drainer system, and other low lying areas are protected against sparks",
    },
    {
      name:"workingAreaSign",
      label:"The work area is defined with barriers/signs",
    },
    {
      name:"isFireFightingMaterialExist",
      label:"Additional fire fighting materials are present and ready to use",
    },
    {
      name:"isGasDetectorInstalled",
      label:"A continuous gas detector is installed",
    },
    {
      name:"isSafetyOfficierPresent",
      label:"Safety officer to Safety Supervisor",
    },
  ]

  const gasTestInfo=[
    {
      name:"gasTestOncebeforeCommencementOfTheWork",
      label:"• Once before commencement of the work",
    },
    {
      name:"gasTestWhileWorkInProgress",
      label:"• Test while work is in progress, at specified frequency",
    }
    
    
  ]

 
  const onSubmit=async(value)=>{
    console.log(value)
    let engineerLPIPI=loginRoleId.includes(roleInfo.engineer)||loginRoleId.includes(roleInfo.lpi)
   const postData={
    ...value,
    isApproved:engineerLPIPI?true:null,
    isAGTApproved:loginRoleId.includes(roleInfo.agt)&&!reAssign?true:value.isAGTApproved
   }
   const editLink=api.hotWork.editHotWork
  await axiosPrivate.put(`${editLink}/${certificateInfo.id}`,postData).then(res=>{
    console.log(res) 
  
    // getData()
    handleCloseClick()
    toast.success("Successfully Updated")
  }).catch(e=>{
    console.log(e)
  })
  }
  const getAgt=async(companyId)=>{
    const getUserByCompanyAndRoleId=api.user.getUserByCompanyIdAndRoleId
    const roleId=roleInfo.agt
    console.log(companyId,roleId)
    await axiosPrivate.get(`${getUserByCompanyAndRoleId}?companyId=${companyId}&roleId=${roleId}`).then(res=>{
      setAgtData(res.data.data)
      console.log(res.data.data)
    }).catch(e=>{
      console.log(e)
      setAgtData([])
    })
  }
  const gasSubmit=async(gasValue)=>{
   const postData={...gasValue,hotWorkId:certificateInfo.id,agtId:agt.agtId}
   const addLink=api.hotWork.addGasTest
   if(editGas.bol){
    const editLink=api.hotWork.editGasTest
    const putData={...editGas.data,...gasValue}
    console.log(putData)
    axiosPrivate.put(`${editLink}/${editGas.data.id}`,putData).then(res=>{
      console.log(res)
      toast.success("Sucessfully Updated")
       getDataHotWork()
    }).catch(e=>{
      console.log(e)
    })
   }else{
    axiosPrivate.post(`${addLink}`,postData).then((res)=>{
    console.log(res)
    toast.success("Sucessfully Added")
     getDataHotWork()
   }).catch(e=>{
    console.log(e,"gas Test")
   })
  }
  }
  const handleAGT = (e, setFieldValue) => {
    const checked = e.target.checked;
    setAgt((prev) => ({ ...prev, agtBol: checked }));
    setFieldValue("agtBol", checked);
    if (!checked) {
      setFieldValue("agtId", ""); 
    }
  };
  const handleCloseCertificate=async()=>{
    const closeCertLink=api.hotWork.closeCertificate
    const postData={
        id: certificateInfo.id,
        isCertificateClosed: true,
        certificateClosedReason: closedRemark
    }
    await axiosPrivate.put(`${closeCertLink}/${certificateInfo.id}`,postData).then(res=>{
      toast.success("Certificate Closed")
      handleCloseClick()
    }).catch(e=>{
      console.log(e)
    })
  }

  const validationSchema = Yup.object().shape({

    locationSpecified: Yup.string()
    .required('This field is required'),

    ignitionSources: Yup.string()
    .required('This field is required'),

    certificateValidFrom: Yup.string()
    .required('This field is required'),

    certificateValidTo: Yup.string()
    .required('This field is required'),

    agtId: Yup.string().when("agtBol", {
      is: (value) => value === true,
      then: (schema) => schema.required('This field is required'),
      otherwise:  (schema) => schema.notRequired(),
    }),
    agtBol: Yup.boolean().default(false),
  })

  const handleReassign=()=>{
    setReassign(true)
    setReAssignAgt(agt)
  }

  return (
    <>
    {!loading?
    
    <Formik initialValues={initialValue} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ handleChange, setFieldValue, values, resetForm, errors, touched, setFieldTouched }) => (
          <Form>
         
    <Grid2 container size={12} spacing={"1rem"}>
      <Grid2 container size={12} alignItems={"center"}>
        <Grid2 container size={4}>
          <Box
            sx={{
              backgroundColor: commonCss.shadeGreen,
              p: 1,
              cursor: "pointer",
            }}
            onClick={handleCloseClick}
          >
            <ArrowBack color={commonCss.green} />
          </Box>
        </Grid2>
        <Grid2 container size={4} justifyContent={"center"}>
          <Typography variant="h5" fontWeight="bold" color="white">
            Hot Work Certificate
          </Typography>
        </Grid2>
        <Grid2 container size={4} justifyContent={"flex-end"}>
          <Box
            sx={{
              backgroundColor: commonCss.shadeGreen,
              p: 1,
            }}
          >
            <Typography variant="body1" fontWeight="bold">
              Permit To Work No. <span style={{ color: "#e53e3e" }}>{searchParams.get("no")}</span>
            </Typography>
          </Box>
        </Grid2>
        <Divider
          sx={{ borderColor: commonCss.red, borderWidth: 1, width: "100%" }}
        />
      </Grid2>
     
      <Grid2 container size={12} alignItems={"center"}>
        <Typography color="white" variant="body2">
          (To be completed only by authorised persons)
        </Typography>
      </Grid2>
      <Grid2 container size={12}>
        <Grid2 size={{ xs: 12, md: 5 }}>
          <Paper
            elevation={3}
            sx={{
              borderRadius: commonCss.paperBorderRadius,
              p: 1.5,
              background: commonCss.shadeLinearGradient,
              width: "100%",
              height: "100%",
            }}
          >
            <InputLabel sx={{ pl: commonCss.labelPadding, mb: 2 }}>
              <Typography
                variant="body2"
                fontWeight={commonCss.labelFontWeight}
                color={commonCss.labelInputColor}
              >
                Specify location within terminal
                <Typography
                                    component="span"
                                    color="error"
                                    fontWeight="bold"
                                    sx={{ ml: 0.5 }}
                                  >
                                    *
                                  </Typography>
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="locationSpecified"
              value={values.locationSpecified}
              onChange={handleChange}
              multiline
              rows={2}
              slotProps={{
                input:{
                  readOnly:roleAccess.contractStaff
                }
              }}
             // onBlur={() => setFieldTouched("locationSpecified", true)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor:roleAccess.contractStaff?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                  borderRadius: commonCss.inputBorderRadius,
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: commonCss.inputBorderColor,
                },
                mb: 2,
              }}
            />
                {errors.locationSpecified && (
                          <ErrorMessage name="locationSpecified">
                           {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                          )}
            <InputLabel sx={{ pl: commonCss.labelPadding, mb: 2 }}>
              <Typography
                variant="body2"
                fontWeight={commonCss.labelFontWeight}
                color={commonCss.labelInputColor}
              >
                Specify likely ignition sources
                <Typography
                                    component="span"
                                    color="error"
                                    fontWeight="bold"
                                    sx={{ ml: 0.5 }}
                                  >
                                    *
                                  </Typography>
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="ignitionSources"
              value={values.ignitionSources}
              onChange={handleChange}
              multiline
              rows={2}
              slotProps={{
                input:{
                  readOnly:roleAccess.contractStaff
                }
              }}
            //  onBlur={() => setFieldTouched("ignitionSources", true)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor:roleAccess.contractStaff?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                  borderRadius: commonCss.inputBorderRadius,
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: commonCss.inputBorderColor,
                },
                mb: 2,
              }}
            />
            { errors.ignitionSources && (
                          <ErrorMessage name="ignitionSources">
                           {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                          )}
          </Paper>
        </Grid2>

        <Grid2 size={{ xs: 12, md: 7 }}>
          <Paper
            elevation={3}
            sx={{
              borderRadius: commonCss.paperBorderRadius,
              p: 1.5,
              background: commonCss.shadeLinearGradient,
              width: "100%",
              height: "100%",
            }}
          >
            <Typography
              variant="body2"
              fontWeight={commonCss.mainLabelHeading}
              color={commonCss.red}
            >
              OPERATIONAL INFORMATION
            </Typography>
            <Grid2 item xs={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.normalSentenceWeight}
                color={commonCss.normalSenternceColor}
                sx={{ textTransform: "none" }}
              >
                I have to checked the work place and the area directly
                surrounding it within 15 meter and;
              </Typography>
            </Grid2>
            <List sx={{ padding: 0, margin: 2 }}>
              {operationInfoList.map((text, index) => (
                <ListItem
                  key={index}
                  sx={{
                    padding: 0,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography
                        variant="body2"
                        fontWeight={commonCss.normalSentenceWeight}
                        color={commonCss.normalSenternceColor}
                        sx={{ display: "flex", alignItems: "flex-start" }}
                      >
                        <Box
                          sx={{
                            width: "6px",
                            height: "6px",
                            backgroundColor: "black",
                            borderRadius: "50%",
                            marginTop: "6px",
                            marginRight: 2,
                          }}
                        />
                        {text.label}
                      </Typography>
                    }
                  />
                  <Checkbox
                  disabled={roleAccess.contractStaff}
                    name={text.name}
                    checked={values[text.name]}
                    onChange={(e)=>{
                      setFieldValue(`${text.name}`,e.target.checked)
                    }}
                    sx={{"& .MuiFormControlLabel-label.Mui-disabled":{
                color:"black"
               },
                      color: "#00807F",
                      "&.Mui-checked": {
                        color: "#00807F",
                      },
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid2>
      </Grid2>
      <Grid2 container size={12}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container size={12} spacing={"0.6rem"} >
          
                     
            <Grid2 size={{ xs: 12, md: 6 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  PTW Holder
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                slotProps={{
                  input: {
                    readOnly: true,
                  },
                }}
                value={certificateInfo.ptwHolderId!==null?certificateInfo?.ptwHolderUserDetail?.firstName+" "+certificateInfo?.ptwHolderUserDetail?.lastName:login.firstName+" "+login.lastName}
                sx={{
                  color:"black",
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.readOnlyBGColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                  
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12,md:6 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Validity From
                            <Typography
                                    component="span"
                                    color="error"
                                    fontWeight="bold"
                                    sx={{ ml: 0.5 }}
                                  >
                                    *
                                  </Typography>
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DatePicker
                              ampm={false}
                              format="DD/MM/YYYY"
                              readOnly={!roleAccess.piContract}
                              value={
                                values.certificateValidFrom !== null
                                  ? dayjs(values.certificateValidFrom)
                                  : null
                              }
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              onChange={(newValue) => {
                                console.log(newValue);
                                if (newValue !== null) {
                                  if (`${newValue.$d}` !== "Invalid Date") {
                                    const dateFrom = moment(newValue.$d)
                                    setFieldValue(
                                      "certificateValidFrom",
                                      dateFrom
                                    );
                                    setFieldValue(
                                      "certificateValidTo",
                                      dateFrom
                                    );

                                    // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                    // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                    // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                    // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                    // setFieldValue("installationDate", dateFormat);
                                  }
                                }
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor:!roleAccess.piContract?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                             {errors.certificateValidTo && (
                          <ErrorMessage name="certificateValidTo">
                           {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                          )}
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
            {/* <Grid2 size={{ xs: 12, md: 6 }} sx={{ mb: 2 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Date and Time
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    readOnly
                    value={values.ptwHolderApprovedDate!==null?dayjs(values.ptwHolderApprovedDate):null}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: commonCss.readOnlyBGColor,
                        borderRadius: commonCss.inputBorderRadius,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2> */}
           
            <Grid2 size={{ xs: 12, md: 3 }}>
              <FormControlLabel
              sx={{
                "& .MuiFormControlLabel-label.Mui-disabled":{
                color:"black"
               }
              }}
                control={
                  <Checkbox
                  disabled={roleAccess.contractStaff}
                    checked={agt.agtBol}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "#00807F",
                      },
                      "&:checked": {
                        color: "#00807F",
                      },
                    }}
                    id="agt"
                    color="primary"
                    onChange={(e) => handleAGT(e, setFieldValue)}
                  />
                }
                label="AGT"
              />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 4 }} mt={0.5} sx={{ display:agt.agtBol?"default":"none"}}>
              <InputLabel>
                  <Typography
                    variant="body2"
                    fontWeight={commonCss.labelFontWeight}
                    color={commonCss.labelInputColor}
                  >
                    Company
                  </Typography>
                </InputLabel>
              <Select
                variant="outlined"
                size="small"
                defaultValue=""
                displayEmpty
                value={agt.agtType}
                readOnly={roleAccess.contractStaff}
                onChange={(e)=>{
                  setAgt({...agt,agtType:e.target.value})
                  getAgt(e.target.value)
                }}
                fullWidth
                sx={{
                 
                  
                  borderRadius: "13px",
                  backgroundColor: roleAccess.contractStaff?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "747373",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              >
                <MenuItem value={companyInfo.ps}>PS Pipeline</MenuItem>
                <MenuItem value={login.companyId}>{login.companyName}</MenuItem>
              </Select>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 5 }} sx={{ display:agt.agtBol?"default":"none"}}>
              <InputLabel>
                  <Typography
                    variant="body2"
                    fontWeight={commonCss.labelFontWeight}
                    color={commonCss.labelInputColor}
                  >
                    AGT
                    <Typography
                            component="span"
                            color="error"
                            fontWeight="bold"
                            sx={{ ml: 0.5 }}
                            >
                            *
                            </Typography>
                  </Typography>
                  <Autocomplete
                  readOnly={reAssign?!reAssign:!roleAccess.adminAGT}
                  name="agtId"
                  inputValue={agt.agtCom}
                  filterOptions={filterOptions}
                  loading={agtData.length === 0 }
                  options={agtData}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) => option.firstName+" "+option.lastName || option}
                  onChange={(event, value) => {
                    if (value) {
                      setFieldValue("agtId",value.id)
                      setAgt({...agt,agtCom:value.firstName+" "+value.lastName,agtId:value.id});
                    } else {
                      setFieldValue("agtId",null)
                      setAgt({...agt,agtCom:"",agtId:null});
                    }
                  }}
                  // onInputChange={(e, v) => {
                  //   setAgt({...agt,agtCom:v});
                  // }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="agtId"
                      size="small"
                      // variant="standard"
                      
                      onChange={(e)=>{setAgt({...agt,agtCom:e.target.value})}}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: (reAssign?!reAssign:!roleAccess.adminAGT)?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor,
                          borderRadius:  commonCss.inputBorderRadius,
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: commonCss.inputBorderColor,
                        },
                      }}
                    //  onBlur={() => setFieldTouched("agtId", true)}
                    />
                  )}
                />
                </InputLabel>
                {errors.agtId && (
                  <ErrorMessage name="agtId">
                    {(error) => <ErrorProps>{error}</ErrorProps>}
                  </ErrorMessage>
                  )}
              </Grid2>
              <Grid2 container size={12} justifyContent={"flex-end"} sx={{display:roleAccess.reAssignButton?"default":"none"}}>
              <Box sx={{display:"flex",gap:"0.6rem",alignItems:"center"}}>
            <Button
            variant="contained"
             sx={{
               backgroundColor: commonCss.red,
               borderRadius: commonCss.buttonBorderRadius,
               textTransform: "none",
               padding: "0.6rem 1rem",
               color: commonCss.buttonTextColor,
               width: "15vw",
               display:!reAssign?"none":"default"
             }}
             onClick={() => {
               setReassign(false)
               setAgt(reassignagt)
               
             }}
           >
             Cancel
             </Button>
             <Button
                        
                          variant="contained"
                          sx={{
                            backgroundColor: commonCss.red,
                            borderRadius: commonCss.buttonBorderRadius,
                            textTransform: "none",
                            padding: "0.6rem 1rem",
                            color: commonCss.buttonTextColor,
                            width: "15vw",
                            display:reAssign?"none":"default"
                          }}
                          onClick={() => {
                            
                            handleReassign()
                          }}
                        >
                          Reassign
              </Button>
            </Box>
              </Grid2>

          </Grid2>
          <Typography
            variant="body2"
            fontWeight={commonCss.mainLabelHeading}
            color={commonCss.red}
          >
            Note : Operator can be the AGT or a depot staff member who review
            the workplace
          </Typography>
        </Paper>
      </Grid2>
      <Grid2 container size={12}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container alignItems={"center"} spacing={"0.6rem"}>
          <Grid2 size={12}>
          <Typography
            variant="body2"
            fontWeight={commonCss.mainLabelHeading}
            color={commonCss.red}
          >
            Gas Test
          </Typography>
          </Grid2>
          <Grid2 container size={12} spacing={0}>
          <Grid2 container size={6} alignItems={"center"}>
            {gasTestInfo.map((text, index) => (
              
            <>
            <Grid2 size={10}>
              <Typography
                    variant="body2"
                    fontWeight={commonCss.normalSentenceWeight}
                    color={commonCss.normalSenternceColor}
                    
                  >
                    
                    {text.label}
                  </Typography>
              </Grid2>
              <Grid2  size={2}>
                      <Checkbox
                      disabled={roleAccess.contractStaff}
                      name={text.name}
                      checked={values[text.name]}
                        onChange={(e)=>{
                          setFieldValue(`${text.name}`,e.target.checked)
                          if(text.name==="gasTestWhileWorkInProgress"){
                             setSpecifiedFreq(true)
                             setFieldValue("specifiedFrequency","")
                          }else{
                            setSpecifiedFreq(false)
                            setFieldValue("specifiedFrequency","")
                          }
                        }}
                        sx={{
                          "& .MuiFormControlLabel-label.Mui-disabled":{
                color:"black"
               },
                          color: commonCss.green,
                          "&.Mui-checked": {
                            color: commonCss.green,
                          },
                        }}
                      />
                    </Grid2>
            </>
                  
            
            ))}
            </Grid2>
            <Grid2 container size={6} alignItems={"flex-end"} sx={{display:specifiedFrequency?"default":"none"}}>
                    <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="specifiedFrequency"
              value={values.specifiedFrequency}
              onChange={handleChange}
              slotProps={{
                input:{
                  readOnly:roleAccess.contractStaff
                }
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: roleAccess.contractStaff?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                  borderRadius: commonCss.inputBorderRadius,
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: commonCss.inputBorderColor,
                },
              }}
            />
              </Grid2>
           </Grid2>
           <Grid2 size={6}>
           <InputLabel sx={{ pl: commonCss.labelPadding }}>
            <Typography
              variant="body2"
              fontWeight={commonCss.labelFontWeight}
              color={commonCss.labelInputColor}
            >
              The following working area has been tested for flammable
              gases/vapours
            </Typography>
          </InputLabel>
           </Grid2>
          <Grid2 size={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            value={values.flammableGases}
            onChange={handleChange}
            slotProps={{
              input:{
                readOnly:roleAccess.contractStaff
              }
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: roleAccess.contractStaff?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                borderRadius: commonCss.inputBorderRadius,
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: commonCss.inputBorderColor,
              },
            }}
          />
          </Grid2>
          </Grid2>
        </Paper>
      </Grid2>

      <Grid2 container size={12} sx={{display:agt.agtBol&&values.agtId!==null?'default':"none"}}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container size={12} spacing={"0.6rem"}>
          <Grid2 size={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.mainLabelHeading}
                color={commonCss.red}
              >
                Gas test performed
              </Typography>

            </Grid2>
            <Grid2 size={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.normalSentenceWeight}
                color={commonCss.normalSenternceColor}
              >
             I confirm that I have tested for gas and/or oxygen and that the result are within the specified acceptance levels, and the measured concertation of explosive gasses/vapours is zero % LEL
             </Typography>
              
            </Grid2>
            <Grid2 size={12}>
              <GasTestComponent  edit={editGas.bol} editData={editGas.data} readOnly={roleAccess.agt}  onSubmitGas={gasSubmit}/>
            </Grid2>
            <Grid2
              size={{ xs: 12 }}
              sx={{ display: gasData.length === 0 ? "none" : "default" }}
            >
              <Paper sx={{ background: "transparent" }}>
                <NormalTableWithRadius
                  data={gasData}
                  columns={gasColumns}
                />
              </Paper>
            </Grid2>
            </Grid2>
        </Paper>
      </Grid2>
      <Grid2 container size={4} >
            <Paper elevation={3}
            sx={{borderRadius:commonCss.paperBorderRadius,p:1.5,background:commonCss.shadeLinearGradient,width:"100%"}}
            >
         
         <Grid2 container size={12} spacing={"1rem"} >
              <Grid2 size={12}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.mainLabelHeading}
                  color={commonCss.red}
                  textAlign={"center"}
                >
                  Engineer
                </Typography>
                <Divider sx={{ borderColor: commonCss.red, borderWidth: 1,width:'100%',mt:1 }} />
              </Grid2>
              <Grid2 size={12}>
                <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none"}}>
                  I certify that I have reviewed the proposed work and I am
                  satisfied that it is adequately described on the permit to
                  work and that the precautions detailed are adequate and
                  clearly defined
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Name
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          value={approveInfo.engineerName}
                          slotProps={{
                            input: {
                              readOnly: true, // Set readOnly to true
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.engineer?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Date/Time
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              format="DD/MM/YYYY HH:mm"
                              value={
                                values.isEngineerApproved
                                  ? dayjs(values.engineerApprovedDate)
                                  : null
                              }
                              readOnly
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor:roleAccess.engineer?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 container size={12} justifyContent={"center"}>
                        <Button
                        type="submit"
                          variant="contained"
                          // disabled={values.isEngineerApproved}
                          sx={{
                            backgroundColor: commonCss.green,
                            borderRadius: commonCss.buttonBorderRadius,
                            textTransform: "none",
                            padding: "0.6rem 1rem",
                            color: commonCss.buttonTextColor,
                            width: "15vw",
                            display: roleAccess.engineer ?"none":"default"
                          }}
                          // onClick={() => {
                          //   setFieldValue("isEngineerApproved", true);
                          //   setFieldValue("engineerId", login.userId);
                          //   setFieldValue(
                          //     "engineerApprovedDate",
                          //     moment().format("YYYY-MM-DDTHH:mm:ss")
                          //   );
                          //   setApproveInfo({
                          //     ...approveInfo,
                          //     engineerName:
                          //       login.firstName + " " + login.lastName,
                          //   });
                          // }}
                        >
                          Approve
                        </Button>
                      </Grid2>
        </Grid2>
           </Paper>
        </Grid2>
        <Grid2 container size={4} >
            <Paper elevation={3}
            sx={{borderRadius:commonCss.paperBorderRadius,p:1.5,background:commonCss.shadeLinearGradient,width:"100%"}}
            >
          <Grid2 container size={12} spacing={"1rem"} >
              <Grid2 size={12}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.mainLabelHeading}
                  color={commonCss.red}
                  textAlign={"center"}
                >
                  LPI
                </Typography>
                <Divider sx={{ borderColor: commonCss.red, borderWidth: 1,width:'100%',mt:1 }} />
              </Grid2>
              <Grid2 size={12}>
                <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none"}}>
                Hot Work Certificate is described in the permit to work is allowed
                  between:
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Name
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          value={approveInfo.lpiName}
                          slotProps={{
                            input: {
                              readOnly: true, // Set readOnly to true
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor:roleAccess.lpi?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Date/Time
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              readOnly
                              value={
                                values.isLPIApproved
                                  ? dayjs(values.lpiApprovedDate)
                                  : null
                              }
                              format="DD/MM/YYYY HH:mm"
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor:roleAccess.lpi?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            From
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DatePicker
                              ampm={false}
                              format="DD/MM/YYYY"
                              readOnly={roleAccess.lpi}
                              minDate={approvedData.piPermitValidFrom!==null?dayjs(approvedData.piPermitValidFrom):dayjs(moment().add(1,"year"))}
                              maxDate={approvedData.piPermitValidUpTo!==null?dayjs(approvedData.piPermitValidUpTo):dayjs(moment().add(1,"year"))}
                              value={
                                values.certificateValidFrom !== null
                                  ? dayjs(values.certificateValidFrom)
                                  : null
                              }
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              onChange={(newValue) => {
                                console.log(newValue);
                                if (newValue !== null) {
                                  if (`${newValue.$d}` !== "Invalid Date") {
                                    const dateFrom = moment(newValue.$d)
                                    setFieldValue(
                                      "certificateValidFrom",
                                      dateFrom
                                    );
                                    setFieldValue(
                                      "certificateValidTo",
                                      dateFrom
                                    );

                                    // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                    // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                    // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                    // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                    // setFieldValue("installationDate", dateFormat);
                                  }
                                }
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor:roleAccess.lpi?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      {/* <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Until
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              format="DD/MM/YYYY HH:mm"
                              readOnly={roleAccess.lpi}
                              value={
                                values.certificateValidTo !== null
                                  ? dayjs(values.certificateValidTo)
                                  : null
                              }
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              onChange={(newValue) => {
                                console.log(newValue);
                                if (newValue !== null) {
                                  if (`${newValue.$d}` !== "Invalid Date") {
                                    const dateFrom = moment(newValue.$d)
                                    setFieldValue(
                                      "certificateValidTo",
                                      dateFrom
                                    );

                                    // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                    // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                    // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                    // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                    // setFieldValue("installationDate", dateFormat);
                                  }
                                }
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor:roleAccess.lpi?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2> */}
                      <Grid2 container size={12} justifyContent={"center"}>
                        <Button
                        type="submit"
                          variant="contained"
                          disabled={values.isLPIApproved}
                          sx={{
                            backgroundColor: commonCss.green,
                            borderRadius: commonCss.buttonBorderRadius,
                            textTransform: "none",
                            padding: "0.6rem 1rem",
                            color: commonCss.buttonTextColor,
                            width: "15vw",
                            display:roleAccess.lpi?"none":"default"
                          }}
                          // onClick={() => {
                          //   setFieldValue("isLPIApproved", true);
                          //   setFieldValue("lpiId", login.userId);
                          //   setFieldValue(
                          //     "lpiApprovedDate",
                          //     moment().format("YYYY-MM-DDTHH:mm:ss")
                          //   );
                          //   setApproveInfo({
                          //     ...approveInfo,
                          //     lpiName: login.firstName + " " + login.lastName,
                          //   });
                          // }}
                        >
                          Approve
                        </Button>
                      </Grid2>
            </Grid2>
          </Paper>
        
         </Grid2>
         <Grid2 container size={4} >
            <Paper elevation={3}
            sx={{borderRadius:commonCss.paperBorderRadius,p:1.5,background:commonCss.shadeLinearGradient,width:"100%"}}
            >
          <Grid2 container size={12} spacing={"1rem"} >
              <Grid2 item xs={12}>
                <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none"}}>
                Hot Work Certificate is certificate and permit to work are
                  withdrawn:
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Name
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          slotProps={{
                            input: {
                              readOnly: true,
                            },
                          }}
                          value={
                            values.isCertificateClosed !== null
                              ? approveInfo.certificateClosedName
                              : null
                          }
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor:roleAccess.certClose?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Date/Time
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              format="DD/MM/YYYY HH:mm"
                              readOnly
                              value={
                                values.isCertificateClosed
                                  ? dayjs(values.certificateClosedAt)
                                  : null
                              }
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor:roleAccess.certClose?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Reason
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="reason"
                          value={closedRemark}
                          slotProps={{
                            input:{
                              readOnly:roleAccess.certClose
                            }
                          }}
                          onChange={(e)=>{setClosedRemark(e.target.value)}}
                          multiline
                          minRows={2}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.certClose?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 container size={12} justifyContent={"center"}>
                        <Button
                          variant="contained"
                          disabled={values.isCertificateClosed}
                          sx={{
                            backgroundColor: commonCss.green,
                            borderRadius: commonCss.buttonBorderRadius,
                            textTransform: "none",
                            padding: "0.6rem 1rem",
                            color: commonCss.buttonTextColor,
                            width: "15vw",
                            display:roleAccess.certClose?"none":"default"
                          }}
                          onClick={() => {
                            handleCloseCertificate()
                          }}
                        >
                          Close
                        </Button>
                      </Grid2>
            </Grid2>
            </Paper>
         
        </Grid2>
         
        <Grid2 container size={12} justifyContent={"flex-end"} sx={{display:roleAccess.submitButton?"default":"none"}}>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      backgroundColor: commonCss.green,
                      borderRadius: commonCss.buttonBorderRadius,
                      textTransform: "none",
                      padding: "0.6rem 1rem",
                      color: commonCss.buttonTextColor,
                      width: "10vw",
                    }}
                  >
                    Submit
                  </Button>
                </Grid2>
    </Grid2>
    </Form>
        )}

        </Formik>
        :<Loading/>}
        </>
  );
};

export default HotWork;
