import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  Select,
  Divider,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Button,
  Paper,
  Radio,
  createFilterOptions,
  Autocomplete,
} from "@mui/material";
import { Grid2 } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { commonCss } from "../../../StyleConfig/CommonCss";
import TableSample from "../../../Component/TableSample";
import { ArrowBack } from "@mui/icons-material";
import moment from "moment";
import dayjs from "dayjs";
import NormalTableWithRadius from "../../../Component/NormalTableWithRadius";
import { Form, Formik, ErrorMessage } from "formik";
import Loading from "../../../Component/Loading";
import { useSelector } from "react-redux";
import { api, companyInfo, roleInfo } from "../../API/config";
import { axiosPrivate } from "../../../axios/axios";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import * as Yup from 'yup';
import ErrorProps from "../../../Component/Errorprops";

const IsolationCertificate = ({certificateDetail,handleCloseClick,approvedData }) => {
  const login = useSelector((state) => state.auth.loginInfo);
  const loginRoleId=String(login.roleIds).split(",")
  const [searchParams,setSearchParams]=useSearchParams()

  
  const [typeMeasure,setTypeMeasure]=useState([])
  const [checked, setChecked] = useState({
    electrical: false,
    mechanical: false,
  });
  const [certificateInfo,setCertificateInfo]=useState({})
  const [approveInfo, setApproveInfo] = useState({
    engineerName: "",
    lpiName:"",
    certificateClosedName: "",
  });

  const [roleAccess, setRoleAccess] = useState({
    contractStaff:false,
    engineer:false,
    lpi:false,
    certClose:false,
    io:false,
    iv:false,
    reconnectionIV:false,
    reconnectionIO:false,
    technician:false,
    piContract:false,
  });
  const [loading,setLoading]=useState(false)
  
  const [isolationData,setIsolationData]=useState([
    {
      id:1,
      rowName:"Isolation Owner",
      Name:"",
      action:"",
      autoCom:"io"
    },
    {
      id:2,
      rowName:"Isolation Verifier",
      Name:"",
      action:'',
      autoCom:"iv"
    },
  ])
  const [reconnectionData,setReconnectionData]=useState([
    {
      id:1,
      rowName:"Isolation Owner",
      Name:"",
      action:"",
      autoCom:"reconnectionIO"
    },
    {
      id:2,
      rowName:"Isolation Verifier",
      Name:"",
      action:'',
      autoCom:"reconnectionIV"
    },
  ])

  const [ioData,setIOData]=useState([])
  const [ivData,setIVData]=useState([])
  const [autoCompleteValue,setAutoCompleteValue]=useState({
    iv:"",
    io:"",
    reconnectionIV:"",
    reconnectionIO:""
  })
  const [closedRemark,setClosedRemark]=useState("")
  const [deisolationBol,setDeisolationBol]=useState(null)


  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();
 
  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  useEffect(()=>{
    getData()
  },[])

  const getData=async()=>{
    setLoading(true)
    const getByKey=api.attributeValue.getByKeyName
    await axiosPrivate.get(`${getByKey}?keyName=SpecifyEnergyIsolated`).then(res=>{
      setTypeMeasure(res.data.data)
    }).catch(e=>{
      console.log(e)
    })
    const getById=api.isolation.getById
    await axiosPrivate.get(`${getById}/${certificateDetail.id}`).then(res=>{
      console.log(res)
      setCertificateInfo(res.data.data)
      setClosedRemark(res.data.data.certificateClosedReason)
      setChecked({
        electrical:res.data.data.electricalDetails!==null?true:false,
        mechanical:res.data.data.mechanicalDetails!==null?true:null
      })
      setAutoCompleteValue({
        io:res.data.data.isolationOwnerId!==null?res.data.data?.isolationOwnerUserDetail?.firstName+" "+res.data.data?.isolationOwnerUserDetail?.lastName:"",
        iv:res.data.data.isolationVerifierId!==null?res.data.data?.isolationVerifierUserDetail?.firstName+" "+res.data.data?.isolationVerifierUserDetail?.lastName:"",
        reconnectionIO:res.data.data.reconnectionPermitIssuerId!==null?res.data.data?.reconnectionPermitIssuerUserDetail?.firstName+" "+res.data.data?.reconnectionPermitIssuerUserDetail?.lastName:"",
        reconnectionIV:res.data.data.reconnectionPermitVerifierId!==null?res.data.data?.reconnectionPermitVerifierUserDetail?.firstName+" "+res.data.data?.reconnectionPermitVerifierUserDetail?.lastName:"",
      })
      setApproveInfo({
        engineerName: res.data.data.isEngineerApproved? res.data.data?.engineerUserDetail?.firstName+" "+res.data.data?.engineerUserDetail?.lastName:"",
        lpiName: res.data.data.isLPIApproved? res.data.data?.lpiUserDetail?.firstName+" "+res.data.data?.lpiUserDetail?.lastName:"",
        certificateClosedName: res.data.data.isCertificateClosed? res.data.data?.certificateClosedByUserDetail?.firstName+" "+res.data.data?.certificateClosedByUserDetail?.lastName:"",
      })
      setRoleAccess({
         
        piContract:(loginRoleId.includes(roleInfo.contractStaff) && !approvedData.isContractApproved)||(loginRoleId.includes(roleInfo.pi)&&!approvedData.isPermitIssuerApproved),
        contractStaff:!loginRoleId.includes(roleInfo.contractStaff) || approvedData.isContractApproved,
          engineer:!loginRoleId.includes(roleInfo.engineer) || approvedData.isEngineerApproved || res.data.data.isEngineerApproved,
          lpi:!loginRoleId.includes(roleInfo.lpi) || approvedData.isLPIApproved || res.data.data.isLPIApproved,
          certClose:login.userId!==approvedData.permitHolderId || res.data.data.isCertificateClosed||!res.data.data.isLPIApproved,
          io:(!loginRoleId.includes(roleInfo.isolationOwner)||login.userId!==res.data.data.isolationOwnerId||res.data.data.isIsolationOwnerApproved)||!approvedData.isContractApproved,
          iv:(!loginRoleId.includes(roleInfo.isolationVerifier)||login.userId!==res.data.data.isolationVerifierId||res.data.data.isIsolationVerifierApproved)||!res.data.data.isIsolationOwnerApproved,
          reconnectionIO:!loginRoleId.includes(roleInfo.isolationOwner)||login.userId!==res.data.data.reconnectionPermitIssuerId||res.data.data?.reconnectionIsIsolationOwnerClosed||!res.data.data.isCertificateClosed||!res.data.data.isDeIsolation,
          reconnectionIV:!loginRoleId.includes(roleInfo.isolationVerifier)||login.userId!==res.data.data.reconnectionPermitVerifierId||res.data.data?.reconnectionIsIsolationVerifierClosed||!res.data.data.reconnectionIsIsolationOwnerClosed,
          technician:!loginRoleId.includes(roleInfo.technican)||!res.data.data.isCertificateClosed||res.data.data.isDeIsolation

      })
      setIsolationData([ {
        id:1,
        rowName:"Isolation Owner",
        Name:res.data.data.isolationOwnerId!==null?res.data.data.isolationOwnerId:"",
        action:"",
        autoCom:"io"
      },
      {
        id:2,
        rowName:"Isolation Verifier",
        Name:res.data.data.isolationVerifierId!==null?res.data.data.isolationVerifierId:"",
        action:'',
        autoCom:"iv"
      }])
      setReconnectionData([
        {
          id:1,
          rowName:"Isolation Owner",
          Name:"",
          action:res.data.data.reconnectionPermitIssuerId!==null?res.data.data.reconnectionPermitIssuerId:"",
          autoCom:"reconnectionIO"
        },
        {
          id:2,
          rowName:"Isolation Verifier",
          Name:res.data.data.reconnectionPermitVerifierId!==null?res.data.data.reconnectionPermitVerifierId:"",
          action:'',
          autoCom:"reconnectionIV"
        },
      ])

    }).catch(e=>{
      console.log(e)
    })
    const getIO=api.user.getUserByCompanyIdAndRoleId
    await axiosPrivate.get(`${getIO}?companyId=${companyInfo.ps}&roleId=${roleInfo.isolationOwner}`).then(res=>{
      setIOData(res.data.data)
    }).catch(e=>{
      setIOData([])
      console.log(e)
    })
    const getIV=api.user.getUserByCompanyIdAndRoleId
    await axiosPrivate.get(`${getIV}?companyId=${companyInfo.ps}&roleId=${roleInfo.isolationVerifier}`).then(res=>{
      setIVData(res.data.data)
    }).catch(e=>{
      setIVData([])
      console.log(e)
    })
    setLoading(false)
  }


  const initialValue={
    id: certificateInfo.id,
    formId: certificateInfo.formId,
  lotoNo: certificateInfo.lotoNo,
  isolatedEquipment: certificateInfo.isolatedEquipment,
  locationText: certificateInfo.locationText,
  specifyEnergyIsolatedId: certificateInfo.specifyEnergyIsolatedId,
  isolationOwnerId: certificateInfo.isolationOwnerId,
  isolationVerifierId: certificateInfo.isolationVerifierId,
  electricalDetails: certificateInfo.electricalDetails,
  mechanicalDetails: certificateInfo.mechanicalDetails,
  engineerId: certificateInfo.engineerId,
  isEngineerApproved: certificateInfo.isEngineerApproved,
  engineerApprovedDate: certificateInfo.engineerApprovedDate,
  isCertificateClosed: certificateInfo.isCertificateClosed,
  certificateClosedBy: certificateInfo.certificateClosedBy,
  certificateClosedAt: certificateInfo.certificateClosedAt,
  certificateClosedReason: certificateInfo.certificateClosedReason,
  lpiId: certificateInfo.lpiId,
  isLPIApproved: certificateInfo.isLPIApproved,
  lpiApprovedDate: certificateInfo.lpiApprovedDate,
  certificateValidFrom: certificateInfo.certificateValidFrom,
  certificateValidTo: certificateInfo.certificateValidTo,
  reconnectionPermitIssuerId: certificateInfo.reconnectionPermitIssuerId,
  reconnectionPermitVerifierId: certificateInfo.reconnectionPermitVerifierId,
  isDeIsolation:certificateInfo.isDeIsolation
    }

   const validationSchema = Yup.object().shape({
     
    lotoNo: Yup.string()
    .required('This field is required'),

    isolatedEquipment: Yup.string()
    .required('This field is required'),

    locationText: Yup.string()
    .required('This field is required'),

    specifyEnergyIsolatedId: Yup.string()
    .required('This field is required'),

    certificateValidTo: Yup.string()
    .required('This field is required'),

    certificateValidFrom: Yup.string()
    .required('This field is required'),
    
    
   })
 

 const onSubmit=async(value)=>{
  let engineerLPIPI=loginRoleId.includes(roleInfo.engineer)||loginRoleId.includes(roleInfo.lpi)
   
  const postData={
    ...value,
    isolationOwnerId:isolationData[0].Name,
    isolationVerifierId:isolationData[1].Name,
    reconnectionPermitIssuerId:isolationData[0].Name,
    reconnectionPermitVerifierId:isolationData[1].Name,
    isApproved:engineerLPIPI?true:null
  }
  console.log(postData)
  const editLink=api.isolation.edit
  await axiosPrivate.put(`${editLink}/${certificateInfo.id}`,postData).then(res=>{
    toast.success("Successfully Updated")
    handleCloseClick()
  }).catch(e=>{
    console.log(e)
  })
 }
 
  const handleIsolation =async()=>{
    let ioiv=(login.userId===certificateInfo.isolationOwnerId||login.userId===certificateInfo.isolationVerifierId)
    ||(certificateInfo.isCertificateClosed&&(login.userId===certificateInfo.reconnectionPermitIssuerId||login.userId===certificateInfo.reconnectionPermitVerifierId))
   
  const postData={
   ...certificateInfo,
    isApproved:ioiv?true:null
  }
  console.log(postData)
  const editLink=api.isolation.edit
  await axiosPrivate.put(`${editLink}/${certificateInfo.id}`,postData).then(res=>{
    toast.success("Successfully Updated")
    handleCloseClick()
  }).catch(e=>{
    console.log(e)
  })
  }
  const handleReconnection=()=>{
    const closeCertLink=api.isolation.closeCertificate
    const postData={
        id: certificateInfo.id,
        isCertificateClosed: true,
    }
    console.log(postData)
     axiosPrivate.put(`${closeCertLink}/${certificateInfo.id}`,postData).then(res=>{
      toast.success("Certificate Closed")
      handleCloseClick()
    }).catch(e=>{
      console.log(e)
    })
  }

  const handleCloseCertificate=()=>{
    const closeCertLink=api.isolation.closeCertificate
    const postData={
        id: certificateInfo.id,
        isCertificateClosed: true,
        certificateClosedReason: closedRemark,
        isDeIsolation:deisolationBol
    }
    console.log(postData)
     axiosPrivate.put(`${closeCertLink}/${certificateInfo.id}`,postData).then(res=>{
      toast.success("Certificate Closed")
      handleCloseClick()
    }).catch(e=>{
      console.log(e)
    })
  }
 
  
  const isolationColumns = [
    {
      
      accessor: "rowName",
      Cell: ({ cell }) => {
       return (
        <Typography sx={{fontSize:"15px",fontWeight:700}}>
          {cell.value}
        </Typography>
       )
      },
    },
     {
      Header: "Name",
      accessor: "name",
      Cell:({cell})=>{
        return( 

          <Autocomplete
                  readOnly={roleAccess.contractStaff}
                  name="name"
                  inputValue={autoCompleteValue[cell.row.original.autoCom]}
                  filterOptions={filterOptions}
                  loading={cell.row.original.autoCom==="io"?ioData.length === 0 :ivData.length===0}
                  options={cell.row.original.autoCom==="io"?ioData :ivData}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) => option.firstName+" "+option.lastName || option}
                  onChange={(event, value) => {
                    if (value) {
                      cell.row.original.Name=value.id
                      setAutoCompleteValue({...autoCompleteValue,[cell.row.original.autoCom]:value.firstName+" "+value.lastName})
                      
                    } else {
                      cell.row.original.Name=null
                      setAutoCompleteValue({...autoCompleteValue,[cell.row.original.autoCom]:""})
                    }
                  }}
                 onBlur={(e,value)=>{
                  if(value){
                      const dataUpdate=isolationData
                      dataUpdate[cell.row.original.id-1].Name=value.id
                      setIsolationData(dataUpdate)
                  }
                      
                 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="companyId"
                      size="small"
                      // variant="standard"
                      fullWidth
                      onChange={(e)=>{setAutoCompleteValue({...autoCompleteValue,[cell.row.original.autoCom]:e.target.value})}}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: roleAccess.contractStaff?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor,
                          borderRadius:  commonCss.inputBorderRadius,
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: commonCss.inputBorderColor,
                        },
                      }}
                      
                    />
                  )}
                />
        )
      }

    },
    {
      Header:"Action",
      accessor:'button',
      Cell:({cell})=>{
        return(
          <Button
            variant="contained"
            disabled={roleAccess[cell.row.original.autoCom]}
            sx={{
              backgroundColor:commonCss.red,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"5vw"
            }}
            onClick={()=>{
              handleIsolation()
            }}
          >
            OK
          </Button>
        )
      }
    }
  //   {
  //     Header: "Company",
  //     accessor: "companhy",
  //     Cell:({cell})=>{
  //       const [value,setValue]=useState(cell.value)
  //       return(
  //         <TextField
  //               fullWidth
  //               variant="outlined"
  //               size="small"
  //               value={value}
  //               sx={{
  //                 "& .MuiOutlinedInput-root": {
  //                   backgroundColor: commonCss.inputBackgroundColor,
  //                   borderRadius: commonCss.inputBorderRadius,
  //                 },
  //                 "& .MuiOutlinedInput-notchedOutline": {
  //                   borderColor: commonCss.inputBorderColor,
  //                 },
  //               }}
  //               onChange={(e) => {
  //                 setValue(e.target.value)
  //                 cell.row.original.company = e.target.value;
  //               }}
  //               onBlur={(e) =>
  //                 handleIsolation(
  //                   e.target.value,
  //                   cell.row.original,
  //                   "company"
  //                 )
  //               }
  //             />
  //       )
  //     }
  //   },
  //   {
  //     Header: "Date/Time",
  //     accessor: "date",
  //     Cell:({cell})=>{
  //       const [value,setValue]=useState(cell.value)
  //       return(
  //         <LocalizationProvider dateAdapter={AdapterDayjs}>
  //   <DemoItem>
  //     <DateTimePicker
  //       ampm={false}
  //       format="DD/MM/YYYY HH:mm"
  //       viewRenderers={{
  //         hours: renderTimeViewClock,
  //         minutes: renderTimeViewClock,
  //         seconds: renderTimeViewClock,
  //       }}
  //       slotProps={{
  //         textField: {
  //           size: "small",
  //           variant: "outlined",
  //         },
  //       }}
  //       value={cell.value!==null ? dayjs(cell.value) : null}
  //       onChange={(newValue) => {
  //         if (newValue !== null) {
  //           if (`${newValue.$d}` !== "Invalid Date") {
  //             const dateFrom = moment(newValue.$d).format(
  //               "YYYY-MM-DD HH:MM "
  //             );
             
  //             setValue(dateFrom)
  //             cell.row.original.date = dateFrom;
  //       }}
        
  //     }}
  //     onBlur={(e) =>
  //       handleIsolation(
  //         value,
  //         cell.row.original,
  //         "date"
  //       )
  //     }

  //       sx={{
  //         "& .MuiOutlinedInput-root": {
  //           backgroundColor: commonCss.inputBackgroundColor,
  //           borderRadius: commonCss.inputBorderRadius,
  //         },
  //         "& .MuiOutlinedInput-notchedOutline": {
  //           borderColor: commonCss.inputBorderColor,
  //         },
  //       }}
  //       fullWidth
  //     />
  //   </DemoItem>
  // </LocalizationProvider>
  //       )
  //     }
  //   }
  ];

  const reconnectionColumns = [
    {
      
      accessor: "rowName",
      Cell: ({ cell }) => {
       return (
        <Typography sx={{fontSize:"15px",fontWeight:700}}>
          {cell.value}
        </Typography>
       )
      },
    },
     {
      Header: "Name",
      accessor: "name",
      Cell:({cell})=>{
        return( 

          <Autocomplete
                  readOnly={roleAccess.technician}
                  name="name"
                  inputValue={autoCompleteValue[cell.row.original.autoCom]}
                  filterOptions={filterOptions}
                  loading={cell.row.original.autoCom==="io"?ioData.length === 0 :ivData.length===0}
                  options={cell.row.original.autoCom==="io"?ioData :ivData}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) => option.firstName+" "+option.lastName || option}
                  onChange={(event, value) => {
                    if (value) {
                      cell.row.original.Name=value.id
                      setAutoCompleteValue({...autoCompleteValue,[cell.row.original.autoCom]:value.firstName+" "+value.lastName})
                      
                    } else {
                      cell.row.original.Name=null
                      setAutoCompleteValue({...autoCompleteValue,[cell.row.original.autoCom]:""})
                    }
                  }}
                 onBlur={(e,value)=>{
                  if(value){
                      const dataUpdate=isolationData
                      dataUpdate[cell.row.original.id-1].Name=value.id
                      setReconnectionData(dataUpdate)
                  }
                      
                 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="companyId"
                      size="small"
                      // variant="standard"
                      fullWidth
                      onChange={(e)=>{setAutoCompleteValue({...autoCompleteValue,[cell.row.original.autoCom]:e.target.value})}}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: roleAccess.technician?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor,
                          borderRadius:  commonCss.inputBorderRadius,
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: commonCss.inputBorderColor,
                        },
                      }}
                      
                    />
                  )}
                />
        )
      }

    },
    {
      Header:"Action",
      accessor:'button',
      Cell:({cell})=>{
        return(
          <Button
            variant="contained"
            disabled={roleAccess[cell.row.original.autoCom]}
            sx={{
              backgroundColor:commonCss.red,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"5vw"
            }}
            onClick={()=>{
              handleDeisolationButton(true)
            }}
          >
            OK
          </Button>
        )
      }
    }
  ];

  const handleDeisolationButton=(cert)=>{
    const reconnectionCertLink=api.isolation.deisolation
    const postData={
        id: certificateInfo.id,
        isCertificateClosed: cert,
        // certificateClosedReason: closedRemark,
        isDeIsolation:true
    }
    console.log(postData)
     axiosPrivate.put(`${reconnectionCertLink}/${certificateInfo.id}`,postData).then(res=>{
      toast.success("Certificate Reconnected")
      handleCloseClick()
    }).catch(e=>{
      console.log(e)
    })
  }
  return (
    <>
    {!loading?
    <Formik initialValues={initialValue} onSubmit={onSubmit} enableReinitialize validationSchema={validationSchema}>
        {({ handleChange, setFieldValue, values, resetForm, errors }) => (
          <Form>
    <Grid2 container size={12} spacing={"1rem"}>
      <Grid2 container size={12} alignItems={"center"}>
        <Grid2 container size={4}>
          <Box
            sx={{
              backgroundColor: commonCss.shadeGreen,
              display: "flex",
              alignItems: "cente",
              p: 1,
              cursor: "pointer",
            }}
            onClick={handleCloseClick}
          >
            <ArrowBack color={commonCss.green} />
          </Box>
        </Grid2>
        <Grid2 container size={4} justifyContent={"center"}>
          <Typography variant="h5" fontWeight="bold" color="white">
            Isolation Certificate-Lock out/Tag out
          </Typography>
        </Grid2>
        <Grid2 container size={4} justifyContent={"flex-end"}>
          <Box
            sx={{
              backgroundColor: commonCss.shadeGreen,
              p: 1,
            }}
          >
            <Typography variant="body1" fontWeight="bold">
              Permit To Work No. <span style={{ color: "#e53e3e" }}>{searchParams.get("no")}</span>
            </Typography>
          </Box>
        </Grid2>
        <Divider
          sx={{ borderColor: commonCss.red, borderWidth: 1, width: "100%" }}
        />
      </Grid2>
      <Grid2 container size={12} alignItems={"center"}>
        <Typography color="white" variant="body2">
          (To be completed only by authorised persons)
        </Typography>
      </Grid2>
      <Grid2 container size={12}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container spacing={2} mb={2}>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Identify number(LOTO number)
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                name="lotoNo"
                value={values.lotoNo}
                onChange={handleChange}
                slotProps={{
                  input:{
                    readOnly:roleAccess.contractStaff
                  }
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor:roleAccess.contractStaff?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
              { errors.lotoNo && (
                          <ErrorMessage name="lotoNo">
                           {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                          )}
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Isolated Equipment
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                name="isolatedEquipment"
                value={values.isolatedEquipment}
                onChange={handleChange}
                slotProps={{
                  input:{
                    readOnly:roleAccess.contractStaff
                  }
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor:roleAccess.contractStaff?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
                { errors.isolatedEquipment && (
                          <ErrorMessage name="isolatedEquipment">
                           {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                          )}
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Location
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                name="locationText"
                value={values.locationText}
                onChange={handleChange}
                slotProps={{
                  input:{
                    readOnly:roleAccess.contractStaff
                  }
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor:roleAccess.contractStaff?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
                { errors.locationText && (
                          <ErrorMessage name="locationText">
                           {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                          )}
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Operational Measure Types
                </Typography>
              </InputLabel>
              <Select
                size="small"
                defaultValue=""
                displayEmpty
                value={values.specifyEnergyIsolatedId}
                name="specifyEnergyIsolatedId"
                readOnly={roleAccess.contractStaff}
                sx={{
                  borderRadius: "13px",
                  backgroundColor: roleAccess.contractStaff?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "747373",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
                fullWidth
                onChange={handleChange}
              >
                
                {typeMeasure.map((measure,i)=>(
                  <MenuItem key={i} value={measure.id}>{measure.name}</MenuItem>
                ))}
              </Select>
              { errors.specifyEnergyIsolatedId && (
                          <ErrorMessage name="specifyEnergyIsolatedId">
                           {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                          )}
            </Grid2>
            <Grid2 size={{ xs: 12,md:4 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Validity From
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DatePicker
                              ampm={false}
                              format="DD/MM/YYYY"
                              readOnly={!roleAccess.piContract}
                              // minDate={approvedData.piPermitValidFrom}
                              // maxDate={approvedData.piPermitValidTo}
                              value={
                                values.certificateValidFrom !== null
                                  ? dayjs(values.certificateValidFrom)
                                  : null
                              }
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              onChange={(newValue) => {
                                console.log(newValue);
                                if (newValue !== null) {
                                  if (`${newValue.$d}` !== "Invalid Date") {
                                    const dateFrom = moment(newValue.$d)
                                    setFieldValue(
                                      "certificateValidFrom",
                                      dateFrom
                                    );
                                   

                                    // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                    // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                    // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                    // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                    // setFieldValue("installationDate", dateFormat);
                                  }
                                }
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor:!roleAccess.piContract?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                             { errors.certificateValidFrom && (
                          <ErrorMessage name="certificateValidFrom">
                           {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                          )}
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 size={{ xs: 12,md:4 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Validity Utill
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DatePicker
                              ampm={false}
                              format="DD/MM/YYYY"
                              readOnly={!roleAccess.piContract}
                              // minDate={approvedData.piPermitValidFrom}
                              // maxDate={approvedData.piPermitValidTo}
                              value={
                                values.certificateValidTo !== null
                                  ? dayjs(values.certificateValidTo)
                                  : null
                              }
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              onChange={(newValue) => {
                                console.log(newValue);
                                if (newValue !== null) {
                                  if (`${newValue.$d}` !== "Invalid Date") {
                                    const dateFrom = moment(newValue.$d)
                                    setFieldValue(
                                      "certificateValidTo",
                                      dateFrom
                                    );

                                    // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                    // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                    // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                    // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                    // setFieldValue("installationDate", dateFormat);
                                  }
                                }
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor:!roleAccess.piContract?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                              { errors.certificateValidTo && (
                          <ErrorMessage name="certificateValidTo">
                           {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                          )}
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
          </Grid2>
        </Paper>
      </Grid2>
      <Grid2 container size={12}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container spacing={2}>
            <Grid2 size={12}>
            <Typography variant="body2" color={commonCss.red} fontWeight={commonCss.mainLabelHeading} >
              PERSON(S) COMPLETING THE ISOLATION
            </Typography>
            </Grid2>
            <Grid2 size={12} >
            <NormalTableWithRadius data={isolationData} columns={isolationColumns}/>
          </Grid2>
          </Grid2>
        </Paper>
      </Grid2>
      <Grid2 container size={12}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container spacing={2} mb={2}>
            <Typography
              variant="body2"
              fontWeight={commonCss.labelFontWeight}
              color={commonCss.labelInputColor}
            >
              I have personally checked the equipment to be worked on under this
              Isolation certificate and certify it to have been isolated.
              Accidental reconnection of this equipment has been prevented by
              the following means:
            </Typography>
          </Grid2>

          <Grid2 container  mb={2}>
            <Grid2 container size={{ xs: 12, md: 4 }} spacing={2} >
            <FormControlLabel
              control={
                <Radio
                  readOnly={roleAccess.contractStaff}
                  checked={checked.electrical}
                  onChange={(e)=>{setChecked({electrical:true,mechanical:false})}}
                  sx={{
                    color: commonCss.green,
                    "&.Mui-checked": {
                      color: commonCss.green,
                    },
                  }}
                />
              }
              label="Electrical"
            />
            <FormControlLabel
              control={
                <Radio
                  readOnly={roleAccess.contractStaff}
                  checked={checked.mechanical}
                  onChange={(e)=>{setChecked({electrical:false,mechanical:true})}}
                  sx={{
                    color: commonCss.green,
                    "&.Mui-checked": {
                      color: commonCss.green,
                    },
                  }}
                />
              }
              label="Mechanical"
            />
            </Grid2>
            
            {checked.electrical && (
              <Grid2 size={{ xs: 12, md: 8 }}>
                {/* <InputLabel>
                  <Typography variant="body2" fontWeight="bold" color="black">
                    Electrical
                  </Typography>
                </InputLabel> */}
                <TextField
                  variant="outlined"
                  size="small"
                  name="electricalDetails"
                  value={values.electricalDetails}
                  onChange={handleChange}
                  multiline
                  minRows={2}
                  maxRows={5}
                  fullWidth
                  slotProps={{
                    input:{
                      readOnly:roleAccess.contractStaff
                    }
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor:roleAccess.contractStaff?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                      borderRadius: commonCss.inputBorderRadius,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: commonCss.inputBorderColor,
                    },
                  }}
                />
              </Grid2>
            )}

            {checked.mechanical && (
              <Grid2 size={{ xs: 12, md: 8 }}>
                {/* <InputLabel>
                  <Typography variant="body2" fontWeight="bold" color="black">
                    Mechanical
                  </Typography>
                </InputLabel> */}
                <TextField
                  variant="outlined"
                  size="small"
                  name="mechanicalDetails"
                  value={values.mechanicalDetails}
                  onChange={handleChange}
                  multiline
                  minRows={2}
                  maxRows={5}
                  fullWidth
                  slotProps={{
                    input:{
                      readOnly:roleAccess.contractStaff
                    }
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor:roleAccess.contractStaff?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                      borderRadius: commonCss.inputBorderRadius,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: commonCss.inputBorderColor,
                    },
                  }}
                />
              </Grid2>
            )}
          </Grid2>
        </Paper>
      </Grid2>
      <Grid2 container size={4} >
            <Paper elevation={3}
            sx={{borderRadius:commonCss.paperBorderRadius,p:1.5,background:commonCss.shadeLinearGradient,width:"100%"}}
            >
         
         <Grid2 container size={12} spacing={"1rem"} >
              <Grid2 size={12}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.mainLabelHeading}
                  color={commonCss.red}
                  textAlign={"center"}
                >
                  Engineer
                </Typography>
                <Divider sx={{ borderColor: commonCss.red, borderWidth: 1,width:'100%',mt:1 }} />
              </Grid2>
              <Grid2 size={12}>
                <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none"}}>
                  I certify that I have reviewed the proposed work and I am
                  satisfied that it is adequately described on the permit to
                  work and that the precautions detailed are adequate and
                  clearly defined
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Name
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          value={approveInfo.engineerName}
                          slotProps={{
                            input: {
                              readOnly: true, // Set readOnly to true
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.engineer?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Date/Time
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              format="DD/MM/YYYY HH:mm"
                              value={
                                values.isEngineerApproved
                                  ? dayjs(values.engineerApprovedDate)
                                  : null
                              }
                              readOnly
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor:roleAccess.engineer?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 container size={12} justifyContent={"center"}>
                        <Button
                        type="submit"
                          variant="contained"
                          // disabled={values.isEngineerApproved}
                          sx={{
                            backgroundColor: commonCss.green,
                            borderRadius: commonCss.buttonBorderRadius,
                            textTransform: "none",
                            padding: "0.6rem 1rem",
                            color: commonCss.buttonTextColor,
                            width: "15vw",
                            display: roleAccess.engineer ?"none":"default"
                          }}
                          // onClick={() => {
                          //   setFieldValue("isEngineerApproved", true);
                          //   setFieldValue("engineerId", login.userId);
                          //   setFieldValue(
                          //     "engineerApprovedDate",
                          //     moment().format("YYYY-MM-DDTHH:mm:ss")
                          //   );
                          //   setApproveInfo({
                          //     ...approveInfo,
                          //     engineerName:
                          //       login.firstName + " " + login.lastName,
                          //   });
                          // }}
                        >
                          Approve
                        </Button>
                      </Grid2>
        </Grid2>
           </Paper>
        </Grid2>
        <Grid2 container size={4} >
            <Paper elevation={3}
            sx={{borderRadius:commonCss.paperBorderRadius,p:1.5,background:commonCss.shadeLinearGradient,width:"100%"}}
            >
          <Grid2 container size={12} spacing={"1rem"} >
              <Grid2 size={12}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.mainLabelHeading}
                  color={commonCss.red}
                  textAlign={"center"}
                >
                  LPI
                </Typography>
                <Divider sx={{ borderColor: commonCss.red, borderWidth: 1,width:'100%',mt:1 }} />
              </Grid2>
              <Grid2 size={12}>
                <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none"}}>
                Isolation Certificate is described in the permit to work is allowed
                  between:
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Name
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          value={approveInfo.lpiName}
                          slotProps={{
                            input: {
                              readOnly: true, // Set readOnly to true
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor:roleAccess.lpi?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Date/Time
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              readOnly
                              value={
                                values.isLPIApproved
                                  ? dayjs(values.lpiApprovedDate)
                                  : null
                              }
                              format="DD/MM/YYYY HH:mm"
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor:roleAccess.lpi?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            From
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DatePicker
                              ampm={false}
                              format="DD/MM/YYYY"
                              readOnly={roleAccess.lpi}
                              minDate={approvedData.piPermitValidFrom!==null?dayjs(approvedData.piPermitValidFrom):dayjs(moment().add(1,"year"))}
                              maxDate={approvedData.piPermitValidUpTo!==null?dayjs(approvedData.piPermitValidUpTo):dayjs(moment().add(1,"year"))}
                              value={
                                values.certificateValidFrom !== null
                                  ? dayjs(values.certificateValidFrom)
                                  : null
                              }
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              onChange={(newValue) => {
                                console.log(newValue);
                                if (newValue !== null) {
                                  if (`${newValue.$d}` !== "Invalid Date") {
                                    const dateFrom = moment(newValue.$d)
                                    setFieldValue(
                                      "certificateValidFrom",
                                      dateFrom
                                    );

                                    // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                    // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                    // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                    // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                    // setFieldValue("installationDate", dateFormat);
                                  }
                                }
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor:roleAccess.lpi?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Until
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DatePicker
                              ampm={false}
                              format="DD/MM/YYYY"
                              readOnly={roleAccess.lpi}
                              minDate={approvedData.piPermitValidFrom!==null?dayjs(approvedData.piPermitValidFrom):dayjs(moment().add(1,"year"))}
                              maxDate={approvedData.piPermitValidUpTo!==null?dayjs(approvedData.piPermitValidUpTo):dayjs(moment().add(1,"year"))}
                              value={
                                values.certificateValidTo !== null
                                  ? dayjs(values.certificateValidTo)
                                  : null
                              }
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              onChange={(newValue) => {
                                console.log(newValue);
                                if (newValue !== null) {
                                  if (`${newValue.$d}` !== "Invalid Date") {
                                    const dateFrom = moment(newValue.$d)
                                    setFieldValue(
                                      "certificateValidTo",
                                      dateFrom
                                    );

                                    // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                    // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                    // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                    // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                    // setFieldValue("installationDate", dateFormat);
                                  }
                                }
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor:roleAccess.lpi?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 container size={12} justifyContent={"center"}>
                        <Button
                        type="submit"
                          variant="contained"
                          disabled={values.isLPIApproved}
                          sx={{
                            backgroundColor: commonCss.green,
                            borderRadius: commonCss.buttonBorderRadius,
                            textTransform: "none",
                            padding: "0.6rem 1rem",
                            color: commonCss.buttonTextColor,
                            width: "15vw",
                            display:roleAccess.lpi?"none":"default"
                          }}
                          // onClick={() => {
                          //   setFieldValue("isLPIApproved", true);
                          //   setFieldValue("lpiId", login.userId);
                          //   setFieldValue(
                          //     "lpiApprovedDate",
                          //     moment().format("YYYY-MM-DDTHH:mm:ss")
                          //   );
                          //   setApproveInfo({
                          //     ...approveInfo,
                          //     lpiName: login.firstName + " " + login.lastName,
                          //   });
                          // }}
                        >
                          Approve
                        </Button>
                      </Grid2>
            </Grid2>
          </Paper>
        
         </Grid2>
        <Grid2 container size={4} >
            <Paper elevation={3}
            sx={{borderRadius:commonCss.paperBorderRadius,p:1.5,background:commonCss.shadeLinearGradient,width:"100%"}}
            >
          <Grid2 container size={12} spacing={"1rem"} >
              <Grid2 item xs={12}>
                <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none"}}>
                Isolation Certificate is certificate and permit to work are
                  withdrawn:
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Name
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          slotProps={{
                            input: {
                              readOnly: true,
                            },
                          }}
                          value={
                            values.isCertificateClosed !== null
                              ? approveInfo.certificateClosedName
                              : null
                          }
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor:roleAccess.certClose?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Date/Time
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              format="DD/MM/YYYY HH:mm"
                              readOnly
                              value={
                                values.isCertificateClosed
                                  ? dayjs(values.certificateClosedAt)
                                  : null
                              }
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor:roleAccess.certClose?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Reason
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="reason"
                          value={closedRemark}
                          slotProps={{
                            input:{
                              readOnly:roleAccess.certClose
                            }
                          }}
                          onChange={(e)=>{setClosedRemark(e.target.value)}}
                          multiline
                          minRows={2}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.certClose?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={12}>
                      {/* <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Is this deisolate?
                          </Typography>
                        </InputLabel> */}
                        {/* <Box sx={{display:"flex",gap:"0.6rem",justifyContent:"center",alignItems:"center",width:"100%"}}>
                     
                  <FormControlLabel
                   
                   sx={{"& .MuiFormControlLabel-label.Mui-disabled":{
                 
                 color:"black"
                 
                }}}
                   control={
                     <Checkbox
                     checked={!deisolationBol}
                     disabled={roleAccess.certClose}
                     onClick={(e)=>{setDeisolationBol(e.target.checked)}}
                       sx={{
                         "& .MuiSvgIcon-root": {
                           fill: "#00807F",
                         },
                         "&:checked": {
                           color: "#00807F",
                         },
                         
                       }}
                     />
                   }
                   label={"No"}
                 />
                  </Box> */}
                   <FormControlLabel
                   
                   sx={{"& .MuiFormControlLabel-label.Mui-disabled":{
                 
                 color:"black"
                 
                }}}
                   control={
                     <Checkbox
                     checked={deisolationBol||values.isDeIsolation}
                     disabled={roleAccess.certClose}
                     onClick={(e)=>{setDeisolationBol(e.target.checked)}}
                       sx={{
                         "& .MuiSvgIcon-root": {
                           fill: "#00807F",
                         },
                         "&:checked": {
                           color: "#00807F",
                         },
                         
                       }}
                     />
                   }
                   label={"Is This Deisolate?"}
                 />
                      </Grid2>
                      <Grid2 container size={12} justifyContent={"center"}>
                        <Button
                          variant="contained"
                          disabled={values.isCertificateClosed}
                          sx={{
                            backgroundColor: commonCss.green,
                            borderRadius: commonCss.buttonBorderRadius,
                            textTransform: "none",
                            padding: "0.6rem 1rem",
                            color: commonCss.buttonTextColor,
                            width: "15vw",
                            display:roleAccess.certClose?"none":"default"
                          }}
                          onClick={() => {
                            handleCloseCertificate()
                          }}
                        >
                          Close
                        </Button>
                      </Grid2>
            </Grid2>
            </Paper>
         
        </Grid2>
      <Grid2 container size={12}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          
          <Grid2 container spacing={2} mb={2}>
            <Typography
              variant="body2"
              fontWeight={commonCss.normalSentenceWeight}
              color={commonCss.normalSenternceColor}
              sx={{ textTransform: "none" }}
            >
              I have personally checked the equipment isolated above and confirm
              that work on it is completed and said equipment has been
              re-connected and is in a safe working condition.
            </Typography>
          </Grid2>
          <Grid2 size={12} >
            <NormalTableWithRadius data={reconnectionData} columns={reconnectionColumns}/>
          </Grid2>
          <Grid2 container spacing={2} mb={2} justifyContent={"flex-end"} sx={{display:roleAccess.technician?"none":"default"}}>
            <Button
              variant="contained"
              sx={{
                width: "15vw",
                backgroundColor: "#FF0000",
                color: "white",
                borderRadius: "10px",
                "&:hover": {
                  backgroundColor: "#cc0000",
                },
                mt: 2,
              }}
              onClick={()=>{handleDeisolationButton(false)}}
            >
              Reconnection
            </Button>
          </Grid2>
        </Paper>
      </Grid2>
      <Grid2 container size={12} justifyContent={"flex-end"} sx={{display:approvedData.isContractApproved?"none":"default"}}>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      backgroundColor: commonCss.green,
                      borderRadius: commonCss.buttonBorderRadius,
                      textTransform: "none",
                      padding: "0.6rem 1rem",
                      color: commonCss.buttonTextColor,
                      width: "10vw",
                    }}
                  >
                    Submit
                  </Button>
       </Grid2>
    </Grid2>
    </Form>
        )}

        </Formik>
        :<Loading/>}
        </>
  );
};

export default IsolationCertificate;
