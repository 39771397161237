import { Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { commonCss } from "../../../StyleConfig/CommonCss";
import {
  Button,
  Grid2,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import {
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import * as Yup from "yup";
import ErrorProps from "../../../Component/Errorprops";

const validationSchema = Yup.object().shape({
  o2: Yup.string().required("This field is required"),
  cO2: Yup.string().required("This field is required"),
  h2S: Yup.string().required("This field is required"),
  lel: Yup.string().required("This field is required"),
  //agtApprovedDate: Yup.string().required('This field is required'),
});
const GasTestComponent = ({
  edit,
  onSubmitGas,
  editData,
  type,
  hotWorkInfo,
  readOnly,
}) => {
  const [gasData, setGasData] = useState({
    o2: "",
    cO2: "",
    h2S: "",
    lel: "",
    isAGTApproved: true,
    agtApprovedDate: moment().utc(),
  });
  useEffect(() => {
    setGasData({
      o2: edit ? editData.o2 : "",
      cO2: edit ? editData.cO2 : "",
      h2S: edit ? editData.h2S : "",
      lel: edit ? editData.lel : "",
      isAGTApproved: true,
      agtApprovedDate: edit ? editData.agtApprovedDate : moment().utc(),
    });
  }, [editData]);
  const handleGas = (name, value) => {
    setGasData({ ...gasData, [name]: value });
    if (errors[name]) {
      setErrors({ ...errors, [name]: undefined });
    }
  };
  const handleBlur = async (name) => {
    try {
      await validationSchema.validateAt(name, gasData);
      setErrors({ ...errors, [name]: undefined });
    } catch (err) {
      setErrors({ ...errors, [name]: err.message });
    }
  };
  const validate = async () => {
    try {
      await validationSchema.validate(gasData, { abortEarly: false });
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };
  const onSubmit = async () => {
    const isValid = await validate();
    if (isValid) {
      onSubmitGas(gasData);
      setGasData({
        o2: "",
        cO2: "",
        h2S: "",
        lel: "",
        agtApprovedDate: moment().utc(),
      });
    }
  };
  const [errors, setErrors] = useState({});

  return (
    <Grid2 container size={12} spacing={"0.6rem"}>
      <Grid2 size={{ xs: 12, md: 4 }}>
        <InputLabel sx={{ pl: commonCss.labelPadding }}>
          <Typography
            variant="body2"
            fontWeight={commonCss.labelFontWeight}
            color={commonCss.labelInputColor}
          >
            O2
            <Typography
              component="span"
              color="error"
              fontWeight="bold"
              sx={{ ml: 0.5 }}
            >
              *
            </Typography>
          </Typography>
        </InputLabel>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={gasData.o2}
          name="o2"
          onChange={(e) => {
            handleGas(e.target.name, e.target.value);
          }}
         // onBlur={() => handleBlur("o2")}
          slotProps={{
            input: {
              readOnly: readOnly,
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              backgroundColor: readOnly
                ? commonCss.readOnlyBGColor
                : commonCss.inputBackgroundColor,
              borderRadius: commonCss.inputBorderRadius,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: commonCss.inputBorderColor,
            },
          }}
        />
        <ErrorProps>{errors.o2}</ErrorProps>
      </Grid2>
      <Grid2 size={{ xs: 12, md: 4 }}>
        <InputLabel sx={{ pl: commonCss.labelPadding }}>
          <Typography
            variant="body2"
            fontWeight={commonCss.labelFontWeight}
            color={commonCss.labelInputColor}
          >
            CO2
            <Typography
              component="span"
              color="error"
              fontWeight="bold"
              sx={{ ml: 0.5 }}
            >
              *
            </Typography>
          </Typography>
        </InputLabel>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          name="cO2"
          value={gasData.cO2}
          onChange={(e) => {
            handleGas(e.target.name, e.target.value);
          }}
          //onBlur={() => handleBlur("cO2")}
          slotProps={{
            input: {
              readOnly: readOnly,
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              backgroundColor: readOnly
                ? commonCss.readOnlyBGColor
                : commonCss.inputBackgroundColor,
              borderRadius: commonCss.inputBorderRadius,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: commonCss.inputBorderColor,
            },
          }}
        />
        <ErrorProps>{errors.cO2}</ErrorProps>
      </Grid2>
      <Grid2 size={{ xs: 12, md: 4 }}>
        <InputLabel sx={{ pl: commonCss.labelPadding }}>
          <Typography
            variant="body2"
            fontWeight={commonCss.labelFontWeight}
            color={commonCss.labelInputColor}
          >
            H2S
            <Typography
              component="span"
              color="error"
              fontWeight="bold"
              sx={{ ml: 0.5 }}
            >
              *
            </Typography>
          </Typography>
        </InputLabel>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={gasData.h2S}
          name="h2S"
          onChange={(e) => {
            handleGas(e.target.name, e.target.value);
          }}
        //  onBlur={() => handleBlur("h2S")}
          slotProps={{
            input: {
              readOnly: readOnly,
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              backgroundColor: readOnly
                ? commonCss.readOnlyBGColor
                : commonCss.inputBackgroundColor,
              borderRadius: commonCss.inputBorderRadius,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: commonCss.inputBorderColor,
            },
          }}
        />
        <ErrorProps>{errors.h2S}</ErrorProps>
      </Grid2>
      <Grid2 size={{ xs: 12, md: 4 }}>
        <InputLabel sx={{ pl: commonCss.labelPadding }}>
          <Typography
            variant="body2"
            fontWeight={commonCss.labelFontWeight}
            color={commonCss.labelInputColor}
          >
            LEL
            <Typography
              component="span"
              color="error"
              fontWeight="bold"
              sx={{ ml: 0.5 }}
            >
              *
            </Typography>
          </Typography>
        </InputLabel>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          name="lel"
          value={gasData.lel}
          onChange={(e) => {
            handleGas(e.target.name, e.target.value);
          }}
         // onBlur={() => handleBlur("lel")}
          slotProps={{
            input: {
              readOnly: readOnly,
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              backgroundColor: readOnly
                ? commonCss.readOnlyBGColor
                : commonCss.inputBackgroundColor,
              borderRadius: commonCss.inputBorderRadius,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: commonCss.inputBorderColor,
            },
          }}
        />
        <ErrorProps>{errors.lel}</ErrorProps>
      </Grid2>

      <Grid2 size={{ xs: 12, md: 4 }}>
        <InputLabel sx={{ pl: commonCss.labelPadding }}>
          <Typography
            variant="body2"
            fontWeight={commonCss.labelFontWeight}
            color={commonCss.labelInputColor}
          >
            Date/Time
          </Typography>
        </InputLabel>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoItem>
            <DateTimePicker
              ampm={false}
              format="DD/MM/YYYY HH:mm"
              readOnly={readOnly}
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock,
              }}
              slotProps={{
                textField: {
                  size: "small",
                  variant: "outlined",
                },
              }}
              value={dayjs(gasData.agtApprovedDate)}
              onChange={(newValue) => {
                if (newValue !== null) {
                  if (`${newValue.$d}` !== "Invalid Date") {
                    const dateFrom = moment(newValue.$d).utc();
                    handleGas("agtApprovedDate", dateFrom);
                  }
                }
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: readOnly
                    ? commonCss.readOnlyBGColor
                    : commonCss.inputBackgroundColor,
                  borderRadius: commonCss.inputBorderRadius,
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: commonCss.inputBorderColor,
                },
              }}
              fullWidth
            />
          </DemoItem>
        </LocalizationProvider>
      </Grid2>
      {/* <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  AGT Approval
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={getGas.appro}
                onChange={(e) => {
                  setGas({
                    ...getGas,
                    appro: e.target.value,
                  });
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2> */}
      <Grid2 container size={{ xs: 12 }} justifyContent={"center"}>
        <Button
          variant="contained"
          disabled={readOnly}
          sx={{
            backgroundColor: commonCss.red,
            borderRadius: commonCss.buttonBorderRadius,
            textTransform: "none",
            padding: "0.6rem 1rem",
            color: commonCss.buttonTextColor,
            width: "15vw",
          }}
          onClick={onSubmit}
        >
          Add
        </Button>
      </Grid2>
    </Grid2>
  );
};

export default GasTestComponent;
