import { Autocomplete, Box, Checkbox, createFilterOptions, Divider, FormControlLabel, FormGroup, Grid2, IconButton, InputLabel, Paper, TextField, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { commonCss } from "../../StyleConfig/CommonCss";
import { Clear, Save } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { api, roleInfo } from "../API/config";
import { axiosPrivate } from "../../axios/axios";
import NormalTable from "../../Component/NormalTable";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";

const NewForm = ({handleCloseClick}) => {
    const [company,setCompany]=useState([])
    const [comAuto,setComAuto]=useState("")
    const [user,setUser]=useState([])
    const [userSelect,setUserSelect]=useState([])
    const [loading,setLoading]=useState(false)

    const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };
    
    useEffect(()=>{
      getData()
    },[])
    
    const getData=async()=>{
      setLoading(true)
      const getCompany=api.company.getAll
      await axiosPrivate.get(`${getCompany}`).then(res=>{
        console.log(res)
        setCompany(res.data.data)
        setLoading(true)
      }).catch(e=>{
        console.log(e)
        setLoading(true)
        setCompany([])
      })
    }

    const initialValue = {
      companyId:null,
      assignedUsers:null,
      workDescription:''
    };

    const getUserByCompany=async(id)=>{
      const getUserByCompanyLink=api.user.getUserByCompanyIdAndRoleId
      await axiosPrivate.get(`${getUserByCompanyLink}?companyId=${id}&&roleId=${roleInfo.contractStaff}`).then(res=>{
        console.log(res)
        setUser(res.data.data)
      }).catch(e=>{
        console.log(e)
        setUser([])
      })
    }

    const columns=[
      {
      
          accessor:"id",
          // disableFilters: true,
          Cell:({cell})=>{
              return (
                <Checkbox 
                checked={userSelect.includes(cell.value)}  
                onClick={(e)=>{handleCheckBox(e,cell.row.original)}} 
                sx={{
                  "& .MuiSvgIcon-root": {
                     color: "#00807F",
                   },
                   "&:checked": {
                     color: "#00807F",
                   },
                 }}
                />
              )
          }
      },
      
      {
          Header:"Name",
          accessor:"firstName",
          // disableFilters: true,
          Cell: ({ cell }) => {
            // console.log(cell)
            return (
              <Typography sx={{fontSize:"inherit",fontWeight:"inherit",color:"inherit"}}>
                {cell.value+" "+cell.row.original.lastName}
              </Typography>
            );
          },
         
      },
      {
        Header:"Email",
        accessor:"emailId",
        // disableFilters: true,
       
    },
    {
      Header:"Role",
      accessor:"roleName",
      // disableFilters: true,
     
  }
  ]

  const handleCheckBox=(e,info)=>{
    if(e.target.checked){
      setUserSelect([...userSelect,info.id])
    }else{
      const filterUserSelect=userSelect.filter(fil=>fil!==info.id)
      setUserSelect(filterUserSelect)
    }
  }

    

    const onSubmit=async(value,{resetForm})=>{
      const initiateFormLink=api.PTWForm.formInitiate
      const postData={
        ...value,
        assignedUsers:userSelect
      }
      console.log(postData)
      await axiosPrivate.post(`${initiateFormLink}`,postData).then(res=>{
        toast.success("Successfully Initiate")
        // handleCloseClick()
        // resetForm()
        // setUser([])
        setUserSelect([])
        // setComAuto("")
      }).catch(e=>{
        console.log(e)

      })
    }
  return (
    <Paper sx={{ background: commonCss.shadeLinearGradient, p: 1.5 }}>
      <Formik initialValues={initialValue} onSubmit={onSubmit}>
        {({ handleChange, setFieldValue, values, resetForm }) => (
          <Form>
      <Grid2 container spacing={"1rem"}>
        <Grid2 size={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography  fontWeight={600} color={commonCss.green} sx={{fontSize:"1.3rem"}}>
              New Form
            </Typography>
            <Box sx={{display:"flex",alignItems:"center",width:"40%",justifyContent:"flex-end",gap:"1rem"}}>
              <IconButton type="submit" >
                <Save sx={{color:commonCss.red}}/>
              </IconButton>
              <IconButton onClick={handleCloseClick}>
                <Clear sx={{color:commonCss.red}}/>
              </IconButton>
            </Box>
          </Box>
          <Divider sx={{borderColor: commonCss.red, borderWidth: 1,width:"100%"}}/>
        </Grid2>
        <Grid2 container size={12}>
          <Grid2 size={{xs:12,md:6}}>
          <InputLabel>
                  <Typography
                    variant="body2"
                    fontWeight={commonCss.labelFontWeight}
                    color={commonCss.labelInputColor}
                  >
                    Contractor
                  </Typography>
                </InputLabel>
          <Autocomplete
                  name="companyId"
                  inputValue={comAuto}
                  filterOptions={filterOptions}
                  loading={company.length === 0 && loading}
                  options={company}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) => option.name || option}
                  onChange={(event, value) => {
                    if (value) {
                      setFieldValue("companyId",value.id)
                      setComAuto(value.name);
                      getUserByCompany(value.id)
                    } else {
                      setFieldValue("companyId",null)
                      setComAuto("");
                    }
                  }}
                  onInputChange={(e, v) => {
                    setComAuto(v);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="companyId"
                      size="small"
                      // variant="standard"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: commonCss.inputBackgroundColor,
                          borderRadius: commonCss.inputBorderRadius,
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: commonCss.inputBorderColor,
                        },
                      }}
                      
                    />
                  )}
                />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }}>
                <InputLabel>
                  <Typography
                    variant="body2"
                    fontWeight={commonCss.labelFontWeight}
                    color={commonCss.labelInputColor}
                  >
                    Work Description
                  </Typography>
                </InputLabel>
                <TextField
                  size={"small"}
                  type="text"
                  name="workDescription"
                  value={values.workDescription}
                  fullWidth
                  onChange={handleChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: commonCss.inputBackgroundColor,
                      borderRadius: commonCss.inputBorderRadius,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: commonCss.inputBorderColor,
                    },
                  }}
                />
              </Grid2>
          <Grid2 size={12}>
            <NormalTable columns={columns} data={user} />
          </Grid2>
        
        </Grid2>
      </Grid2>
      </Form>
        )}
        </Formik>
    </Paper>
  );
};

export default NewForm;
