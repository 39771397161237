import {
  Button,
  Divider,
  Grid2,
  Paper,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  InputLabel,
  Box,
  IconButton,
} from "@mui/material";
import { commonCss } from "../../../StyleConfig/CommonCss";
import Close from '@mui/icons-material/Close';
import {
  Add,
  ArrowBack,
  EditOutlined,
  FileUploadOutlined,
} from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import {
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import NormalTable from "../../../Component/NormalTable";
import { Form, Formik, ErrorMessage } from "formik";
import { api, companyInfo, roleInfo } from "../../API/config";
import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Loading from "../../../Component/Loading";
import { axiosPrivate } from "../../../axios/axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import moment from "moment";
import ViewImage from "../ViewImage";
import * as Yup from 'yup';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import ErrorProps from "../../../Component/Errorprops";

const MethodStatement = ({ handleCloseClick, approvedData }) => {
 
  const [workCompleteData, setWorkCompleteData] = useState([]);
  const [workComplete, setWorkComplete] = useState();
  const [editWC, setEditWC] = useState({ bol: false, i: null });
  const login = useSelector((state) => state.auth.loginInfo);
  const loginRoleId=String(login.roleIds).split(",")
  const [loading, setLoading] = useState(false);
  const [workCompletedNotes, setWorkCompletedNotes] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState({});
  const [editData, setEditData] = useState(false);
  const [uploadStatus, setUploadStatus] = useState({
    siteLocationDrawings: false,
  });
  const [roleAccess, setRoleAccess] = useState({
    contractStaff: false,
    pi: false,
  });
  const [approveInfo, setApproveInfo] = useState({
    piName: "",
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [siteLocationData, setSiteLocationData] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState({ siteLocationDrawings: null });
    const [confirmIndex, setConfirmIndex] = useState(null);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    const getLink = api.methodstatement.getByFormId;

    await axiosPrivate
      .get(`${getLink}?formId=${searchParams.get("id")}`)
      .then(async (res) => {
        console.log("resdata", res);
        
        setRoleAccess({
          contractStaff:
          !loginRoleId.includes(roleInfo.contractStaff) ||
            approvedData.isContractApproved,
          pi:!loginRoleId.includes(roleInfo.pi)||approvedData.isPermitIssuerApproved||res.data.data[0]?.isPermitIssuerApproved
        });
        setApproveInfo({
          piName: res.data.data[0]?.isPermitIssuerApproved
            ? res.data.data[0]?.permitIssuerUserDetail?.firstName +
              " " +
              res.data.data[0]?.permitIssuerUserDetail?.lastName
            : "",
        });
        if (res.data.data.length !== 0) {
          setWorkCompleteData(
            res.data.data[0]?.workCompletedNotes !== null
              ? String(res.data.data[0].workCompletedNotes).split(",")
              : []
          );
          setData(res.data.data[0]);
          setEditData(true);
          console.log("responce api", res.data.data);
          // setSiteLocationData(
          //   res.data.data[0]?.siteLocationDrawings !== null
          //     ? res.data.data[0].siteLocationDrawings
          //     : "" 
          // );
        }
      })
      .catch((e) => {
        console.error("Error fetching data: ", e);
        setData({});
      });
    setLoading(false);
    //    .finally(() => {
    //    setLoading(false);
    //});
  };
  const handleOpenDialog = (file) => {
    const fileData = {
      data: file,
      type: file.type,
    };
    setSelectedFile(fileData);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedFile(null);
  };
  
  const initialValues = {
    formId: searchParams.get("id"),
    company: approvedData?.companyDetail?.name,
    project: approvedData?.descriptionOfWork,
    dateField: editData? data.dateField:moment().utc(),
    durationOfWork: editData ? data.durationOfWork : "",
    siteLocationDrawings: editData ? data.siteLocationDrawings : "",
    restrictions: editData ? data.restrictions : "",
    localPermitRequired: editData ? data.localPermitRequired : "",
    otherWorkDetails: editData ? data.otherWorkDetails : "",
    projectWorkerDetails: editData ? data.projectWorkerDetails : "",
    inChargeDetails: editData ? data.inChargeDetails : "",
    responsibilitiesSpecified: editData ? data.responsibilitiesSpecified : "",
    localAuthoritiesContact: editData ? data.localAuthoritiesContact : "",
    otherPersonDetails: editData ? data.otherPersonDetails : "",
    plantEquipmentRequired: editData ? data.plantEquipmentRequired : "",
    workingTools: editData ? data.workingTools : "",
    sparkTools: editData ? data.sparkTools : "",
    othersTools: editData ? data.othersTools : "",
    certificatesRequired: editData ? data.certificatesRequired : "",
    trainingRequired: editData ? data.trainingRequired : "",
    proceduresCommunicatedWorkforce: editData
      ? data.proceduresCommunicatedWorkforce
      : "",
    proposedStartDate: editData ? data.proposedStartDate : moment().utc(),
    proposedEndDate: editData ? data.proposedEndDate : moment().utc(),
      workCompletedNotes: [],
    isPermitIssuerApproved: data.isPermitIssuerApproved ,
    permitIssuerApprovedDate : data.permitIssuerApprovedDate,
    workCompletedNotes:editData&&data.workCompletedNotes!==null?String(data.workCompletedNotes).split(","):[]
  };

  const validationSchema = (editData) => Yup.object().shape({
    // company: Yup.string().required('This field is required'),
    // project: Yup.string().required('This field is required'),
    // datetime: Yup.string().required('This field is required'),
    durationOfWork: Yup.string().required('This field is required'),
    siteLocationDrawings: Yup.string().required('This field is required'),
    restrictions: Yup.string().required('This field is required'),
    localPermitRequired: Yup.string().required('This field is required'),
    otherWorkDetails: Yup.string().required('This field is required'),
    projectWorkerDetails: Yup.string().required('This field is required'),
    inChargeDetails: Yup.string().required('This field is required'),
    responsibilitiesSpecified: Yup.string().required('This field is required'),
    localAuthoritiesContact: Yup.string().required('This field is required'),
    otherPersonDetails: Yup.string().required('This field is required'),
    plantEquipmentRequired: Yup.string().required('This field is required'),
    workingTools: Yup.string().required('This field is required'),
    sparkTools: Yup.string().required('This field is required'),
    othersTools: Yup.string().required('This field is required'),
    certificatesRequired: Yup.string().required('This field is required'),
    trainingRequired: Yup.string().required('This field is required'),
    proceduresCommunicatedWorkforce: Yup.string().required('This field is required'),
    workCompletedNotes: Yup.array().min(1,"This field is required")
  
  
  })

    const handleWC = (setFieldValue) => {
    if (!workComplete) return;
  
    let updatedWork = [...workCompleteData];
  
    if (editWC.bol) {
      updatedWork[editWC.i] = workComplete;
      setEditWC({ bol: false, i: null });
    } else {
      updatedWork.push(workComplete);
    }
  
    setWorkCompleteData(updatedWork); 
    setFieldValue("workCompletedNotes", updatedWork); 
    setWorkComplete(""); 
  };
    const handleDelete = (index,setFieldValue) => {
        const updatedList = workCompleteData.filter((_, i) => i !== index);
        setWorkCompleteData(updatedList);
        setFieldValue("workCompletedNotes",updatedList)
        setConfirmIndex(null); 
    };

  const onSubmit = async (value) => {
    let piAppro = loginRoleId.includes(roleInfo.pi);
    const updatedValues = {
      ...value,
      isApproved: piAppro ? true : null,
        workCompletedNotes: workCompleteData?.length ? workCompleteData.toString() : null,
    };
    console.log("OnSub", value);
    if (editData) {
      const editLink = api.methodstatement.edit;
      console.log("updated value", updatedValues);
      await axiosPrivate
        .put(`${editLink}/${data.id}`, { id: data.id, ...updatedValues })
        .then((res) => {
          toast.success("Successfully Updated");
          handleCloseClick()
        })
        .catch((e) => {
          console.log(e);
          toast.error("Not Updated");
        });
    } else {
      const createLink = api.methodstatement.create;
      await axiosPrivate
        .post(`${createLink}`, updatedValues)
        .then((res) => {
          toast.success("Successfully Created");
          handleCloseClick()
        })
        .catch((e) => {
          toast.error("Not Created");
          console.log(e);
        });
    }
  };

  const handleFileUpload = (event, fieldName, setFieldValue) => {
    const file = event.target.files[0];
  
    if (file) {
      const fileType = file.type.split("/")[1];
      console.log(file.name);
      console.log(fileType);
  
      let reader = new FileReader();
      reader.readAsDataURL(file);
  
      reader.onload = function () {
        const base64String = reader.result;  
        console.log(base64String);
        setFieldValue(fieldName, base64String);  
        // setSiteLocationData(base64String);
        // setUploadStatus((prevState) => ({
        //   ...prevState,
        //   [fieldName]: true,
        // }));
      };
  
      reader.onerror = function (error) {
        console.error("Error reading file: ", error);
      };
    } else {
      console.log("No file selected.");
    }
  };
  
  return (
    <>
      {!loading ? (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema(editData)}>
          {({ handleChange, setFieldValue, values, errors, touched, setFieldTouched }) => (
            <Form>
              <Grid2 container size={12} spacing={"1rem"}>
                <Grid2 container size={12} alignItems={"center"}>
                  <Grid2 container size={4}>
                    <Box
                      sx={{
                        backgroundColor: commonCss.shadeGreen,
                        display: "flex",
                        alignItems: "cente",
                        p: 1,
                        cursor: "pointer",
                      }}
                      onClick={handleCloseClick}
                    >
                      <ArrowBack color={commonCss.green} />
                    </Box>
                  </Grid2>
                  <Grid2 container size={4} justifyContent={"center"}>
                    <Typography variant="h5" fontWeight="bold" color="white">
                      Method Statement
                    </Typography>
                  </Grid2>
                  <Grid2 container size={4} justifyContent={"flex-end"}>
                    <Box
                      sx={{
                        backgroundColor: commonCss.shadeGreen,
                        p: 1,
                      }}
                    >
                      <Typography variant="body1" fontWeight="bold">
                        Permit To Work No.{" "}
                        <span style={{ color: "#e53e3e" }}>2701</span>
                      </Typography>
                    </Box>
                  </Grid2>
                  <Divider
                    sx={{
                      borderColor: commonCss.red,
                      borderWidth: 1,
                      width: "100%",
                    }}
                  />
                </Grid2>
                <Grid2 size={12}>
                  <Typography
                    variant="body2"
                    color={commonCss.outSideSentenceColor}
                  >
                    The Permit Issuer, when developing the respective Permit
                    forms, will use the Method Statement supplied.
                  </Typography>
                </Grid2>
                <Grid2 container size={12}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"0.6rem"}>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Company
                            <Typography
                                    component="span"
                                    color="error"
                                    fontWeight="bold"
                                    sx={{ ml: 0.5 }}
                                  >
                                    *
                                  </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="company"
                          value={values.company}
                          onChange={handleChange}
                          slotProps={{
                            input: {
                              readOnly: true,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                          //onBlur={() => setFieldTouched("company", true)}
                        />
                           { errors.company && (
                          <ErrorMessage name="company">
                           {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                          )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Project
                            <Typography
                                    component="span"
                                    color="error"
                                    fontWeight="bold"
                                    sx={{ ml: 0.5 }}
                                  >
                                    *
                                  </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="project"
                          value={values.project}
                          onChange={handleChange}
                          slotProps={{
                            input: {
                              readOnly: true,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                         // onBlur={() => setFieldTouched("project", true)}
                        />
                          {errors.project && (
                          <ErrorMessage name="project">
                           {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                          )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Date and Time
                            <Typography
                                    component="span"
                                    color="error"
                                    fontWeight="bold"
                                    sx={{ ml: 0.5 }}
                                  >
                                    *
                                  </Typography>
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              format="DD/MM/YYYY HH:mm"
                              readOnly={roleAccess.contractStaff}
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              value={
                                values.dateField !== null
                                  ? dayjs(values.dateField)
                                  : null
                              }
                              onChange={(newValue) => {
                                console.log(newValue);
                                if (newValue !== null) {
                                  if (`${newValue.$d}` !== "Invalid Date") {
                                    const dateFrom = moment(newValue.$d).utc();
                                    setFieldValue("dateField", dateFrom);

                                    // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                    // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                    // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                    // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                    // setFieldValue("installationDate", dateFormat);
                                  }
                                }
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor: roleAccess.contractStaff
                                    ? commonCss.readOnlyBGColor
                                    : commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                             // onClose={() => setFieldTouched("datetime", true)}
                              fullWidth
                            />
                            { errors.datetime && (
                          <ErrorMessage name="datetime">
                           {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                          )}
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={12}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"0.6rem"}>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.mainLabelHeading}
                          color={commonCss.red}
                        >
                          Description of works
                        </Typography>
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Duration of work?
                            <Typography
                                    component="span"
                                    color="error"
                                    fontWeight="bold"
                                    sx={{ ml: 0.5 }}
                                  >
                                    *
                                  </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="durationOfWork"
                          value={values.durationOfWork}
                          onChange={handleChange}
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                       //   onBlur={() => setFieldTouched("durationOfWork", true)}
                        />
                          {errors.durationOfWork && (
                          <ErrorMessage name="durationOfWork">
                           {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                          )}
                      </Grid2>
                                  
                                    <Grid2 size={{ xs: 12, md: 4 }}>
                <InputLabel sx={{ pl: commonCss.labelPadding }}>
                  <Typography
                    variant="body2"
                    fontWeight={commonCss.labelFontWeight}
                    color={commonCss.labelInputColor}
                  >
                    Include site location drawings if appropriate?
                    <Typography
                                    component="span"
                                    color="error"
                                    fontWeight="bold"
                                    sx={{ ml: 0.5 }}
                                  >
                                    *
                                  </Typography>
                  </Typography>
                </InputLabel>
                {values.siteLocationDrawings!==null&&values.siteLocationDrawings!=="" ? (
                  <Button
                    variant="outlined"
                    sx={{
                      display: roleAccess.contractStaff ? "none" : "block",
                      backgroundColor: commonCss.red,
                      borderRadius: commonCss.buttonBorderRadius,
                      textTransform: "none",
                      padding: "0.6rem 1rem",
                      color: commonCss.buttonTextColor,
                      width: "15vw",
                    }}
                    onClick={() => handleOpenDialog(values.siteLocationDrawings)}
                  >
                    <Typography sx={{ fontSize: "0.9rem" }}>View File</Typography>
                  </Button>
                ) : (
              <Button
                    variant="contained"
                    component="label"
                    sx={{
                      visibility: roleAccess.contractStaff ? "hidden" : "visible",
                      backgroundColor: commonCss.red,
                      borderRadius: commonCss.buttonBorderRadius,
                      textTransform: "none",
                      padding: "0.6rem 1rem",
                      color: commonCss.buttonTextColor,
                      width: "15vw",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "0.5rem",
                    }}
                  >
                    <FileUploadOutlined />
                    <Typography sx={{ fontSize: "0.9rem" }}>
                      Add Attachment
                    </Typography>
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={(e) => handleFileUpload(e, "siteLocationDrawings", setFieldValue)}
                    />
                  </Button>
                )}
                 { errors.siteLocationDrawings && (
                          <ErrorMessage name="siteLocationDrawings">
                           {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                          )}
              </Grid2>



                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Are there any restrictions(e.g overhead power
                            lines)?
                            <Typography
                                    component="span"
                                    color="error"
                                    fontWeight="bold"
                                    sx={{ ml: 0.5 }}
                                  >
                                    *
                                  </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="restrictions"
                          value={values.restrictions}
                          onChange={(e) =>
                            setFieldValue("restrictions", e.target.value)
                          }
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        //  onBlur={() => setFieldTouched("restrictions", true)}
                          />
                            { errors.restrictions && (
                            <ErrorMessage name="restrictions">
                             {(error) => <ErrorProps>{error}</ErrorProps>}
                            </ErrorMessage>
                            )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Any local permit required (local regulations)
                            <Typography
                                    component="span"
                                    color="error"
                                    fontWeight="bold"
                                    sx={{ ml: 0.5 }}
                                  >
                                    *
                                  </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="localPermitRequired"
                          value={values.localPermitRequired}
                          onChange={handleChange}
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                          //onBlur={() => setFieldTouched("localPermitRequired", true)}
                          />
                            { errors.localPermitRequired && (
                            <ErrorMessage name="localPermitRequired">
                             {(error) => <ErrorProps>{error}</ErrorProps>}
                            </ErrorMessage>
                            )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Any other details
                            <Typography
                                    component="span"
                                    color="error"
                                    fontWeight="bold"
                                    sx={{ ml: 0.5 }}
                                  >
                                    *
                                  </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="otherWorkDetails"
                          value={values.otherWorkDetails}
                          onChange={handleChange}
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                          //onBlur={() => setFieldTouched("otherWorkDetails", true)}
                          />
                            {errors.otherWorkDetails && (
                            <ErrorMessage name="otherWorkDetails">
                             {(error) => <ErrorProps>{error}</ErrorProps>}
                            </ErrorMessage>
                            )}
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={12}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"0.6rem"}>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.mainLabelHeading}
                          color={commonCss.red}
                        >
                          Names of Responsible persons
                        </Typography>
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Who is going to be working on the project?
                            <Typography
                                    component="span"
                                    color="error"
                                    fontWeight="bold"
                                    sx={{ ml: 0.5 }}
                                  >
                                    *
                                  </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="projectWorkerDetails"
                          value={values.projectWorkerDetails}
                          onChange={handleChange}
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                         //onBlur={() => setFieldTouched("projectWorkerDetails", true)}
                        />
                          { errors.projectWorkerDetails && (
                          <ErrorMessage name="projectWorkerDetails">
                           {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                          )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Who will be in charge?
                            <Typography
                                    component="span"
                                    color="error"
                                    fontWeight="bold"
                                    sx={{ ml: 0.5 }}
                                  >
                                    *
                                  </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="inChargeDetails"
                          value={values.inChargeDetails}
                          onChange={handleChange}
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                         // onBlur={() => setFieldTouched("inChargeDetails", true)}
                        />
                        {errors.inChargeDetails && (
                          <ErrorMessage name="inChargeDetails">
                           {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                          )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Who has specific responsibilities?(eg. plant ,
                            material, etc)
                            <Typography
                                    component="span"
                                    color="error"
                                    fontWeight="bold"
                                    sx={{ ml: 0.5 }}
                                  >
                                    *
                                  </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="responsibilitiesSpecified"
                          value={values.responsibilitiesSpecified}
                          onChange={handleChange}
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                          //onBlur={() => setFieldTouched("responsibilitiesSpecified", true)}
                          />
                          { errors.responsibilitiesSpecified && (
                            <ErrorMessage name="responsibilitiesSpecified">
                             {(error) => <ErrorProps>{error}</ErrorProps>}
                            </ErrorMessage>
                            )}
                        
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Who is to make contact with local authorities?
                            <Typography
                                    component="span"
                                    color="error"
                                    fontWeight="bold"
                                    sx={{ ml: 0.5 }}
                                  >
                                    *
                                  </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="localAuthoritiesContact"
                          value={values.localAuthoritiesContact}
                          onChange={handleChange}
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                          //onBlur={() => setFieldTouched("localAuthoritiesContact", true)}
                        />
                         { errors.localAuthoritiesContact && (
                            <ErrorMessage name="localAuthoritiesContact">
                             {(error) => <ErrorProps>{error}</ErrorProps>}
                            </ErrorMessage>
                            )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Any other details
                            <Typography
                                    component="span"
                                    color="error"
                                    fontWeight="bold"
                                    sx={{ ml: 0.5 }}
                                  >
                                    *
                                  </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="otherPersonDetails"
                          value={values.otherPersonDetails}
                          onChange={handleChange}
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        //  onBlur={() => setFieldTouched("otherPersonDetails", true)}
                          />
                           { errors.otherPersonDetails && (
                              <ErrorMessage name="otherPersonDetails">
                               {(error) => <ErrorProps>{error}</ErrorProps>}
                              </ErrorMessage>
                              )}
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={12}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"0.6rem"}>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.mainLabelHeading}
                          color={commonCss.red}
                        >
                          Plant and Equipment
                        </Typography>
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            What plant equipment and tools are required to do
                            the job?
                            <Typography
                                    component="span"
                                    color="error"
                                    fontWeight="bold"
                                    sx={{ ml: 0.5 }}
                                  >
                                    *
                                  </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="plantEquipmentRequired"
                          value={values.plantEquipmentRequired}
                          onChange={handleChange}
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        
                         // onBlur={() => setFieldTouched("plantEquipmentRequired", true)}
                          />
                           {errors.plantEquipmentRequired && (
                              <ErrorMessage name="plantEquipmentRequired">
                               {(error) => <ErrorProps>{error}</ErrorProps>}
                              </ErrorMessage>
                              )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: 0.5 }}>
                          <Typography
                            variant="body2"
                            fontWeight="500"
                            color="black"
                          >
                            Tools (for work)
                            <Typography
                                    component="span"
                                    color="error"
                                    fontWeight="bold"
                                    sx={{ ml: 0.5 }}
                                  >
                                    *
                                  </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="workingTools"
                          value={values.workingTools}
                          onChange={handleChange}
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                          //onBlur={() => setFieldTouched("workingTools", true)}
                          />
                           { errors.workingTools && (
                              <ErrorMessage name="workingTools">
                               {(error) => <ErrorProps>{error}</ErrorProps>}
                              </ErrorMessage>
                              )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 12 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Tool ( that may generate spark, ignitions source,
                            etc)
                            <Typography
                                    component="span"
                                    color="error"
                                    fontWeight="bold"
                                    sx={{ ml: 0.5 }}
                                  >
                                    *
                                  </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="sparkTools"
                          value={values.sparkTools}
                          onChange={handleChange}
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                          //onBlur={() => setFieldTouched("sparkTools", true)}
                          />
                           { errors.sparkTools && (
                              <ErrorMessage name="sparkTools">
                               {(error) => <ErrorProps>{error}</ErrorProps>}
                              </ErrorMessage>
                              )}
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Others, Specify
                            <Typography
                                    component="span"
                                    color="error"
                                    fontWeight="bold"
                                    sx={{ ml: 0.5 }}
                                  >
                                    *
                                  </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="othersTools"
                          value={values.othersTools}
                          onChange={handleChange}
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                          //onBlur={() => setFieldTouched("othersTools", true)}
                          />
                           { errors.othersTools && (
                              <ErrorMessage name="othersTools">
                               {(error) => <ErrorProps>{error}</ErrorProps>}
                              </ErrorMessage>
                              )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            What certificates, if any , are required for plant
                            or equipment?
                            <Typography
                                    component="span"
                                    color="error"
                                    fontWeight="bold"
                                    sx={{ ml: 0.5 }}
                                  >
                                    *
                                  </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="certificatesRequired"
                          value={values.certificatesRequired}
                          onChange={handleChange}
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
             
                            //onBlur={() => setFieldTouched("certificatesRequired", true)}
                          />
                           { errors.certificatesRequired && (
                              <ErrorMessage name="certificatesRequired">
                               {(error) => <ErrorProps>{error}</ErrorProps>}
                              </ErrorMessage>
                              )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            What training, if any, is required for operatives?
                            <Typography
                                    component="span"
                                    color="error"
                                    fontWeight="bold"
                                    sx={{ ml: 0.5 }}
                                  >
                                    *
                                  </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="trainingRequired"
                          value={values.trainingRequired}
                          onChange={handleChange}
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                         // onBlur={() => setFieldTouched("trainingRequired", true)}
                          />
                           {errors.trainingRequired && (
                              <ErrorMessage name="trainingRequired">
                               {(error) => <ErrorProps>{error}</ErrorProps>}
                              </ErrorMessage>
                              )}
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={12}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"0.6rem"}>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.mainLabelHeading}
                          color={commonCss.red}
                        >
                          Communications with the workforce
                        </Typography>
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: 0.5 }}>
                          <Typography
                            variant="body2"
                            fontWeight="500"
                            color="black"
                          >
                            How are the procedures, hazards to be communicated
                            to the workforce?
                            <Typography
                                    component="span"
                                    color="error"
                                    fontWeight="bold"
                                    sx={{ ml: 0.5 }}
                                  >
                                    *
                                  </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="proceduresCommunicatedWorkforce"
                          value={values.proceduresCommunicatedWorkforce}
                          onChange={handleChange}
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                         // onBlur={() => setFieldTouched("proceduresCommunicatedWorkforce", true)}
                          />
                           { errors.proceduresCommunicatedWorkforce && (
                              <ErrorMessage name="proceduresCommunicatedWorkforce">
                               {(error) => <ErrorProps>{error}</ErrorProps>}
                              </ErrorMessage>
                              )}
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={12}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"0.6rem"}>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Start Date
                     
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              format="DD/MM/YYYY HH:mm"
                              readOnly={roleAccess.contractStaff}
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              value={
                                values.proposedStartDate !== null
                                  ? dayjs(values.proposedStartDate)
                                  : null
                              }
                              onChange={(newValue) => {
                                console.log(newValue);
                                if (newValue !== null) {
                                  if (`${newValue.$d}` !== "Invalid Date") {
                                    const dateFrom = moment(newValue.$d).utc();
                                    setFieldValue(
                                      "proposedStartDate",
                                      dateFrom
                                    );

                                    // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                    // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                    // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                    // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                    // setFieldValue("installationDate", dateFormat);
                                  }
                                }
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor: roleAccess.contractStaff
                                    ? commonCss.readOnlyBGColor
                                    : commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              
                              fullWidth
                            />
                       
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            End Date
                        
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              format="DD/MM/YYYY HH:mm"
                              readOnly={roleAccess.contractStaff}
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              value={
                                values.proposedEndDate !== null
                                  ? dayjs(values.proposedEndDate)
                                  : null
                              }
                              onChange={(newValue) => {
                                console.log(newValue);
                                if (newValue !== null) {
                                  if (`${newValue.$d}` !== "Invalid Date") {
                                    const dateFrom = moment(newValue.$d).utc();
                                    setFieldValue("proposedEndDate", dateFrom);

                                    // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                    // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                    // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                    // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                    // setFieldValue("installationDate", dateFormat);
                                  }
                                }
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor: roleAccess.contractStaff
                                    ? commonCss.readOnlyBGColor
                                    : commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              
                              fullWidth
                            />
                    
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 8 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            How work to be completed
                          </Typography>
                        </InputLabel>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "1rem",
                            alignItems: "center",
                          }}
                        >
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={workComplete} 
                            slotProps={{
                              input: {
                                readOnly: roleAccess.contractStaff,
                              },
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: roleAccess.contractStaff
                                  ? commonCss.readOnlyBGColor
                                  : commonCss.inputBackgroundColor,
                                borderRadius: commonCss.inputBorderRadius,
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: commonCss.inputBorderColor,
                              },
                            }}
                            onChange={(e) => {
                              setWorkComplete(e.target.value);
                            }} // Update workComplete state for individual steps
                          />
                          <IconButton
                            sx={{
                              display: roleAccess.contractStaff
                                ? "none"
                                : "default",
                              border: `3px solid ${commonCss.red}`,
                              p: 0,
                            }}
                            //onClick={handleWC}
                            onClick={() => handleWC(setFieldValue)} 
                          >
                            <Add sx={{ color: commonCss.red }} />
                          </IconButton>
                        </Box>
                        { errors.workCompletedNotes && (
                          <ErrorMessage name="workCompletedNotes">
                           {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                          )}
                            <Grid2 size={12} sx={{display:values.workCompletedNotes.length===0?"none":"default"}}>
                              <Paper elevation={4} sx={{ p: 1 }}>
                                {values.workCompletedNotes.map((comData, i) => (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      width: "100%",
                                      justifyContent: "space-between",
                                    }}
                                    >
                                    <Typography>{i + 1}</Typography>
                                    <Typography>{comData}</Typography>
                                    <IconButton
                                    disabled={roleAccess.contractStaff}
                                      onClick={() => {
                                        setEditWC({ bol: true, i: i });
                                        setWorkComplete(comData);
                                      }}

                                    >
                                      <EditOutlined
                                        sx={{ color: commonCss.red }}
                                      />
                                        </IconButton>
                                        {confirmIndex === i ? (
                                         
                              
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-end',
                                                        alignItems: 'center',
                                                        gap: 1, 
                                                    }}
                                                >
                                                    <ErrorProps>Are you sure wants to delete</ErrorProps>
                                                    <Button
                                                        onClick={() => handleDelete(i,setFieldValue)}
                                                    sx={{
                                                        color: commonCss.red, 
                                                        borderColor: commonCss.red, }} 
                                                        size="small"
                                                        variant="outlined"
                                                    >
                                                        Yes
                                                    </Button>
                                                    <Button
                                                        onClick={() => setConfirmIndex(null)}
                                                        sx={{
                                                          color: commonCss.green, 
                                                          borderColor: commonCss.green, }}
                                                        size="small"
                                                        variant="outlined"
                                                    >
                                                        No
                                                    </Button>
                                                </Box>

                                        ) : (
                                           
                                            <IconButton
                                                disabled={roleAccess.contractStaff}
                                                onClick={() => setConfirmIndex(i)}
                                            >
                                                <DeleteOutlined sx={{ color: commonCss.red }} />
                                            </IconButton>
                                        )}
                                  </Box>
                                ))}

                                {/* <NormalTable
                                data={workCompleteData}
                                columns={workColumns}
                                hideHeader={true}
                              /> */}
                              </Paper>
                            </Grid2>
                        
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={12}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"0.6rem"}>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.mainLabelHeading}
                          color={commonCss.red}
                          textAlign={"center"}
                        >
                          PI
                        </Typography>
                        <Divider
                          sx={{
                            borderColor: commonCss.red,
                            borderWidth: 1,
                            width: "100%",
                            mt: 1,
                          }}
                        />
                      </Grid2>
                      <Grid2 size={12}>
                        {" "}
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.normalSentenceWeight}
                          color={commonCss.normalSenternceColor}
                          sx={{ textTransform: "none" }}
                        >
                          I certify that I have reviewed the proposed work and I
                          am satisfied that it is adequately described on the
                          permit to work and that the precautions detailed are
                          adequate and clearly defined
                        </Typography>
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Name
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          value={approveInfo.piName}
                          slotProps={{
                            input: {
                              readOnly: true, // Set readOnly to true
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.pi
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Date/Time
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              format="DD/MM/YYYY HH:mm"
                              value={
                                values.isPermitIssuerApproved
                                  ? dayjs(values.permitIssuerApprovedDate)
                                  : null
                              }
                              readOnly
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor: roleAccess.pi
                                    ? commonCss.readOnlyBGColor
                                    : commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 container size={4}>
                        <Button
                          type="submit"
                          variant="contained"
                          disabled={values.isPermitIssuerApproved}
                          sx={{
                            backgroundColor: commonCss.green,
                            borderRadius: commonCss.buttonBorderRadius,
                            textTransform: "none",
                            padding: "0.6rem 1rem",
                            color: commonCss.buttonTextColor,
                            width: "15vw",
                            marginTop: "1rem",
                            display: roleAccess.pi ? "none" : "default",
                          }}
                          // onClick={() => {
                          //   setFieldValue("isEngineerApproved", true);
                          //   setFieldValue("engineerId", login.userId);
                          //   setFieldValue(
                          //     "engineerApprovedDate",
                          //     moment().format("YYYY-MM-DDTHH:mm:ss")
                          //   );
                          //   setApproveInfo({
                          //     ...approveInfo,
                          //     engineerName:
                          //       login.firstName + " " + login.lastName,
                          //   });
                          // }}
                        >
                          Approve
                        </Button>
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={12} justifyContent={"flex-end"}>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      backgroundColor: commonCss.green,
                      borderRadius: commonCss.buttonBorderRadius,
                      textTransform: "none",
                      padding: "0.6rem 1rem",
                      color: commonCss.buttonTextColor,
                      width: "10vw",
                      display: approvedData.isContractApproved
                        ? "none"
                        : roleAccess.contractStaff
                        ? "none"
                        : "default",
                    }}
                  >
                    Submit
                  </Button>
                </Grid2>
                          </Grid2>
                          <Dialog
                              open={dialogOpen}
                              onClose={handleCloseDialog}
                              fullWidth
                              maxWidth="xl"
                          >
                              {/* <DialogTitle sx={{ background: commonCss.normalLinearGradient, color: commonCss.buttonTextColor }}>
                                  Site Location Drawing
                              </DialogTitle> */}
                              <DialogContent sx={{ background: commonCss.normalLinearGradient }}>
                                  {selectedFile && selectedFile.data ? (
                                      <ViewImage selectedFile={selectedFile} handleCloseClick={handleCloseDialog} dialogTitle={"Site Location Drawing"} />
                                  ) : (
                                      <Typography>No file to display</Typography>
                                  )}
                              </DialogContent>
                              {/* <DialogActions
                                  sx={{
                                      position: "absolute",
                                      top: 0,
                                      right: 0,
                                      padding: "0.5rem",
                                  }}
                              >
                                  <IconButton
                                      sx={{
                                          color: commonCss.buttonTextColor,
                                          borderRadius: "50%",
                                          "&:hover": {
                                              backgroundColor: "darkred",
                                          },
                                      }}
                                      onClick={handleCloseDialog}
                                  >
                                      <Close />
                                  </IconButton>
                              </DialogActions> */}
                          </Dialog>
            </Form>
          )}
        </Formik>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default MethodStatement;
