import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  Box,
  List,
  ListSubheader,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  ListItem,
} from "@mui/material";
// import { TreeView, TreeItem } from "@mui/lab";

import { useDispatch, useSelector } from "react-redux";
import {
  AssignmentInd,
  Business,
  Dashboard,
  DeveloperBoard,
  FormatAlignCenter,
  Group,
  ListAlt,
  PinDrop,
  WebAsset,
} from "@mui/icons-material";
import { commonCss } from "../../StyleConfig/CommonCss";
import { roleInfo } from "../../Pages/API/config";

const SideNav = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [listOpen, setListOpen] = useState({});

  const drawerOpen = useSelector((state) => state.data.drawerOpen);
  const login = useSelector((state) => state.auth.loginInfo);
  const loginRoldId = String(login.roleIds).split(",");
  const admin = loginRoldId.includes(roleInfo.admin);

  const handleClick = (listType) => {
    setListOpen({ ...listOpen, [`${listType}`]: !listOpen[`${listType}`] });
  };

  const handleNavigate = (link) => {
    navigate(link);
  };

  // const renderTree = (nodes) => {
  //   // console.log(nodes)
  //   return (
  //     <TreeItem
  //       key={nodes.id}
  //       nodeId={nodes.id}
  //       label={
  //         <Box
  //           sx={{
  //             display: "flex",
  //             alignItems: "flex-start",
  //             p: 1,
  //             gap: "0.6rem",
  //           }}
  //           onClick={() => {
  //             if (nodes.children === null) {
  //               if(nodes.module.toUpperCase()==="ASSETS_EXPL"){
  //                 dispatch({type:"drawerOpen",payload:{bol:true,open:false}})
  //               }else{
  //                 dispatch({type:"drawerOpen",payload:{bol:false,open:false}})
  //               }
  //               navigate(`${nodes.href}`);
  //               // dispatch({type:"actionsAllow",payload:nodes})
  //             }
  //           }}
  //         >
  //           <img
  //             src={`${nodes.icon}`}
  //             style={{
  //               width: "1.4rem",
  //               height: "1.4rem",
  //               display: nodes.children == null ? "default" : "none",
  //             }}
  //           />
  //           <Typography
  //             sx={{
  //               fontSize: "0.82rem",
  //               fontWeight: color.sideFontWeight,
  //               fontFamily: color.sideFontFamily,
  //               color: color.sideFontColor,
  //             }}
  //           >
  //             {nodes.name}
  //           </Typography>
  //         </Box>
  //       }
  //       sx={{ mt: 0.7, p: 0, ml:nodes.children === null? -3.5:0 }}
  //     >
  //       {Array.isArray(nodes.children)
  //         ? nodes.children.map((node) => renderTree(node))
  //         : null}
  //     </TreeItem>
  //   );
  // };

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={drawerOpen.bol ? drawerOpen.open : props.open}
      sx={{
        width: props.drawerWidth,
        flexShrink: 0,
        display: {
          xs: props.res === "main" ? "none" : "block",
          md: props.res === "main" ? "block" : "none",
        },
        [`& .MuiDrawer-paper`]: {
          width: props.drawerWidth,
          boxSizing: "border-box",
          border: "none",
          // backgroundColor: props.res === "main" ? "#FEDADA" : "default",
          background: "linear-gradient(to top, #FD0000 , #00807F)",
          mt: { xs: props.res === "main" ? 0 : "9vh" },
          // boxShadow: props.res === "main" ? 0 : 3,
          boxShadow: `0px 2px 10px ${commonCss.red}`,
        },
      }}
    >
      <Toolbar
        sx={{ display: props.res === "main" ? "block" : "none" }}
      ></Toolbar>
      <Box sx={{ overflow: "auto" }}>
        <List
          sx={{
            width: "100%",
            // bgcolor: "background.paper",
            backgroundColor: "transparent",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            mt: "1rem",
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItem>
            <Link to="/dashboard">
              <Box sx={{ display: "flex", gap: "0.5rem", color: "white" }}>
                <Dashboard />
                <Typography>Dashboard</Typography>
              </Box>
            </Link>
          </ListItem>
          <ListItem>
            <Link to="/formlist">
              <Box sx={{ display: "flex", gap: "0.5rem", color: "white" }}>
                <ListAlt />
                <Typography>Form</Typography>
              </Box>
            </Link>
          </ListItem>
          {admin ? (
            <>
              <Typography sx={{ p: 2, color: "whitesmoke", letterSpacing: 2 }}>
                SETTING
              </Typography>
              <ListItem>
                <Link to="/user">
                  <Box sx={{ display: "flex", gap: "0.5rem", color: "white" }}>
                    <Group />
                    <Typography>Users</Typography>
                  </Box>
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/terminal">
                  <Box sx={{ display: "flex", gap: "0.5rem", color: "white" }}>
                    <DeveloperBoard />
                    <Typography>Terminal</Typography>
                  </Box>
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/site">
                  <Box sx={{ display: "flex", gap: "0.5rem", color: "white" }}>
                    <PinDrop />
                    <Typography>Site</Typography>
                  </Box>
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/company">
                  <Box sx={{ display: "flex", gap: "0.5rem", color: "white" }}>
                    <Business />
                    <Typography>Company</Typography>
                  </Box>
                </Link>
              </ListItem>
            </>
          ) : null}
          {/* <ListItem>
          <Link to="/role" >
          <Box sx={{display:"flex",gap:"0.5rem",color:"white"}}>
            <AssignmentInd/>
          <Typography>
            Role
          </Typography>
          </Box>
          </Link>
        </ListItem> */}
        </List>
        {/* <TreeView
          aria-label="Location Tree"
          defaultCollapseIcon={<ExpandMoreIcon sx={{ color: "#828079" }} />}
          //  defaultExpanded={expanded}
          defaultExpandIcon={<ChevronRightIcon sx={{ color: "#828079" }} />}
          sx={{
            "& .MuiTreeItem-root": {
              "& .MuiTreeItem-content": {
                "& .MuiTreeItem-label": {
                  paddingLeft: "1em",
                },
                "& .MuiTreeItem-iconContainer": {
                  paddingLeft: "0.5em",
                },
              },
            },
          }}
        >
          {data.map((list, i) => (
            <Box key={i}>{renderTree(list)}</Box>
          ))}
        </TreeView> */}
      </Box>
    </Drawer>
  );
};

export default SideNav;
