import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  createFilterOptions,
  Divider,
  Grid2,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { CellTower, Clear, Password, Visibility, VisibilityOff } from "@mui/icons-material";

import { axiosPrivate } from "../../../axios/axios";
import { api, companyInfo, userTypeInfo } from "../../API/config";
import { commonCss } from "../../../StyleConfig/CommonCss";
import NormalTable from "../../../Component/NormalTable";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import moment from "moment";
import dayjs from "dayjs";

const UserModal = ({ mode, handleCloseClick, editData }) => {
  const [loading, setLoading] = useState(false);
  const [terminal,setTerminal]=useState([])
  const [terminalShow,setTerminalShow]=useState(mode==="Edit"?editData.userTypeId===userTypeInfo.internal?true:false:false)
  const [userType, setuserType] = useState([]);
  const [role, setRole]=useState([])
  const [selectRole,setSelRole]=useState(mode ==="Edit"?editData.roleIds:[])
  const [company,setCompany]=useState([])
  const [showPassword, setShowPassword] = useState(false);

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    const getUserType = api.userType.getAll;
    await axiosPrivate
      .get(`${getUserType}`)
      .then((res) => {
        setuserType(res.data.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setuserType([])
        setLoading(false);
      });
     
      const getCompany = api.company.getAll;
    await axiosPrivate
      .get(`${getCompany}`)
      .then((res) => {
        setCompany(res.data.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setCompany([])
        setLoading(false);
      });
      const terminalLink=api.terminal.getAll
      await axiosPrivate.get(`${terminalLink}`).then(res=>{
        setTerminal(res.data.data)
      }).catch(e=>{
        console.log(e)
        setTerminal([])
      })

      if(mode === "Edit"){
        getRoleByUserType(editData.userTypeId)
      }
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const initialValue = {
    firstName: mode === "Edit" ? editData.firstName : "",
    lastName: mode === "Edit" ? editData.lastName : "",
    emailId: mode === "Edit" ? editData.emailId : "",
    password: mode === "Edit" ? editData.password : "",
    userTypeId: mode === "Edit" ? editData.userTypeId : null,
    companyId: mode === "Edit" ? editData.companyId : null,
    terminalIds: mode === "Edit" ? editData.terminalIds!==null?editData.terminalIds[0]:null : null,
    validityUpto: mode === "Edit" ? editData.validityUpto : null
  };

  const onSubmit = async (value) => {
    console.log(value);
    setLoading(true);

    const createLink = api.user.add;
    const updateLink = api.user.edit;
    if (mode === "Create") {
      const postData={
        ...value,
        roleIds:selectRole
      }
      await axiosPrivate
        .post(`${createLink}`, postData)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, "create User");
        });
    } else {
      const updateAC = {
        id: editData.id,
        ...value,
        roleIds:selectRole

      };
     console.log(updateAC)
      await axiosPrivate
        .put(`${updateLink}/${editData.id}`, updateAC)
        .then((res) => {
          console.log(res);
          setLoading(false);
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, "update User");
        });
    }
  };

  const handleCancel = () => {
    handleCloseClick();
  };

  const getRoleByUserType=async(id)=>{
    const getRole = api.role.getByUserType;
    await axiosPrivate
      .get(`${getRole}?userTypeId=${id}`)
      .then((res) => {
        console.log(res)
        setRole(res.data.data);
        
      })
      .catch((e) => {
        console.log(e);
        setRole([])
      });
  }

  const handleCheckBox=(e,id)=>{
    if(selectRole.includes(id)){
      const filterRole=selectRole.filter(fil=>fil!==id)
      setSelRole(filterRole)
    }else{
      setSelRole([...selectRole,id])
    }
  }

  const columns=[
    {
      accessor:"id",
      Cell:({cell})=>{
        return (
          <Checkbox 
          checked={selectRole.includes(cell.value)} 
          sx={{
            "& .MuiSvgIcon-root": {
               color: commonCss.green,
             },
             "&:checked": {
               color: commonCss.green,
             },
           }}
          onClick={(e)=>{handleCheckBox(e,cell.value)}}/>
        )
      }
    },
    {
      Header:"Role Name",
      accessor:"roleName"
    }
  ]

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: commonCss.titleColor,
            fontSize: commonCss.titleFontSize,
            fontWeight: commonCss.titleFontWeight,
          }}
        >
          {mode === "Edit" ? editData.firstName +" " + editData.lastName : "New User"}
        </Typography>
        <IconButton onClick={handleCancel}>
          <Clear />
        </IconButton>
      </Box>
      <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }} />
      <Formik initialValues={initialValue} onSubmit={onSubmit}>
        {({ handleChange, setFieldValue, values, resetForm }) => (
          <Form>
            <Grid2 container spacing={"1rem"}>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <InputLabel>
                  <Typography
                    variant="body2"
                    fontWeight={commonCss.labelFontWeight}
                    color={commonCss.labelInputColor}
                  >
                    First Name
                  </Typography>
                </InputLabel>
                <TextField
                  size={"small"}
                  type="text"
                  name="firstName"
                  value={values.firstName}
                  fullWidth
                  onChange={handleChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: commonCss.inputBackgroundColor,
                      borderRadius: commonCss.inputBorderRadius,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: commonCss.inputBorderColor,
                    },
                  }}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <InputLabel>
                  <Typography
                    variant="body2"
                    fontWeight={commonCss.labelFontWeight}
                    color={commonCss.labelInputColor}
                  >
                    Last Name
                  </Typography>
                </InputLabel>
                <TextField
                  size={"small"}
                  type="text"
                  name="lastName"
                  value={values.lastName}
                  fullWidth
                  onChange={handleChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: commonCss.inputBackgroundColor,
                      borderRadius: commonCss.inputBorderRadius,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: commonCss.inputBorderColor,
                    },
                  }}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <InputLabel>
                  <Typography
                    variant="body2"
                    fontWeight={commonCss.labelFontWeight}
                    color={commonCss.labelInputColor}
                  >
                    Email
                  </Typography>
                </InputLabel>
                <TextField
                  size={"small"}
                  type="email"
                  name="emailId"
                  value={values.emailId}
                  fullWidth
                  onChange={handleChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: commonCss.inputBackgroundColor,
                      borderRadius: commonCss.inputBorderRadius,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: commonCss.inputBorderColor,
                    },
                  }}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <InputLabel>
                  <Typography
                    variant="body2"
                    fontWeight={commonCss.labelFontWeight}
                    color={commonCss.labelInputColor}
                  >
                    Password
                  </Typography>
                </InputLabel>
                <TextField
                  size={"small"}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={values.password}
                  fullWidth
                  onChange={handleChange}
                  slotProps={{
                    input: {
                      endAdornment: <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        // onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>,
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: commonCss.inputBackgroundColor,
                      borderRadius: commonCss.inputBorderRadius,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: commonCss.inputBorderColor,
                    },
                  }}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <InputLabel>
                  <Typography
                    variant="body2"
                    fontWeight={commonCss.labelFontWeight}
                    color={commonCss.labelInputColor}
                  >
                    User Type
                  </Typography>
                </InputLabel>
                <Select
                size="small"
                displayEmpty
                value={values.userTypeId}
                name="userTypeId"
                inputProps={{ "aria-label": "Technician" }}
                sx={{
                  borderRadius: "13px",
                  backgroundColor: "white",
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '747373',
                  },
                }}
                onChange={(e)=>{
                  setFieldValue("userTypeId",e.target.value)
                  getRoleByUserType(e.target.value)
                  if(e.target.value===userTypeInfo.internal){
                    setFieldValue("companyId",companyInfo.ps)
                    setTerminalShow(true)
                  }else{
                    setFieldValue("companyId",null)
                    setTerminalShow(false)
                  }
                }}
                fullWidth
              >
                {userType.map((type,i)=>(
                  <MenuItem key={i} value={type.id}>{type.name}</MenuItem>
                ))}
                
               
              </Select>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }} sx={{display:!terminalShow?"default":"none"}}>
                <InputLabel>
                  <Typography
                    variant="body2"
                    fontWeight={commonCss.labelFontWeight}
                    color={commonCss.labelInputColor}
                  >
                    Company
                  </Typography>
                </InputLabel>
                <Select
                size="small"
                displayEmpty
                value={values.companyId}
                name="companyId"
                inputProps={{ "aria-label": "Technician" }}
                sx={{
                  borderRadius: "13px",
                  backgroundColor: "white",
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '747373',
                  },
                }}
                onChange={handleChange}
                fullWidth
              >
                {company.map((type,i)=>(
                  <MenuItem key={i} value={type.id} sx={{display:type.id===companyInfo.ps?"none":"default"}}>{type.name}</MenuItem>
                ))}
                
               
              </Select>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }} sx={{display:terminalShow?"default":"none"}}>
                <InputLabel>
                  <Typography
                    variant="body2"
                    fontWeight={commonCss.labelFontWeight}
                    color={commonCss.labelInputColor}
                  >
                    Terminal
                  </Typography>
                </InputLabel>
                <Select
                size="small"
                displayEmpty
                value={values.terminalIds}
                name="terminalIds"
                sx={{
                  borderRadius: "13px",
                  backgroundColor: "white",
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '747373',
                  },
                }}
                onChange={(e)=>{
                  setFieldValue("terminalIds",[e.target.value])
                  
                }}
                fullWidth
              >
                {terminal.map((type,i)=>(
                  <MenuItem key={i} value={type.id} >{type.name}</MenuItem>
                ))}
                
               
              </Select>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Validity Upto
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DatePicker
                              ampm={false}
                              format="DD/MM/YYYY"
                              value={
                                values.validityUpto !== null
                                  ? dayjs(values.validityUpto)
                                  : null
                              }
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              onChange={(newValue) => {
                                console.log(newValue);
                                if (newValue !== null) {
                                  if (`${newValue.$d}` !== "Invalid Date") {
                                    const dateFrom = moment(newValue.$d)
                                    setFieldValue(
                                      "validityUpto",
                                      dateFrom
                                    );

                                    // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                    // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                    // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                    // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                    // setFieldValue("installationDate", dateFormat);
                                  }
                                }
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor:commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }} sx={{display:role.length!==0?"default":"none"}}>
                <InputLabel>
                  <Typography
                    variant="body2"
                    fontWeight={commonCss.labelFontWeight}
                    color={commonCss.labelInputColor}
                  >
                    Role
                  </Typography>
                </InputLabel>
                {/* <Select
                size="small"
                displayEmpty
                value={values.roleIds[0]}
                name="roleIds"
                inputProps={{ "aria-label": "Technician" }}
                sx={{
                  borderRadius: "13px",
                  backgroundColor: "white",
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '747373',
                  },
                }}
                onChange={(e)=>{setFieldValue("roleIds",[e.target.value])}}
                fullWidth
              >
                {role.map((type,i)=>(
                  <MenuItem key={i} value={type.id}>{type.roleName}</MenuItem>
                ))}
                
               
              </Select> */}
              
              <NormalTable data={role} columns={columns}/>
              </Grid2>
              <Grid2 container size={12} justifyContent={"flex-end"} gap="1rem">
                <Button
                  onClick={handleCancel}
                  variant="contained"
                  sx={{
                    backgroundColor: commonCss.green,
                    borderRadius: commonCss.buttonBorderRadius,
                    textTransform: "none",
                    padding: "0.6rem 1rem",
                    color: commonCss.buttonTextColor,
                    width: "10vw",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type={"submit"}
                  variant="contained"
                  disabled={loading}
                  sx={{
                    backgroundColor: commonCss.green,
                    borderRadius: commonCss.buttonBorderRadius,
                    textTransform: "none",
                    padding: "0.6rem 1rem",
                    color: commonCss.buttonTextColor,
                    width: "10vw",
                  }}
                >
                  Submit
                </Button>
              </Grid2>
            </Grid2>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default UserModal;
