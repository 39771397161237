import React from "react";
import ApexCharts from "react-apexcharts";

const PermitByStatus = ({ data }) => {
  const state = {
    series: data.permitByStatus.value,
    options: {
      chart: {
        type: "donut",
      },
      colors: [
        "#1395CF",
        "#EAC644",
        "#5DB75E",
        "#EA3922",
        "#98D19A",
        "#D77A22",
        "#F9ADA8",
        "#A76CFB",
        "#A71D48",
        "#FE8B7E",
        "#453191",
        "#B4BFDD",
        "#3D5EAC",
        "#62B146",
        "#D0DD37",
        "#F3EC3A",
        "#F9BD19",
        "#F99B1E",
        "#F15523",
        "#7C3697"
      ],
      stroke: {
        width: 0,
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
            //   show: true,
            //   total: {
            //     showAlways: true,
            //     show: true,
            //   },
            },
          },
        },
      },
      labels: data.permitByStatus.status,
      dataLabels: {
        enabled: false,
      },

      title: {
        text: "Permit To Work By Status",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      legend: {
        formatter: function (seriesName, opts) {
          return [seriesName, " - ", opts.w.globals.series[opts.seriesIndex]];
        },
      },
    },
  };

  return (
    <ApexCharts
      options={state.options}
      series={state.series}
      type="donut"
      height={300}
    />
  );
};

export default PermitByStatus;
