export const roleInfo = {
  agt: "605ba1bc-fffa-4032-e1c6-08dcde22af85",
  technican: "48692a51-30fb-449b-1591-08dcdcc6195b",
  pi: "f8ff551f-93ba-48d4-5326-08dce068874f",
  lpi: "97b4e6b5-5175-4774-5329-08dce068874f",
  engineer: "cc6cbbb6-f9ae-4dc8-5325-08dce068874f",
  contractStaff: "6050d7cc-c9f3-4dfc-1592-08dcdcc6195b",
  oe: "f8f11e55-8a6c-4cb7-5323-08dce068874f",
  controller: "293d5ed8-c4f8-416e-a2d3-acb80c574806",
  HSSE: "52b41b62-fb86-4a86-5328-08dce068874f",
  operationManager: "d2ab4589-7891-4e3c-5327-08dce068874f",
  liftingInspector: "5bc0024b-f2ad-4d29-b20e-ef5648dccdc8",
  sme: "25c98030-a83f-4327-5322-08dce068874f",
  isolationVerifier: "d1596e30-8aad-47d8-aa3d-725a0c19d87b",
  isolationOwner: "73d7e935-9565-4565-80af-3e6ab5cbc729",
  admin:"e8ee0a26-505b-4494-767c-08dce75fcb6f"
};
export const terminalInfo = {
  terminal1: "058f18e7-d04e-48dc-bbca-5b23be6a0717",
  terminal2: "0471f94b-f8aa-4669-afde-722d33721ce6",
  terminal3: "058f18e7-d04e-48dc-bbca-5b23be6a0717",
};
export const companyInfo = {
  ps: "f6b006ef-b07b-4b79-8b29-ebd6b4bee7af",
};
export const userTypeInfo = {
  internal: "a6df7d9a-8dcb-4c7a-957a-2a85df21d01f",
};
export const infoId = {
  low: "5a07c230-a3da-4af8-b2b9-9fe369942bed",
  classifiedAreaWithWhatOtherId: "b146835f-cf3d-4ffc-b582-a9f4a4639a5d",
  operationalInformationOtherId: "80600331-3e11-428d-9e96-9169e6fe8a89",
};
export const operationMeasure={
  mechanical:"596a9776-509a-4541-8277-2c34e8f18f83",
  electrical:"597f7b99-c72c-4236-9090-7fcfa69c7324",
  instrumentation:"a1de872f-e0e7-4ed1-a313-9828b11917a0"
}
export const api = {
  account: {
    login: "/Account/authenticate",
  },
  PTWForm: {
    getAllForm: "/PTWForm/GetFormList",
    getByAssignedUser: "/PTWForm/GetMyFormListAsAssignedUser",
    formInitiate: "/PTWForm/InitiatePTWForm",
    saveForm: "/PTWForm/SavePTWForm",
    saveApproval: "/PTWForm/saveApproval",
    getDetailById: "/PTWForm/GetPTWFormDetail",
    getPendingList:"/PTWForm/getPendingApprovalList",
    reAssign:"/PTWForm/ReassignUsers",
    permitClose:"/PTWForm/ClosePermit",
    cancelPermit:"/PTWForm/SaveCanceledPermit",
    saveDocumentApprove:"/PTWForm/saveDocumentApprove"
  },
  terminal: {
    title: "Terminal",
    getAll: "/TerminalMaster/GetAll",
    add: "/TerminalMaster/Add",
    edit: "/TerminalMaster/Edit",
  },
  site: {
    title: "Site",
    getAll: "/SiteMaster/GetAll",
    add: "/SiteMaster/Add",
    edit: "/SiteMaster/Edit",
  },
  role: {
    title: "Role",
    getAll: "/RoleMaster/GetAll",
    add: "/RoleMaster/Add",
    edit: "/RoleMaster/Edit",
    getByUserType: "/RoleMaster/GetAllRolesByUserType",
  },
  company: {
    title: "Company",
    getAll: "/CompanyMaster/GetAll",
    add: "/CompanyMaster/Add",
    edit: "/CompanyMaster/Edit",
  },
  user: {
    title: "User",
    getAll: "/UserMaster/GetAll",
    add: "/UserMaster/Add",
    edit: "/UserMaster/Edit",
    getUserByCompanyId: "/UserMaster/GetUserByCompanyId",
    getUserByCompanyIdAndRoleId: "/UserMaster/GetCompanyUserByRoleId",
  },
  userType: {
    getAll: "/UserTypeMaster/GetAll",
  },
  hotWork: {
    getByHotWorkId: "/PTWHotWorkGasTest/GetAllByHotWorkId",
    addGasTest: "/PTWHotWorkGasTest/Add",
    editGasTest: "/PTWHotWorkGasTest/Edit",
    getDataByFormId: "/PTWHotWorkCertificate/GetAllByFormId",
    editHotWork: "/PTWHotWorkCertificate/Edit",
    deleteCertificate:"/PTWHotWorkCertificate/Delete",
    getById: "/PTWHotWorkCertificate/Get",
    closeCertificate:"/PTWHotWorkCertificate/PTWCloseCertificate" ,
    clone:"/PTWHotWorkCertificate/CloneById",
    delete:"/PTWHotWorkGasTest/Delete"
  },
  confinedSpace: {
    getByCSId: "/PTWConfinedSpaceGasTest/GetAllByConfinedSpaceId",
    addGasTest: "/PTWConfinedSpaceGasTest/Add",
    editGasTest: "/PTWConfinedSpaceGasTest/Edit",
    getDataByFormId: "/PTWConfinedSpaceCertificate/GetAllByFormId",
    editCS: "/PTWConfinedSpaceCertificate/Edit",
    deleteCertificate:"/PTWConfinedSpaceCertificate/Delete",
    getById: "/PTWConfinedSpaceCertificate/Get",
    closeCertificate:"/PTWConfinedSpaceCertificate/PTWCloseCertificate",
    clone:"/PTWConfinedSpaceCertificate/CloneById",
    delete:"/PTWConfinedSpaceGasTest/Delete"
  },
  excavation: {
    getDataByFormId: "/PTWExcavationCertificate/GetAllByFormId",
    getById: "/PTWExcavationCertificate/Get",
    edit: "/PTWExcavationCertificate/Edit",
    deleteCertificate:"/PTWExcavationCertificate/Delete",
    closeCertificate:"/PTWExcavationCertificate/PTWCloseCertificate"
  },
  systemOverriding: {
    getDataByFormId: "/PTWSystemOverridingCertificate/GetAllByFormId",
    getById: "/PTWSystemOverridingCertificate/Get",
    edit: "/PTWSystemOverridingCertificate/Edit",
    deleteCertificate:"/PTWSystemOverridingCertificate/Delete",
    closeCertificate:"/PTWSystemOverridingCertificate/PTWCloseCertificate"
  },
  isolation: {
    getDataByFormId: "/PTWIsolationCertificate/GetAllByFormId",
    getById: "/PTWIsolationCertificate/Get",
    edit: "/PTWIsolationCertificate/Edit",
    deleteCertificate:"/PTWIsolationCertificate/Delete",
    closeCertificate:"/PTWIsolationCertificate/PTWCloseCertificate",
    deisolation:"/PTWIsolationCertificate/PTWDeisolation"
  },
  liftingAndHoisting: {
    getDataByFormId: "/PTWLiftingAndHoistingCertificate/GetAllByFormId",
    edit: "/PTWLiftingAndHoistingCertificate/Edit",
    getById: "/PTWLiftingAndHoistingCertificate/Get",
    deleteCertificate:"/PTWLiftingAndHoistingCertificate/Delete",
    closeCertificate:"/PTWLiftingAndHoistingCertificate/PTWCloseCertificate"
  },
  radiation: {
    getDataByFormId: "/PTWRadiationCertificate/GetAllByFormId",
    edit: "/PTWRadiationCertificate/Edit",
    getById: "/PTWRadiationCertificate/Get",
    deleteCertificate:"/PTWRadiationCertificate/Delete",
    closeCertificate:"/PTWRadiationCertificate/PTWCloseCertificate"
  },
  attributeValue: {
    getByKeyName: "/AttributeValue/GetAllByKeyName",
  },
  formType: {
    getAll: "/PTWFormTypeMaster/GetAll",
  },
  methodstatement: {
    create: "/PTWMethodStatement/Add",
    getByFormId: "/PTWMethodStatement/GetAllByFormId",
    edit: "/PTWMethodStatement/Edit",
  },
  additionalPrecaution: {
    getByFormId: "/PTWAdditionalPrecaution/GetAllByFormId",
    add: "/PTWAdditionalPrecaution/Add",
    edit: "/PTWAdditionalPrecaution/Edit",
    delete: "/PTWAdditionalPrecaution/Delete",
  },
  location: {
    getAll: "/LocationMaster/GetAll",
  },
  riskAssessment: {
    getByFormId: "/PTWRiskAssessment/GetAllByFormId",
    add: "/PTWRiskAssessment/Add",
    edit: "/PTWRiskAssessment/Edit",
    getByRiskId: "/PTWRSHazardAnalysisWorksheet/GetAllByRiskAssessmentId",
    getHazardByFormId:"/PTWRSHazardAnalysisWorksheet/GetAllByFormId",
    hazardAdd: "/PTWRSHazardAnalysisWorksheet/Add",
    hazardEdit: "/PTWRSHazardAnalysisWorksheet/Edit",
  },

  PTWPermitShift: {
    add: "/PTWPermitShift/Add",
    edit: "/PTWPermitShift/Edit",
    getByFormId: "/PTWPermitShift/GetAllByFormId",
  },
  dashboard:{
    getAllDashboardData:"/PTWForm/GetAllDashboardData",
    getAllDashboardDataByDateRange:"/PTWForm/GetAllDashboardDatabyDateRange"
  }
};
