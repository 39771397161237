import { Box, Container, Divider, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Typography } from "@mui/material";
import { commonCss } from "../../StyleConfig/CommonCss";
import { Clear } from "@mui/icons-material";
import { imageURL } from "../../axios/axios";

const ViewImage = ({ selectedFile,handleCloseClick, dialogTitle }) => {
  let imageBase64 = selectedFile.data.split("upload").length===2?imageURL+selectedFile.data:selectedFile.data

  // if (selectedFile && selectedFile.data) {
  //   console.log("Selected File Data:", selectedFile.data);
  //   if (selectedFile.data.startsWith("data:image")) {
  //     imageBase64 = selectedFile.data;
  //   } else {
  //     let fileTypePrefix = "data:image/jpeg;base64,";
  //     if (selectedFile.data.startsWith("iVBORw0KGgo")) {
  //       fileTypePrefix = "data:image/png;base64,";
  //     }
  //     imageBase64 = fileTypePrefix + selectedFile.data;
  //   }
  // }
 

  return (
    <>
   
      <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
             {/* <Typography variant="h5" fontWeight="bold" color="white">{dialogTitle}</Typography> */}
              <IconButton onClick={handleCloseClick}>
                <Clear sx={{color:"white"}}/>
              </IconButton>
            
          </Box>
          <Divider sx={{borderColor: "white", borderWidth: 1,width:"100%"}}/>
          <Box sx={{display:"flex" ,flexDirection:"column",width:"100%",alignItems:"center",justifyContent:"center"}}>
      {selectedFile.data ? (
        <img
          src={`${imageBase64}`}
          alt="Uploaded Image"
          style={{maxWidth:"80vw",maxHeight:"80vh"}}
        />
      ) : (
        <Typography>No image available</Typography>
      )}
      </Box>
    </>
  );
};

export default ViewImage;
