import {
  Button,
  Divider,
  Grid2,
  Paper,
  Typography,
  TextField,
  Select,
  InputLabel,
  Box,
  IconButton,
  Dialog,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { commonCss } from "../../../StyleConfig/CommonCss";
import {
  Add,
  ArrowBack,
  DeleteOutline,
  FileUploadOutlined,
} from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { EditOutlined } from "@mui/icons-material";
import {
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { useSelector } from "react-redux";
import NormalTable from "../../../Component/NormalTable";
import PSImageRiskAssessment from "../../../Images/PSImageRiskAssessment.png";
import NormalTableWithRadius from "../../../Component/NormalTableWithRadius";
import Loading from "../../../Component/Loading";
import { Form, Formik, ErrorMessage } from "formik";
import { api, roleInfo } from "../../API/config";
import { useSearchParams } from "react-router-dom";
import { axiosPrivate } from "../../../axios/axios";
import moment from "moment";
import dayjs from "dayjs";
import { toast } from "react-toastify";

import * as Yup from 'yup';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';

import ErrorProps from "../../../Component/Errorprops";
import HazardWorkSheet from "./HazardWorkSheet";

const RiskAssessment = ({ handleCloseClick, approvedData }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [editData, setEditData] = useState(false);
  const [overAll, setOverAll] = useState([]);

  // const [labourList, setLabourList] = useState([]);
  // const [labour, setLabour] = useState("");
  // const [editLabour, setEditLabour] = useState({ bol: false, i: null });


    
 
  const login = useSelector((state) => state.auth.loginInfo);
  const loginRoleId=String(login.roleIds).split(",")
  const [roleAccess, setRoleAccess] = useState({
    contractStaff: false,
    HSSE: false,
  });
  const [approveInfo, setApproveInfo] = useState({
    hsscName: "",
  });
 

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
  
    const getLink = api.riskAssessment.getByFormId;
    axiosPrivate
      .get(`${getLink}?formId=${searchParams.get("id")}`)
      .then((res) => {

        const resData = res.data.data[0];

        setRoleAccess({
          contractStaff:
          !loginRoleId.includes(roleInfo.contractStaff) ||
            approvedData.isContractApproved,
          HSSE:
            !loginRoleId.includes(roleInfo.HSSE) ||
            approvedData.isHSSEVerifierApproved ||
            res.data.data[0]?.isHSSEVerifierApproved,
        });
        setApproveInfo({
          hsscName: res.data.data[0]?.isHSSEVerifierApproved
            ? res.data.data[0]?.hsseVerifierUserDetail?.firstName +
              " " +
              res.data.data[0]?.hsseVerifierUserDetail?.lastName
            : "",
        });
        if (res.data.data.length !== 0) {
          setData(res.data.data[0]);
          setEditData(true);
          
        } else {
          setData({});

          setEditData(false);
        }
  
        const getOverAllLink = api.attributeValue.getByKeyName;
        return axiosPrivate.get(`${getOverAllLink}?keyName=overallAssessment`);
      })
      .then((overallRes) => {
        setOverAll(overallRes.data.data);
      })
      .catch((error) => {
        console.error(error);
        setData({});
      });

    const getOverAllLink = api.attributeValue.getByKeyName;
    axiosPrivate
      .get(`${getOverAllLink}?keyName=overallAssessment`)
      .then((res) => {
        setOverAll(res.data.data);
      })
      .catch((e) => {
        console.log(e);
        setOverAll([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };
 

  const initialValues = {
    formId: searchParams.get("id"),
    riskAssessmentOf: editData ? data.riskAssessmentOf : "",
    assessmentDate: editData ? data.assessmentDate : moment().utc(),
    activityDescription: editData ? data.activityDescription : "",
    equipmentDescription: editData ? data.equipmentDescription : "",
    overallAssessment: editData ? data.overallAssessment : null,
    otherComments: editData ? data.otherComments : "",
    hsseVerifierApprovedDate: editData ? data.hsseVerifierApprovedDate : "",
    isHSSEVerifierApproved: editData ? data.isHSSEVerifierApproved : null,
    staffDetails:
      editData && data?.staffDetails !== null
        ? String(data?.staffDetails).split(",")
        : [],
    labour: "",
    editLabour:{bol:false,i:null},
    confirmIndex:null
  };
  const validationSchema = (editData) =>
    Yup.object().shape({
      riskAssessmentOf: Yup.string().required("This field is required"),
      staffDetails: Yup.array().min(1, "This field is required"),
      // labourList: editData
      // ? Yup.string().notRequired()
      // : Yup.string().required('This field is required'),

      activityDescription: Yup.string().required("This field is required"),

      equipmentDescription: Yup.string().required("This field is required"),

      otherComments: Yup.string().required("This field is required"),

      overallAssessment: Yup.string().required("This field is required"),
    });
  const onSubmit = async (value) => {
    let HSSEappro = loginRoleId.includes(roleInfo.HSSE);
    const postDataEd = {
      ...value,
      isApproved: HSSEappro ? true : null,
      staffDetails:
        value.staffDetails.length !== 0 ? value.staffDetails.toString() : null,

    };
    console.log("onsoutput", postDataEd);
    if (editData) {
      const editLink = api.riskAssessment.edit;
      await axiosPrivate
        .put(`${editLink}/${data.id}`, { id: data.id, ...postDataEd })
        .then((res) => {
          toast.success("Successfully Updated");
          handleCloseClick();
        })
        .catch((e) => {
          console.log(e);
          toast.error("Not Updated");
        });
    } else {
      const createLink = api.riskAssessment.add;
      console.log("creatonsoutput", postDataEd);
      await axiosPrivate
        .post(`${createLink}`, postDataEd)
        .then((res) => {
          toast.success("Successfully Updated");
          handleCloseClick();
        })
        .catch((e) => {
          toast.error("Not Updated");
          console.log(e);
        });
    }
  };


  const listOfInstruction = [
    "Electricity",
    "Noise",
    "Lasers",
    "Radiation",
    "Vacuum ",
    "Legionella",
    "Weil's Disease",
    "Other Biological (Specify)",
    "Psychological Hazards",
    "High Pressure",
    "Explosion",
    "Fire Hazard",
    "Gas Vapor Release",
    "Toxic Chemicals",
    "Flammable Substances ",
    "Hazard to eyes",
    "Hand Tools ",
    "Dangerous Machinery ",
    "Extreme of Temperature",
    "Confined Spaces",
    "Working environment ",
    "Manual Handling ",
    "Gravity (Height or above excavations)",
    "Moving Vehicles",
    "Cleaning Devices",
    "Slips, Trip, and Falls",
    "Environmental Effects",
    "Other",
  ];
  

  

  const handleAccessor = (values, setFieldValue) => {
    if (values.editLabour.bol) {
      const updatedLabourList = [...values.staffDetails];
      updatedLabourList[values.editLabour.i] = values.labour.trim();
      setFieldValue("staffDetails", updatedLabourList);
      // setLabourList(updatedLabourList);
      setFieldValue("editLabour",{ bol: false, i: null });
      setFieldValue("labour", "");
    } else {
      if (values.labour.trim()) {
        const updatedLabourList = [
          ...values.staffDetails,
          values.labour.trim(),
        ];
        setFieldValue("staffDetails", updatedLabourList);
        // setLabourList(updatedLabourList);
        setFieldValue("labour", "");
      }
    }
  };

  const handleDelete = (index,values,setFieldValue) => {
        const updatedList = values.staffDetails.filter((_, i) => i !== index);
        setFieldValue("staffDetails",updatedList);
        setFieldValue("confirmIndex",null)
    };

 

  return (
    <>
      {!loading ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
          validationSchema={validationSchema(editData)}
        >
          {({
            handleChange,
            setFieldValue,
            values,
            resetForm,
            errors,
            touched,
            setFieldTouched,
          }) => (
          <Form>
              <Grid2 container size={12} spacing={"1rem"}>
                <Grid2 container size={12} alignItems={"center"}>
                  <Grid2 container size={4}>
                    <Box
                      sx={{
                        backgroundColor: commonCss.shadeGreen,
                        display: "flex",
                        alignItems: "cente",
                        p: 1,
                        cursor: "pointer",
                      }}
                      onClick={handleCloseClick}
                    >
                      <ArrowBack color={commonCss.green} />
                    </Box>
                  </Grid2>
                  <Grid2 container size={4} justifyContent={"center"}>
                    <Typography variant="h5" fontWeight="bold" color="white">
                      Risk Assessment
                    </Typography>
                  </Grid2>
                  <Grid2 container size={4} justifyContent={"flex-end"}>
                    <Box
                      sx={{
                        backgroundColor: commonCss.shadeGreen,
                        p: 1,
                      }}
                    >
                      <Typography variant="body1" fontWeight="bold">
                        Permit To Work No.{" "}
                        <span style={{ color: "#e53e3e" }}>2701</span>
                      </Typography>
                    </Box>
                  </Grid2>
                  <Divider
                    sx={{
                      borderColor: commonCss.red,
                      borderWidth: 1,
                      width: "100%",
                    }}
                  />
                </Grid2>

                <Grid2 container size={12}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"0.6rem"}>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Risk Assessment of
                            <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="riskAssessmentOf"
                          value={values.riskAssessmentOf}
                          onChange={handleChange}
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                          // onBlur={() =>
                          //   setFieldTouched("riskAssessmentOf", true)
                          // }
                        />
                        {errors.riskAssessmentOf && (
                            <ErrorMessage name="riskAssessmentOf">
                              {(error) => <ErrorProps>{error}</ErrorProps>}
                            </ErrorMessage>
                          )}
                      </Grid2>

                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Date and Time
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              format="DD/MM/YYYY HH:mm"
                              readOnly={roleAccess.contractStaff}
                              value={
                                values.assessmentDate !== null
                                  ? dayjs(values.assessmentDate)
                                  : null
                              }
                              onChange={(newValue) => {
                                console.log(newValue);
                                if (newValue !== null) {
                                  if (`${newValue.$d}` !== "Invalid Date") {
                                    const dateFrom = moment(newValue.$d).utc();
                                    setFieldValue("assessmentDate", dateFrom);

                                    // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                    // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                    // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                    // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                    // setFieldValue("installationDate", dateFormat);
                                  }
                                }
                              }}
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor: roleAccess.contractStaff
                                    ? commonCss.readOnlyBGColor
                                    : commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2
                        container
                        size={12}
                        alignItems={"center"}
                        spacing={1}
                      >
                        <Grid2 size={7}>
                          <InputLabel sx={{ pl: commonCss.labelPadding }}>
                            <Typography
                              variant="body2"
                              fontWeight={commonCss.labelFontWeight}
                              color={commonCss.labelInputColor}
                            >
                              Staff List
                              <Typography
                                component="span"
                                color="error"
                                fontWeight="bold"
                                sx={{ ml: 0.5 }}
                              >
                                *
                              </Typography>
                            </Typography>
                          </InputLabel>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "1rem",
                              alignItems: "center",
                            }}
                          >
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              value={values.labour}
                              slotProps={{
                                input: {
                                  readOnly: roleAccess.contractStaff,
                                },
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  backgroundColor: roleAccess.contractStaff
                                    ? commonCss.readOnlyBGColor
                                    : commonCss.inputBackgroundColor,
                                  borderRadius: commonCss.inputBorderRadius,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              onChange={(e) => {
                                setFieldValue("labour", e.target.value);
                              }}
                            />
                            <IconButton
                              sx={{
                                display: roleAccess.contractStaff
                                  ? "none"
                                  : "default",
                                border: `3px solid ${commonCss.red}`,
                                p: 0,
                              }}
                              onClick={() =>
                                handleAccessor(values, setFieldValue)
                              }
                            >
                              <Add sx={{ color: commonCss.red }} />
                            </IconButton>
                          </Box>
                          {errors.staffDetails && (
                            <ErrorMessage name="staffDetails">
                              {(error) => <ErrorProps>{error}</ErrorProps>}
                            </ErrorMessage>
                          )}
                        </Grid2>

                        <Grid2 container size={12}>
                          <Grid2
                                                      size={8}
                            sx={{
                              display:
                                values.staffDetails.length !== 0
                                  ? "default"
                                  : "none",
                            }}
                          >
                            <Paper elevation={4}>
                              {values.staffDetails.map((labData, i) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    p: 1,

                                    backgroundColor: roleAccess.contractStaff
                                      ? commonCss.readOnlyBGColor
                                      : commonCss.inputBackgroundColor,
                                  }}
                                >
                                  <Typography>{i + 1}</Typography>
                                  <Typography>{labData}</Typography>
                                  <IconButton
                                    disabled={roleAccess.contractStaff}
                                    onClick={() => {
                                      setFieldValue("labour", labData);
                                      setFieldValue("editLabour",{ bol: true, i: i });
                                    }}
                                  >
                                    <EditOutlined
                                      sx={{ color: commonCss.red }}
                                    />
                                      </IconButton>
                                      {values.confirmIndex === i ? (


                                          <Box
                                              sx={{
                                                  display: 'flex',
                                                  justifyContent: 'flex-end',
                                                  alignItems: 'center',
                                                  gap: 1, 
                                              }}
                                          >
                                              <ErrorProps>Are you sure wants to delete</ErrorProps>
                                              <Button
                                                  onClick={() => handleDelete(i,values,setFieldValue)}
                                                  sx={{
                                                      color: commonCss.red, 
                                                      borderColor: commonCss.red,
                                                  }}
                                                  size="small"
                                                  variant="outlined"
                                              >
                                                  Yes
                                              </Button>
                                              <Button
                                                  onClick={() => {setFieldValue('confirmIndex',null)}}
                                                  color="primary"
                                                  size="small"
                                                  variant="outlined"
                                              >
                                                  No
                                              </Button>
                                          </Box>

                                      ) : (
                                          
                                          <IconButton
                                              disabled={roleAccess.contractStaff}
                                              onClick={() => setFieldValue("confirmIndex",i)}
                                          >
                                              <DeleteOutlined sx={{ color: commonCss.red }} />
                                          </IconButton>
                                      )}
                                </Box>
                              ))}
                            </Paper>
                          </Grid2>
                        </Grid2>
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Description of activity
                            <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          multiline
                          name="activityDescription"
                          value={values.activityDescription}
                          onChange={handleChange}
                          rows={2}
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                          // onBlur={() =>
                          //   setFieldTouched("activityDescription", true)
                          // }
                        />
                        {
                          errors.activityDescription && (
                            <ErrorMessage name="activityDescription">
                              {(error) => <ErrorProps>{error}</ErrorProps>}
                            </ErrorMessage>
                          )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Description of equipment
                            <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="equipmentDescription"
                          value={values.equipmentDescription}
                          onChange={handleChange}
                          multiline
                          rows={2}
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                          // onBlur={() =>
                          //   setFieldTouched("equipmentDescription", true)
                          // }
                        />
                        {
                          errors.equipmentDescription && (
                            <ErrorMessage name="equipmentDescription">
                              {(error) => <ErrorProps>{error}</ErrorProps>}
                            </ErrorMessage>
                          )}
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={12}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"0.6rem"}>
                      <HazardWorkSheet  formId={searchParams.get("id")}  roleAccess={roleAccess} />
                    
                    <Grid2 size={{ xs: 12, md: 6 }}>
                      <InputLabel sx={{ pl: 0.5 }}>
                        <Typography
                          variant="body2"
                          fontWeight="500"
                          color="black"
                        >
                          Overall Assessment of Risk (using Risk Assessment
                          Matrix): Action needed: (inc responsible party and
                          closeout target)
                          <Typography
                            component="span"
                            color="error"
                            fontWeight="bold"
                            sx={{ ml: 0.5 }}
                          >
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <Select
                        size="small"
                        defaultValue=""
                        displayEmpty
                        name="overallAssessment"
                        value={values.overallAssessment}
                        onChange={handleChange}
                        readOnly={roleAccess.contractStaff}
                        sx={{
                          borderRadius: "13px",
                          backgroundColor: roleAccess.contractStaff
                            ? commonCss.readOnlyBGColor
                            : "white",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "747373",
                          },
                        }}
                        // onClose={() =>
                        //   setFieldTouched("overallAssessment", true)
                        // }
                        fullWidth
                      >
                        {overAll.map((all, i) => (
                          <MenuItem value={all.id}>{all.name}</MenuItem>
                        ))}
                      </Select>
                      {/* {errors.overallAssessment && ( */}
                      <ErrorMessage name="overallAssessment">
                        {(error) => <ErrorProps>{error}</ErrorProps>}
                      </ErrorMessage>
                      {/* )} */}
                    </Grid2>
                    <Grid2
                      container
                      size={{ xs: 12, md: 6 }}
                      alignItems={"flex-end"}
                    >
                      <InputLabel sx={{ pl: commonCss.labelPadding }}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.labelFontWeight}
                          color={commonCss.labelInputColor}
                        >
                          Comments (if any)
                          <Typography
                            component="span"
                            color="error"
                            fontWeight="bold"
                            sx={{ ml: 0.5 }}
                          >
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        name="otherComments"
                        value={values.otherComments}
                        onChange={handleChange}
                        slotProps={{
                          input: {
                            readOnly: roleAccess.contractStaff,
                          },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            backgroundColor: roleAccess.contractStaff
                              ? commonCss.readOnlyBGColor
                              : commonCss.inputBackgroundColor,
                            borderRadius: commonCss.inputBorderRadius,
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: commonCss.inputBorderColor,
                          },
                        }}
                        // onBlur={() => setFieldTouched("otherComments", true)}
                      />
                      { errors.otherComments && (
                        <ErrorMessage name="otherComments">
                          {(error) => <ErrorProps>{error}</ErrorProps>}
                        </ErrorMessage>
                      )}
                    </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={12}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                      textTransform: "uppercase",
                    }}
                  >
                    <Grid2 container size={12} spacing={"0.7rem"}>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.mainLabelHeading}
                          color={commonCss.red}
                        >
                          NON EXHAUSTIVE LIST OF HAZARD CATEGORIES
                        </Typography>
                      </Grid2>
                      <Grid2 container size={12}>
                        {listOfInstruction.map((listOf, i) => (
                          <Grid2 size={4} key={i}>
                            <Typography
                              variant="body2"
                              fontWeight={commonCss.normalSentenceWeight}
                              color={commonCss.normalSenternceColor}
                              sx={{ textTransform: "none" }}
                            >
                              {listOf}
                            </Typography>
                          </Grid2>
                        ))}
                      </Grid2>
                      <Grid2 container size={12}>
                        <Grid2 size={12}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.mainLabelHeading}
                            color={commonCss.green}
                          >
                            WHEN CONDUCTING A RISK ASSESSMENT
                          </Typography>
                        </Grid2>
                        <Grid2 size={12}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.normalSentenceWeight}
                            color={commonCss.normalSenternceColor}
                            sx={{ textTransform: "none" }}
                          >
                            The assessment should be carried out in good time
                            prior to the relevant decision being made When a
                            specific assessment is needed then such an
                            assessment, rather than a generic assessment should
                            be made The risk from each activity should be
                            considered A team of people or employees with
                            practical knowledge of the process/activity being
                            assessed should be used as appropriate Consultants
                            should be used where necessary All the hazards
                            associated with a particular activity should be
                            considered Hazards and risk controls should be
                            linked The results of the assessment should be
                            properly used.
                          </Typography>
                        </Grid2>
                      </Grid2>
                      <Grid2 container size={12}>
                        <Grid2 size={12}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.mainLabelHeading}
                            color={commonCss.green}
                            sx={{ textTransform: "uppercase" }}
                          >
                            PS Pipeline to provide high resolution image
                          </Typography>
                        </Grid2>
                        <Grid2 size={12}>
                          <img
                            src={PSImageRiskAssessment}
                            style={{ width: "100%" }}
                            alt="PS image"
                          />
                        </Grid2>
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={12}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"0.7rem"}>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.mainLabelHeading}
                          color={commonCss.red}
                          textAlign={"center"}
                        >
                          HSSE Verifier
                        </Typography>
                        <Divider
                          sx={{
                            borderColor: commonCss.red,
                            borderWidth: 1,
                            width: "100%",
                            mt: 1,
                          }}
                        />
                      </Grid2>
                      <Grid2 size={12}>
                        {" "}
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.normalSentenceWeight}
                          color={commonCss.normalSenternceColor}
                          sx={{ textTransform: "none" }}
                        >
                          I certify that I have reviewed the proposed work and I
                          am satisfied that it is adequately described on the
                          permit to work and that the precautions detailed are
                          adequate and clearly defined
                        </Typography>
                      </Grid2>
                      <Grid2 size={4}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Name
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          value={approveInfo.hsscName}
                          slotProps={{
                            input: {
                              readOnly: true, // Set readOnly to true
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.HSSE
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={4}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Date/Time
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              format="DD/MM/YYYY HH:mm"
                              value={
                                values.isHSSEVerifierApproved
                                  ? dayjs(values.hsseVerifierApprovedDate)
                                  : null
                              }
                              readOnly
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor: roleAccess.HSSE
                                    ? commonCss.readOnlyBGColor
                                    : commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 container size={4}>
                        <Button
                          type="submit"
                          variant="contained"
                          disabled={values.isHSSEVerifierApproved}
                          sx={{
                            backgroundColor: commonCss.green,
                            borderRadius: commonCss.buttonBorderRadius,
                            textTransform: "none",
                            padding: "0.6rem 1rem",
                            color: commonCss.buttonTextColor,
                            width: "15vw",
                            marginTop: "1rem",
                            display: roleAccess.HSSE ? "none" : "default",
                          }}
                          // onClick={() => {
                          //   setFieldValue("isEngineerApproved", true);
                          //   setFieldValue("engineerId", login.userId);
                          //   setFieldValue(
                          //     "engineerApprovedDate",
                          //     moment().format("YYYY-MM-DDTHH:mm:ss")
                          //   );
                          //   setApproveInfo({
                          //     ...approveInfo,
                          //     engineerName:
                          //       login.firstName + " " + login.lastName,
                          //   });
                          // }}
                        >
                          Approve
                        </Button>
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>

                <Grid2 container size={12} justifyContent={"flex-end"}>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      backgroundColor: commonCss.green,
                      borderRadius: commonCss.buttonBorderRadius,
                      textTransform: "none",
                      padding: "0.6rem 1rem",
                      color: commonCss.buttonTextColor,
                      width: "10vw",
                      display:
                        approvedData.isContractApproved ||
                        roleAccess.contractStaff
                          ? "none"
                          : "default",
                    }}
                    // onClick={()=>{setLabour(true)}}
                  >
                    Submit
                  </Button>
                </Grid2>
              </Grid2>
            </Form>
          )}
        </Formik>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default RiskAssessment;
