import axios, { AxiosRequestConfig } from "axios";
import {store} from "../Redux/store"
import {toast} from "react-toastify"
import { updateAccessToken } from "../Redux/authentication";
import { logout } from "../Redux/authentication";


//  export const baseURL="https://eptw-api.solverthoughts.com/api"
// export const baseURL="https://opc.orienseam.com/api"
export const baseURL="https://eptwapi.oriensetech.com/api"
export const imageURL="https://eptwapi.oriensetech.com/"




export const axiosPrivate = axios.create({
  baseURL:baseURL,
});


axiosPrivate.interceptors.request.use(
  (config)=> {
    const storeData = store.getState();
    const  tokenData=storeData.auth.loginInfo.jwtToken
    if(tokenData!==undefined){
      config.headers = config.headers || {};
      config.headers["Authorization"] = `Bearer ${tokenData}`;
    }
    return config;
  }
);
let isRefreshing = false;
let refreshQueue = [];
let unauthorizedUrls = [];
axiosPrivate.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const storeData = store.getState();
    const refreshToken = storeData.auth.loginInfo.refreshToken;

    if (error.response && error.response.status === 401) {


      // Store the 401 URL in the array
      if (originalRequest?.url) {
        unauthorizedUrls.push(originalRequest.url);
      }

      // Optionally log all 401 URLs for debugging
      console.log("all401url:", unauthorizedUrls);
    }

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry &&
      refreshToken
    ) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          refreshQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
            return axiosPrivate(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        axios.interceptors.request.use(request => {
          console.log('Request Headers:', request.headers);
          return request;
        });
        
        const res = await axios.post(
          `${baseURL}/Account/refresh-auth`, 
          { 
            token: refreshToken, 
          
            });
        
        console.log('Response Data:', res.data);
        
        const newAccessToken = res.data.data.jwtToken;

        // Update the store with new tokens
        store.dispatch(updateAccessToken(res.data.data));
        console.log("token",res.data.data.jwtToken)
        console.log("Updated Token:", store.getState().auth.loginInfo.jwtToken);

        // Update the default headers and retry the original request
        axiosPrivate.defaults.headers.common["Authorization"] = `Bearer ${newAccessToken}`;
        refreshQueue.forEach((prom) => prom.resolve(newAccessToken));
        refreshQueue = [];
        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
        return axiosPrivate(originalRequest);
      } catch (err) {
        // Handle refresh token expiration or errors
        if (err.response?.status === 400) {
          store.dispatch(logout()); // Use the action creator
          toast.warning("Session Expired");
        }
        
        refreshQueue.forEach((prom) => prom.reject(err));
        refreshQueue = [];
        return Promise.reject(err);
      } finally {
        isRefreshing = false;
      }
    }

    if (error.response) {
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);


