import { Box, Divider, Grid2, IconButton, MenuItem, Select, Typography,InputLabel,TextField, Paper, Checkbox, FormControlLabel, Button, createFilterOptions, Autocomplete } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { commonCss } from '../../StyleConfig/CommonCss'
import { Add, EditOutlined, Stop } from '@mui/icons-material'
import NormalTableWithRadius from '../../Component/NormalTableWithRadius'
import { DatePicker, DateTimePicker, LocalizationProvider, renderTimeViewClock, TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoItem } from '@mui/x-date-pickers/internals/demo'
import moment from 'moment'
import dayjs from 'dayjs'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { axiosPrivate } from '../../axios/axios'
import { api, companyInfo, roleInfo } from '../API/config'
import { Form, Formik } from 'formik'
import Loading from '../../Component/Loading'
import { useSelector } from 'react-redux'
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import { toast } from 'react-toastify'
import ErrorProps from '../../Component/Errorprops'

const PTWForm2 = ({ data, getData }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const login = useSelector((state) => state.auth.loginInfo);
  const [confirmIndex, setConfirmIndex] = useState(null);
  const loginRoleId= String(login.roleIds).split(",")
  const [holderName, setHolderName] = useState({
    engineer: data.isEngineerApproved
      ? data?.engineerUser?.firstName + " " + data?.engineerUser?.lastName
      : "",
    om: data.isOperationMangerApproved
      ? data?.operationMangerUser?.firstName +
        " " +
        data?.operationMangerUser?.lastName
      : "",
    hsse: data.isHSSEApproved
      ? data?.hsseVerifierUser?.firstName +
        " " +
        data?.hsseVerifierUser?.lastName
      : "",
    pi: data.isPermitIssuerApproved
      ? data?.permitIssuerUser?.firstName +
        " " +
        data?.permitIssuerUser?.lastName
      : "",
    lpi: data.isLeadPermitIssuerApproved
      ? data?.leadPermitIssuerUser?.firstName +
        " " +
        data?.leadPermitIssuerUser?.lastName
      : "",
    phClose: data.isPHPermitClosed
      ? data?.permitHolderUser?.firstName +
        " " +
        data?.permitHolderUser?.lastName
      : "",
    lpiAndPiClose:
      data.permitClosedByLPIId !== null
        ? data?.permitClosedByLPIUser?.firstName +
          " " +
          data?.permitClosedByLPIUser?.lastName
        : "",
  });
  const [loading, setLoading] = useState(false);
  const [precautionsData, setPrecautionsData] = useState([]);
  const [getPrecaution, setGetPrecaution] = useState({
    precautionSpecified: "",
    roleName:""
  });
  const [roleAccess, setRoleAccess] = useState({
    adminEngineer:(loginRoleId.includes(roleInfo.admin)||loginRoleId.includes(roleInfo.engineer))&&!data.isEngineerApproved,
    engineer:loginRoleId.includes(roleInfo.engineer) ? data.isEngineerApproved : true,
    engineerPILPI:(!loginRoleId.includes(roleInfo.engineer) &&
    !loginRoleId.includes(roleInfo.pi) &&
    !loginRoleId.includes(roleInfo.lpi))||(data.isPIPermitClosed||data.isLPIPermitClosed),
    om:
    loginRoleId.includes(roleInfo.operationManager)
        ? data.isOperationMangerApproved
        : true,
    hsse: loginRoleId.includes(roleInfo.HSSE) ? data.isHSSEApproved : true,
    pi: loginRoleId.includes(roleInfo.pi) ? data.isPermitIssuerApproved : true,
    lpi: loginRoleId.includes(roleInfo.lpi) ? data.isLeadPermitIssuerApproved : true,
    phClose: login.userId!==data.permitHolderId? true: data.isLeadPermitIssuerApproved?data.isPHPermitClosed:true,
    piAndLpoClose:
     ( loginRoleId.includes(roleInfo.pi) || loginRoleId.includes(roleInfo.lpi))&&data.isPHPermitClosed? data.isLPIPermitClosed||data.isPIPermitClosed: true,
    oeController:!data.isLeadPermitIssuerApproved||(!loginRoleId.includes(roleInfo.oe)&&!loginRoleId.includes(roleInfo.controller))||data.isPHPermitClosed
  });
  const [editPrecaution, setEditPrecaution] = useState({ bol: false, i: null });
  const [PSData, setPSData] = useState([]);

    const [reAssign,setAssign]=useState({
      engineer:false
    })
    const [reAssignData,setReAssignData]=useState({
      engineer:{
        formId:data.id,
        engineerId:null
      }
    })
    const [autoCompleteValue,setAutoCompleteValue]=useState({
      engineer:""
    })

    const [engineerData,setEngineerData]=useState([])

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();
 
  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

    useEffect(()=>{
      getDetails()
    },[])

    const getDetails=async()=>{
      setLoading(true)
     await getAdditionPrecaution()
     await getPermitshiftData();
      const getTechnican=api.user.getUserByCompanyIdAndRoleId
    await axiosPrivate.get(`${getTechnican}?companyId=${companyInfo.ps}&roleId=${roleInfo.technican}`).then(res=>{
      setEngineerData(res.data.data)
    }).catch(e=>{
      setEngineerData([])
      console.log(e)
    })
    setLoading(false)
    }
    
    const getAdditionPrecaution=async()=>{
      const getAddPreLink=api.additionalPrecaution.getByFormId
      await axiosPrivate.get(`${getAddPreLink}?formId=${searchParams.get("id")}`).then(res=>{
        const filteredData = res.data.data.filter(item => !item.isDeleted);
        setPrecautionsData(filteredData)
        console.log("precation",filteredData)
      
      }).catch(e=>{
        setPrecautionsData([])
        
        console.log(e)
      })
    }

    const initialValue={
      ...data,
      piPermitValidFrom:data.piPermitValidFrom!==null?data.piPermitValidFrom:data.actualPermitValidFrom,
      piPermitValidUpTo:data.piPermitValidUpTo!==null?data.piPermitValidUpTo:data.actualPermitValidUpTo,
      lpiPermitValidFrom:data.lpiPermitValidFrom!==null?data.lpiPermitValidFrom:data.piPermitValidFrom,
      lpiPermitValidUpTo:data.lpiPermitValidUpTo!==null?data.lpiPermitValidUpTo:data.piPermitValidUpTo,

    }
        

    const onSubmit=async(value)=>{
      // const saveFormLink=api.PTWForm.saveForm
      // // const roleBasedId={
      // //   engineer:login.roleIds===roleInfo.engineer,
      // //   om:login.roleIds===roleInfo.operationManager,
      // //   lpi:login.roleIds===roleInfo.lpi,
      // //   pi:login.roleIds===roleInfo.pi,
      // //   hsse:login.roleIds===roleInfo.HSSE,
      // // }
      // // const postData={
      // //   ...value,
        
      // //  [roleBasedId.engineer?"engineerId":roleBasedId.om?"operationMangerId":roleBasedId.lpi?"leadPermitIssuerId":roleBasedId.pi?"permitIssuerId":"hsseVerifierId"]:login.userId
      // // }
      // console.log(value)
      // await axiosPrivate.post(`${saveFormLink}`,value).then(res=>{ 
      //   console.log(res)
      const pi=loginRoleId.includes(roleInfo.pi)
        const aprovalFormLink=api.PTWForm.saveApproval
        const appData={
          id:data.id,
          isApproved:true,
          verifierId:login.userId,
          validFrom: pi?value.piPermitValidFrom:value.lpiPermitValidFrom,
          validUpTo: pi?value.piPermitValidUpTo:value.lpiPermitValidUpTo,
        }
        console.log(appData)
         axiosPrivate.post(`${aprovalFormLink}`,appData).then(res=>{
    
         navigate("/formlist")
        }).catch(e=>{
          toast.error(e.response.data.message)
         console.log(e)
        })
      //  }).catch(e=>{
      //   console.log(e)
      //  })
    }
    const rejectApproval=async()=>{
      const aprovalFormLink=api.PTWForm.saveApproval
          const appData={
            id:data.id,
            isApproved:false,
            verifierId:login.userId
          }
          console.log(appData)
          await axiosPrivate.post(`${aprovalFormLink}`,appData).then(res=>{
      
           navigate("/formlist")
          }).catch(e=>{
           console.log(e)
          })
     }
     const handleReassign=async()=>{
      const reassignLink=api.PTWForm.reAssign
      console.log(reAssignData.technician)
      await axiosPrivate.post(`${reassignLink}`,reAssignData.technician).then(res=>{
        toast.success("Successfully Reassigned")
        setAutoCompleteValue({...autoCompleteValue,reAssignTech:""})
        setAssign({...reAssign,technician:false})
        navigate("/formlist")
      }).catch(e=>{
        console.log(e)
      })
     }


     const handleDeletePrecaution = (id) => {
      const deletePrecaution = api.additionalPrecaution.delete;
      axiosPrivate
        .delete(`${deletePrecaution}/${id}`)
        .then(() => {
          toast.success("Successfully Deleted")
          getAdditionPrecaution(); 
        })
        .catch((e) => {
          console.error(e);
        });
    };
    
    
    const precautionsColumn=[
      {
        Header:"Precautions",
        accessor:"precautionSpecified"
      },
      {
        Header:"Role",
        accessor:"roleName"
      },
      {
        Header:"User",
        accessor:"addedByUserDetail",
        Cell:({cell})=>{
          return(
            <Typography sx={{fontSize:"inherit",fontWeight:"inherit",color:"inherit"}}>
              {cell.value.firstName+" "+cell.value.lastName}
            </Typography>
          )
        }
      },
      {
        accessor:"id",
        Cell: ({ cell }) => {
          return (
            <Box sx={{ whiteSpace: "nowrap",display:"flex",justifyContent:"center",width:"100%",alignItems:'center' }}>
            
              <IconButton 
              disabled={roleAccess.engineerPILPI}
              onClick={() => {
                setEditPrecaution({ bol: true, i: cell.row.original.id });
                setGetPrecaution(cell.row.original);
              }}
            >
              <EditOutlined sx={{ color: commonCss.red }} />
            </IconButton>

          {confirmIndex === cell.row.index ? (
                                         
                              
                                         <Box
                                             sx={{
                                                 display: 'flex',
                                                 justifyContent: 'flex-end',
                                                 alignItems: 'center',
                                                 gap: 1, 
                                             }}
                                         >
                                             <ErrorProps>Are you sure wants to delete</ErrorProps>
                                             <Button
                                                 onClick={() => handleDeletePrecaution(cell.row.original.id)}
                                             sx={{
                                                 color: commonCss.red, 
                                                 borderColor: commonCss.red, }}
                                                 size="small"
                                                 variant="outlined"
                                             >
                                                 Yes
                                             </Button>
                                             <Button
                                                 onClick={() => setConfirmIndex(null)}
                                                 sx={{
                                                   color: commonCss.green, 
                                                   borderColor: commonCss.green, }}
                                                 size="small"
                                                 variant="outlined"
                                             >
                                                 No
                                             </Button>
                                         </Box>

                                 ) : (
                                    
                                     <IconButton
                                         disabled={roleAccess.engineerPILPI}
                                         onClick={() => setConfirmIndex(cell.row.index)}
                                     >
                                         <DeleteOutlined sx={{ color: commonCss.red }} />
                                     </IconButton>
                                 )}
          </Box>
        );
      },
    },
  ];
  const shiftColumns = [
    {
      Header: "Date",
      accessor: "date",
    },
    // {
    //   Header: "Start Shift",
    //   accessor: "shiftStartDateTime",
    //   // Cell: ({ cell }) => (
    //   //   <Typography
    //   //     sx={{
    //   //       fontSize: "inherit",
    //   //       fontWeight: "inherit",
    //   //       color: "inherit",
    //   //     }}
    //   //   >
    //   //     {cell.value !== null ? moment(cell.value).format("hh:mm a") : null}
    //   //   </Typography>
    //   // ),
    // },
    {
      Header: "Start Shift",
      accessor: "shiftStartDateTime",
      Cell: ({ cell }) => (
        <Typography
          sx={{
            fontSize: "inherit",
            fontWeight: "inherit",
            color: "inherit",
          }}
        >
          {cell.value !== null
            ? moment(cell.value).format("YYYY-MM-DD hh:mm a") // Format to "hh:mm a"
            : "Not Found"}{" "}
          {/* Fallback text if value is null */}
        </Typography>
      ),
    },

    {
      Header: "Permit Holder",
      accessor: "permitHolderUserDetails", // Use accessor for raw data
      Cell: ({ cell }) => {
        const { firstName, lastName } = cell.row.original.permitHolderUserDetails || {};
        return `${firstName || ""} ${lastName || ""}`.trim();
      },
    },
    {
      Header: "OE/Controller",
      accessor: "startShiftOEControllerUserDetails", // Use accessor for raw data
      Cell: ({ cell }) => {
        const { firstName, lastName } = cell.row.original.startShiftOEControllerUserDetails || {};
        return `${firstName || ""} ${lastName || ""}`.trim();
      },
    },
    // {
    //   Header: "End Shift",
    //   accessor: "shiftEndDateTime",
    //   // Cell: ({ cell }) => (
    //   //   <Typography
    //   //     sx={{
    //   //       fontSize: "inherit",
    //   //       fontWeight: "inherit",
    //   //       color: "inherit",
    //   //     }}
    //   //   >
    //   //     {cell.value !== null ? moment(cell.value).format("hh:mm a") : null}
    //   //   </Typography>
    //   // ),
    // },
    {
      Header: "End Shift",
      accessor: "shiftEndDateTime",
      Cell: ({ cell }) => (
        <Typography
          sx={{
            fontSize: "inherit",
            fontWeight: "inherit",
            color: "inherit",
          }}
        >
          {cell.value
            ? moment(cell.value).format("YYYY-MM-DD hh:mm a") : null }
        </Typography>
      ),
    },
    {
      Header: "OE/Controller",
      accessor: "endShiftOEControllerUserDetails",
      Cell: ({ cell }) => {
        const { firstName, lastName } = cell.row.original.endShiftOEControllerUserDetails || {};
        return `${firstName || ""} ${lastName || ""}`.trim();
      },
    },
    {
      accessor: "id",
      Cell: ({ cell }) => (
        <Box
          sx={{
            whiteSpace: "nowrap",
            display: "flex",
            justifyContent: "center",
            width: "100%",
            alignItems: "center",
          }}
        > 

          <Button
            disabled={cell.row.original.shiftEndDateTime !== null||roleAccess.oeController}
            onClick={() =>
              handleShift(
                "shiftEndDateTime",
                cell.row.original.id,
                cell.row.original.shiftStartDateTime,
                cell.row.original.permitHolderApprovedAt,
                cell.row.original.oeControllerId,
              )
            }
            sx={{
              color:
                cell.row.original.shiftEndDateTime !== null
                  ? "default"
                  : commonCss.red,
            }}
          >
            Stop
          </Button>
        </Box>
      ),
    },
  ];

  const handlePrecaution = async () => {
    if (editPrecaution.bol) {
      const putData = {
        formId: data.id,
        addedBy: login.userId,
        ...getPrecaution,
      };
      let updatePrecaution = api.additionalPrecaution.edit;
      await axiosPrivate
        .put(`${updatePrecaution}/${editPrecaution.i}`, putData)
        .then((res) => {
          getAdditionPrecaution();
          setEditPrecaution({ bol: false, i: null });
          setGetPrecaution({
            precautionSpecified: "",
            roleName:""
          });
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      let addPrecaution = api.additionalPrecaution.add;
      const postData = {
        formId: data.id,
        addedBy: login.userId,
        ...getPrecaution,
      };
      await axiosPrivate
        .post(`${addPrecaution}`, postData)
        .then((res) => {
          getAdditionPrecaution();
          setEditPrecaution({ bol: false, i: null });
          setGetPrecaution({
            precautionSpecified: "",
            roleName: "",
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  // const handleShift = async (
  //   type,
  //   id,
  //   shiftStartDateTime,
  //   permitHolderApprovedAt
  // ) => {
  //   if (type === "shiftEndDateTime") {
  //     console.log("Ending Shift...");
  //     console.log("mypayloadupdate", payloadUpdate);
  //     let payloadUpdate = [...shiftData];
  //     const shiftData = {
  //       shiftEndDateTime: moment().toISOString(),
  //       isShiftClosed: true,
  //       formId: searchParams.get("id"),
  //       oeControllerId: login.userId,
  //       shiftStartDateTime,
  //       permitHolderApprovedAt,
  //       id,
  //     };
  //     setShiftData(payloadUpdate);
  //   } else {
  //     console.log("Starting Shift...");
  //     const newShift = {
  //       date: moment().format("YYYY-MM-DD"),
  //       start: moment().format(""),
  //       startPH: "Permit Holder",
  //       startOE: "OE",
  //       end: null,
  //       endPH: "",
  //       endOE: "",
  //     };

  //     setShiftData([...shiftData, newShift]);

  //     const updatesps = api.PTWPermitShift.edidt;

  //     setLoading(true);
  //     await axiosPrivate
  //       .put(`${updatesps}/${id}`, shiftData) // Use PUT and pass the ID
  //       .then((response) => {
  //         console.log("Shift ended successfully:", response.data);
  //         getPermitshiftData(); // Refresh the data
  //       })
  //       .catch((e) => {
  //         console.error("Error ending shift:", e);
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //     const payload = {
  //       formId: searchParams.get("id"),
  //       shiftStartDateTime: moment().toISOString(),
  //       permitHolderApprovedAt: moment().toISOString(),
  //       shiftEndDateTime: null,
  //       oeControllerId: login.userId,
  //       oeControllerApprovedAt: moment().toISOString(),
  //       isShiftClosed: false,
  //     };
  //     console.log("mypayload", payload);
  //     setLoading(true);
  //     let permiteshift = api.PTWPermitShift.addd;
  //     await axiosPrivate
  //       .post(`${permiteshift}`, payload)
  //       .then((response) => {
  //         console.log(response.data);
  //         getPermitshiftData();
  //       })
  //       .catch((e) => {
  //         console.error(e);
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   }
  // };
  const handleShift = async (
    type,
    id,
    shiftStartDateTime,
    permitHolderApprovedAt,
    oeControllerId,
  ) => {
    if (type === "shiftEndDateTime") {
      console.log("Ending Shift...");

      const payloadUpdate = {
        shiftEndDateTime: moment().toISOString(),
        isShiftClosed: true,
        formId: searchParams.get("id"),
        endShiftOEControllerId: login.userId,
        endShiftOEControllerApprovedAt:moment().toISOString(),
        isShiftClosed: true,
        shiftStartDateTime,
        permitHolderApprovedAt,
        oeControllerId,
        id,
      };

      console.log("mypayloadupdate", payloadUpdate);
      const updatesps = api.PTWPermitShift.edit;
      await axiosPrivate
        .put(`${updatesps}/${id}`, payloadUpdate)
        .then((response) => {
          console.log("Shift ended successfully:", response.data);
          getPermitshiftData();
        })
        .catch((e) => {
          toast.error(e.response.data.message)
          console.error("Error ending shift:", e);
        })
        
    } else if (type === "start") {
      const payload = {
        formId: searchParams.get("id"),
        shiftStartDateTime: moment().toISOString(),
        permitHolderApprovedAt: moment().toISOString(),
        shiftEndDateTime: null,
        oeControllerId: login.userId,
        oeControllerApprovedAt: moment().toISOString(),
        isShiftClosed: false,
      };

      console.log("mypayload", payload);
      let permiteshift = api.PTWPermitShift.add;
      await axiosPrivate
        .post(`${permiteshift}`, payload)
        .then((response) => {
          console.log(response.data);
          getPermitshiftData();
        })
        .catch((e) => {
          console.error(e);
        })
        
    }
  };

  const getPermitshiftData = async () => {
   // setLoading(true);
    const getpsdata = api.PTWPermitShift.getByFormId;
    await axiosPrivate
      .get(`${getpsdata}?formId=${searchParams.get("id")}`)
      .then((res) => {
        const formattedData = res.data.data.map((item) => ({
          ...item,
          date: moment(item.date).format("YYYY-MM-DD"),
        }));
        setPSData(formattedData);
        console.log("PSData", formattedData);
      })
      .catch((e) => {
        setPSData([]);
        console.error(e);
      });
    //setLoading(false);
  };


    const handlePermitClose=async()=>{
      const permitCloseLink=api.PTWForm.permitClose
      const postData={
          id: data.id,
          verifierId: login.userId,
          isApproved: true,
          isClosed: true
      }
      console.log(postData)
      const filterPsData=PSData.filter(fil=>fil.isShiftClosed===false)
      // if(filterPsData.length===0){
      await axiosPrivate.post(`${permitCloseLink}`,postData).then(res=>{
        toast.success("Permit Closed")
        navigate("/formlist")
      }).catch(e=>{
        toast.error(e.response.data.message)
        console.log(e)
      })
      // }else{
      //   toast.error("Please Stop Shift")
      // }
    }
 return(
    <>
    {loading?<Loading/>:
   
      <Formik initialValues={initialValue} onSubmit={onSubmit} enableReinitialize>
        {({ handleChange, setFieldValue, values, resetForm }) => (
          <Form>
       
       <Grid2 container spacing={"0.6rem"} >
      
      <Grid2 container size={12} spacing={"1rem"}>
         <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          Precautions for the work (in addition to those overleaf, and stated in associated certificates)
          </Typography>
          </Grid2>
          <Grid2 container size={12} alignItems={"center"} spacing={"1rem"}>
          <Grid2 size={{xs:12,md:6}}>
            <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
              Specify additional precautions
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={getPrecaution.precautionSpecified}
              slotProps={{
                input:{
                  readOnly:roleAccess.engineerPILPI
                }
              }}
              onChange={(e)=>{
                setGetPrecaution({...getPrecaution,precautionSpecified:e.target.value})
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: roleAccess.engineerPILPI?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12,md:5}}>
          <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
              LPI/PI/Engineer
              </Typography>
            </InputLabel>
            <Select
                size="small"
                value={getPrecaution.roleName}
                readOnly={roleAccess.engineerPILPI}
              onChange={(e)=>{
                setGetPrecaution({...getPrecaution,roleName:e.target.value})
              }}
                sx={{
                  backgroundColor: roleAccess.engineerPILPI?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor,  
                  borderRadius:commonCss.inputBorderRadius,
                
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
                }}
                fullWidth
              >
                <MenuItem value="Engineer">Engineer</MenuItem>
                <MenuItem value="LPI">LPI</MenuItem>
                <MenuItem value="PI"> PI</MenuItem>
              </Select>
          </Grid2>
          <Grid2 size={{xs:12,md:1}}>
         <IconButton disabled={roleAccess.engineerPILPI} sx={{border:`3px solid ${commonCss.red}`,p:0,mt:1.5}} onClick={handlePrecaution} >
            <Add sx={{color:commonCss.red}}/>
         </IconButton>
          </Grid2>
          <Grid2 size={{xs:8}} sx={{display:precautionsData.length===0?"none":"default"}}>
            <Paper sx={{background:"transparent"}}>
            <NormalTableWithRadius data={precautionsData} columns={precautionsColumn}/>
            </Paper>
          </Grid2>
          <Grid2 size={12}>
          <Typography  variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none",lineHeight:1.5}}>
          I certify that I have fully considered the implications of the work in line with the responsibilities detailed in the permit to work procedure and that the above precautions are adequate for the work to be carried out provided that the precautions are established and maintained.
            </Typography>
          </Grid2>
          
          <Grid2 container size={{xs:12}}  alignItems={"center"} spacing={"1rem"} sx={{display:values.isEngineerAdviseRequied&&!reAssign.engineer?"default":"none"}}> 
           <Grid2 size={{xs:12,md:4}}>
          <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
                Engineer Name
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={holderName.engineer}
              slotProps={{
                input:{
                  readOnly:true
                }
              }}
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor:roleAccess.engineer? commonCss.readOnlyBGColor:commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                Date and Time
              </Typography>
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoItem>
                <DateTimePicker
                  ampm={false}
                  format="DD/MM/YYYY HH:mm"
                  value={values.isEngineerApproved?dayjs(values.engineerApprovedAt):null}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  slotProps={{
                    textField: {
                      size: "small",
                      variant: "outlined",
                    },
                  }}
                  readOnly
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor:roleAccess.engineer? commonCss.readOnlyBGColor:commonCss.inputBackgroundColor, 
                      borderRadius:commonCss.inputBorderRadius
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                     borderColor: commonCss.inputBorderColor, 
                    },
                  }}
                  fullWidth
                />
              </DemoItem>
            </LocalizationProvider>
          </Grid2> 
          <Grid2 size={{xs:12,md:4}} sx={{display:roleAccess.engineer?"none":"default"}}>
          <Box sx={{display:"flex",gap:"0.6rem",alignItems:"center"}}>
          <Button
                        
                        variant="contained"
                        sx={{
                          backgroundColor: commonCss.red,
                          borderRadius: commonCss.buttonBorderRadius,
                          textTransform: "none",
                          padding: "0.6rem 1rem",
                          color: commonCss.buttonTextColor,
                          width: "15vw",
                          display:roleAccess.adminEngineer?"default":"none"
                        }}
                        onClick={() => {
                          setAssign({...reAssign,engineer:true})
                        }}
                      >
                        Reassign
                      </Button>
                      <Button
            variant="contained"
             sx={{
               backgroundColor: commonCss.red,
               borderRadius: commonCss.buttonBorderRadius,
               textTransform: "none",
               padding: "0.6rem 1rem",
               color: commonCss.buttonTextColor,
               width: "15vw",
             }}
             onClick={() => {
               rejectApproval()
             }}
           >
             Reject
             </Button>
            <Button
            variant="contained"
            type='submit'
            sx={{
              backgroundColor:commonCss.green,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw",
              height:"6vh"
            }}
            
          >
            Approve
          </Button>
          
          </Box>
          </Grid2>
          </Grid2>
          <Grid2 container size={12} sx={{display:reAssign.engineer?'default':"none"}}>
          <Grid2 size={{xs:12,md:4}}>
          <Autocomplete
                  readOnly={!roleAccess.adminEngineer}
                  name="engineerId"
                  inputValue={autoCompleteValue.engineer}
                  filterOptions={filterOptions}
                  loading={engineerData.length === 0 }
                  options={engineerData}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) => option.firstName+" "+option.lastName || option}
                  onChange={(event, value) => {
                    if (value) {
                      setReAssignData({...reAssignData,engineer:{...reAssignData.engineer,engineerId:value.id}})
                      setAutoCompleteValue({...autoCompleteValue,engineer:value.firstName+" "+value.lastName});
                    } else {
                      setReAssignData({...reAssignData,engineer:{...reAssignData.engineer,engineerId:null}})
                      setAutoCompleteValue({...autoCompleteValue,reAssignTech:""});
                    }
                  }}
                  // onInputChange={(e, v) => {
                  //   setAgt({...agt,agtCom:v});
                  // }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="technicianId"
                      size="small"
                      // variant="standard"
                      
                      onChange={(e)=>{setAutoCompleteValue({...autoCompleteValue,engineer:e.target.value})}}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: !roleAccess.adminEngineer?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor,
                          borderRadius:  commonCss.inputBorderRadius,
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: commonCss.inputBorderColor,
                        },
                      }}
                      
                    />
                  )}
                />
          </Grid2>
          <Grid2 size={{xs:12,md:4}}>
            <Box sx={{display:"flex",gap:"0.6rem",alignItems:"center"}}>
            <Button
            variant="contained"
             sx={{
               backgroundColor: commonCss.green,
               borderRadius: commonCss.buttonBorderRadius,
               textTransform: "none",
               padding: "0.6rem 1rem",
               color: commonCss.buttonTextColor,
               width: "15vw",
             }}
             onClick={() => {
               setAssign({...reAssign,engineer:false})
               setAutoCompleteValue({...autoCompleteValue,engineer:""})
               setReAssignData({...reAssignData,engineer:{formId:data.id,technicianId:null}})
             }}
           >
             Cancel
             </Button>
             <Button
                        
                          variant="contained"
                          sx={{
                            backgroundColor: commonCss.red,
                            borderRadius: commonCss.buttonBorderRadius,
                            textTransform: "none",
                            padding: "0.6rem 1rem",
                            color: commonCss.buttonTextColor,
                            width: "15vw",
                          }}
                          onClick={() => {
                            
                            handleReassign()
                          }}
                        >
                          Submit
              </Button>
            </Box>
          
          </Grid2>
         
        </Grid2>
          <Grid2 container size={{xs:12}}  alignItems={"center"} spacing={"1rem"}> 
           <Grid2 size={{xs:12,md:4}}>
          <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
                Operation Manager
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={holderName.om}
              slotProps={{
                input:{
                  readOnly:true
                }
              }}
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor:roleAccess.om? commonCss.readOnlyBGColor:commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                Date and Time
              </Typography>
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoItem>
                <DateTimePicker
                  ampm={false}
                  format="DD/MM/YYYY HH:mm"
                  value={values.isOperationMangerApproved?dayjs(values.operationMangerApprovedAt):null}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  slotProps={{
                    textField: {
                      size: "small",
                      variant: "outlined",
                    },
                  }}
                  readOnly
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor:roleAccess.om? commonCss.readOnlyBGColor:commonCss.inputBackgroundColor, 
                      borderRadius:commonCss.inputBorderRadius
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                     borderColor: commonCss.inputBorderColor, 
                    },
                  }}
                  fullWidth
                />
              </DemoItem>
            </LocalizationProvider>
          </Grid2> 
          <Grid2 size={{xs:12,md:4}} sx={{display:roleAccess.om?"none":"default"}}>
            <Box sx={{display:"flex",gap:"0.6rem",alignItems:"flex-end"}}>
            <Button
            variant="contained"
             sx={{
               backgroundColor: commonCss.red,
               borderRadius: commonCss.buttonBorderRadius,
               textTransform: "none",
               padding: "0.6rem 1rem",
               color: commonCss.buttonTextColor,
               width: "15vw",
             }}
             onClick={() => {
               rejectApproval()
             }}
           >
             Reject
             </Button>
             <Button
            variant="contained"
            type='submit'
            sx={{
              backgroundColor:commonCss.green,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw",
              height:"6vh"
            }}
            
          >
            Approve
          </Button>
            </Box>
            
            
          </Grid2>
          </Grid2>
        </Grid2>
         
          </Grid2>
      <Grid2 container size={12} spacing={"1rem"}>
        <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          Permit Authorized By
          </Typography>
          </Grid2>
         
          <Grid2  size={4}>
            <Box sx={{width:"100%"}}>
              <Grid2 container size={12} spacing={"0.5rem"}>
              <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.green}>
          HSSE VERIFIER
          </Typography>
              </Grid2> 
              <Grid2 size={12}>
                <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none"}}>
                Verified all the HSSE requirement in place including JMS and JSA
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    Name
                  </Typography>
                </InputLabel>
                <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={holderName.hsse}
              slotProps={{
                input:{
                  readOnly:true
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: roleAccess.hsse?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    Date/Time
                  </Typography>
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DateTimePicker
                      ampm={false}
                      format="DD/MM/YYYY HH:mm"
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      value={values.isHSSEApproved?dayjs(values.hsseVerifierApprovedAt):null}
                      readOnly
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "outlined",
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: commonCss.inputBorderRadius,
                          backgroundColor:roleAccess.hsse?commonCss.readOnlyBGColor:  commonCss.inputBackgroundColor,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
                      }}
                      fullWidth
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid2>
              <Grid2 size={{xs:12}} sx={{display:roleAccess.hsse?"none":"default"}}>
            <Button
            variant="contained"
             sx={{
               backgroundColor: commonCss.red,
               borderRadius: commonCss.buttonBorderRadius,
               textTransform: "none",
               padding: "0.6rem 1rem",
               color: commonCss.buttonTextColor,
               width: "15vw",
             }}
             onClick={() => {
               rejectApproval()
             }}
           >
             Reject
             </Button>
          
          
            
          </Grid2>
              <Grid2 size={{xs:12}} sx={{display:roleAccess.hsse?"none":"default"}}>
            
             <Button
            variant="contained"
            type='submit'
            sx={{
              backgroundColor:commonCss.green,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw",
              height:"6vh"
            }}
            
          >
            Approve
          </Button>
          
            
          </Grid2>
              </Grid2>
            </Box>
          </Grid2>
          <Grid2  size={4}>
            <Box sx={{width:"100%"}}>
              <Grid2 container size={12} spacing={"0.5rem"}>
              <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.green}>
          PERMIT ISSUER (PI)
          </Typography>
              </Grid2> 
              <Grid2 size={12}>
                <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none"}}>
                Authorised for execution without additional certificates
                </Typography>
              </Grid2>
              <Grid2 container size={12} mt={"1.25rem"} >
              <Grid2 size={{ xs: 12 }}>
                <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    Name
                  </Typography>
                </InputLabel>
                <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={holderName.pi}
              slotProps={{
                input:{
                  readOnly:true
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: roleAccess.pi?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    Date/Time
                  </Typography>
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DateTimePicker
                      ampm={false}
                      format="DD/MM/YYYY HH:mm"
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      value={values.isPermitIssuerApproved?dayjs(values.permitIssuerApprovedAt):null}
                      readOnly
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "outlined",
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: commonCss.inputBorderRadius,
                          backgroundColor:roleAccess.pi?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
                      }}
                      fullWidth
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    From
                  </Typography>
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DatePicker
                      ampm={false}
                      format="DD/MM/YYYY"
                    
                      value={values.piPermitValidFrom!==null?dayjs(values.piPermitValidFrom):null}
                      readOnly={roleAccess.pi}
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "outlined",
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: commonCss.inputBorderRadius,
                          backgroundColor: roleAccess.pi?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
                      }}
                      onChange={(newValue) => {
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d)
                            // .format(
                            //   "YYYY-MM-DDTHH:mm:ss"
                            // );
                            setFieldValue("piPermitValidFrom",dateFrom)
                            
                            // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                            // setFieldValue("installationDate", dateFormat);
                          }
                        }
                      }}
                      fullWidth
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                   Until
                  </Typography>
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DatePicker
                      ampm={false}
                      format="DD/MM/YYYY"
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "outlined",
                        },
                      }}
                      value={values.piPermitValidUpTo!==null?dayjs(values.piPermitValidUpTo):null}
                      onChange={(newValue) => {
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d)
                            // .format(
                            //   "YYYY-MM-DDTHH:mm:ss"
                            // );
                            setFieldValue("piPermitValidUpTo",dateFrom)
                            
                            // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                            // setFieldValue("installationDate", dateFormat);
                          }
                        }
                      }}
                      readOnly={roleAccess.pi}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: commonCss.inputBorderRadius,
                          backgroundColor: roleAccess.pi?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
                      }}
                      fullWidth
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid2>
              <Grid2 size={{xs:12}} sx={{display:roleAccess.pi?"none":"default"}}>
              <Button
            variant="contained"
             sx={{
               backgroundColor: commonCss.red,
               borderRadius: commonCss.buttonBorderRadius,
               textTransform: "none",
               padding: "0.6rem 1rem",
               color: commonCss.buttonTextColor,
               width: "15vw",
             }}
             onClick={() => {
               rejectApproval()
             }}
           >
             Reject
             </Button>
              </Grid2>
              <Grid2 size={{xs:12}} sx={{display:roleAccess.pi?"none":"default"}}>
            <Button
            variant="contained"
            type='submit'
            sx={{
              backgroundColor:commonCss.green,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw",
              height:"6vh"
            }}
            
          >
            Approve
          </Button>
            
          </Grid2>
              </Grid2>
              </Grid2>
            </Box>
          </Grid2>
          <Grid2  size={4}>
            <Box sx={{width:"100%"}}>
              <Grid2 container size={12} spacing={"0.5rem"}>
              <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.green}>
          LEAD PERMIT ISSUER (LPI)
          </Typography>
              </Grid2> 
              <Grid2 size={12}>
                <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none"}}>
                Authorised for execution only in accordance with attached certificate
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    Name
                  </Typography>
                </InputLabel>
                <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={holderName.lpi}
              slotProps={{
                input:{
                  readOnly:true
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor:roleAccess.lpi?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    Date/Time
                  </Typography>
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DateTimePicker
                      ampm={false}
                      format="DD/MM/YYYY HH:mm"
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      readOnly
                      value={values.isLeadPermitIssuerApproved?dayjs(values.leadPermitIssuerApprovedAt):null}
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "outlined",
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: commonCss.inputBorderRadius,
                          backgroundColor:roleAccess.lpi?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
                      }}
                      fullWidth
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    From
                  </Typography>
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DatePicker
                      ampm={false}
                      format="DD/MM/YYYY"
                      value={values.lpiPermitValidFrom!==null?dayjs(values.lpiPermitValidFrom):null}
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "outlined",
                        },
                      }}
                      readOnly={roleAccess.lpi}
                      onChange={(newValue) => {
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d)
                            // .format(
                            //   "YYYY-MM-DDTHH:mm:ss"
                            // );
                            setFieldValue("lpiPermitValidFrom",dateFrom)
                            
                            // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                            // setFieldValue("installationDate", dateFormat);
                          }
                        }
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: commonCss.inputBorderRadius,
                          backgroundColor: roleAccess.lpi?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
                      }}
                      fullWidth
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                   Until
                  </Typography>
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DatePicker
                      ampm={false}
                      format="DD/MM/YYYY"
                      readOnly={roleAccess.lpi}
                      value={values.lpiPermitValidUpTo!==null?dayjs(values.lpiPermitValidUpTo):null}
                      onChange={(newValue) => {
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d)
                            // .format(
                            //   "YYYY-MM-DDTHH:mm:ss"
                            // );
                            setFieldValue("lpiPermitValidUpTo",dateFrom)
                            
                            // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                            // setFieldValue("installationDate", dateFormat);
                          }
                        }
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "outlined",
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: commonCss.inputBorderRadius,
                          backgroundColor:roleAccess.lpi?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
                      }}
                      fullWidth
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid2>
              <Grid2 size={{xs:12}} sx={{display:roleAccess.lpi?"none":"default"}}>
              <Button
            variant="contained"
             sx={{
               backgroundColor: commonCss.red,
               borderRadius: commonCss.buttonBorderRadius,
               textTransform: "none",
               padding: "0.6rem 1rem",
               color: commonCss.buttonTextColor,
               width: "15vw",
             }}
             onClick={() => {
               rejectApproval()
             }}
           >
             Reject
             </Button>
              </Grid2>
              <Grid2 size={{xs:12}} sx={{display:roleAccess.lpi?"none":"default"}}>
            <Button
            variant="contained"
            type='submit'
            sx={{
              backgroundColor:commonCss.green,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw",
              height:"6vh"
            }}
            
          >
            Approve
          </Button>
            
          </Grid2>
              </Grid2>
            </Box>
          </Grid2>
        </Grid2>
        <Grid2 container size={12} spacing={"1rem"}>
        <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          Permit validity (where work is going to last more than the duration of a day)
          </Typography>
          </Grid2>
          <Grid2 size={12}>
                <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none"}}>
                Permit issuer & Permit Holder confirm by signing that the precautions required to work safely have been complied with prior to starting work each day or work period. At the close of each day, the Permit Issuer & Permit Holder sign to confirm that the area has been left in a safe condition (although it is still not an operational area, i.e. it has been handed back) and that all staff have left the site.
                </Typography>
        </Grid2>
        <Grid2 container size={12} alignItems={"center"} spacing={"1rem"}>
            
            
          <Grid2 size={{xs:12,md:3}}>
        
          <Button
            variant="contained"
            disabled={roleAccess.oeController}
            sx={{
              backgroundColor:commonCss.red,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw"
            }}
            onClick={()=>{handleShift("start")}}
          >
           Start Shift
          </Button>
          </Grid2>
          <Grid2 size={{xs:12}} sx={{display:PSData.length===0?"none":"default"}}>
            <Paper sx={{background:"transparent"}}>
            <NormalTableWithRadius data={PSData} columns={shiftColumns}/>
            </Paper>
          </Grid2>
        
        </Grid2>
          </Grid2>
          <Grid2 container size={12} spacing={"1rem"}>
        <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          PERMIT CLOSE OUT
          </Typography>
          </Grid2>
          <Grid2  size={4}>
            <Box sx={{width:"100%"}}>
              <Grid2 container size={12} spacing={"0.5rem"}>
              <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.green}>
          PERMIT HOLDER           
          </Typography>
              </Grid2> 
              <Grid2 size={12}>
                <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none"}}>
                Close Out – Work Completed
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    Name
                  </Typography>
                </InputLabel>
                <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={holderName.phClose}
              slotProps={{
                input:{
                  readOnly:true
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: roleAccess.phClose?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    Date/Time
                  </Typography>
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DateTimePicker
                      ampm={false}
                      format="DD/MM/YYYY HH:mm"
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      readOnly
                      value={values.isPHPermitClosed?dayjs(values.permitClosedByHolderAt):null}
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "outlined",
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: commonCss.inputBorderRadius,
                          backgroundColor: roleAccess.phClose?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
                      }}
                      fullWidth
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid2>
              
              <Grid2 container size={{xs:12}} justifyContent={"flex-start"} sx={{display:roleAccess.phClose?"none":"default"}}>
              <Button
            variant="contained"
            sx={{
              backgroundColor:commonCss.green,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw"
            }}
            onClick={handlePermitClose}
          >
           Close
          </Button>
              </Grid2>
              </Grid2>
            </Box>
          </Grid2>
          <Grid2  size={4}>
            <Box sx={{width:"100%"}}>
              <Grid2 container size={12} spacing={"0.5rem"}>
              <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.green}>
          PERMIT ISSUER / LEAD PERMIT ISSUER        
          </Typography>
              </Grid2> 
              <Grid2 size={12}>
                <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none"}}>
                Signing Off – Accepting completion of the work
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    Name
                  </Typography>
                </InputLabel>
                <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={holderName.lpiAndPiClose}
              slotProps={{
                input:{
                  readOnly:true
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor:roleAccess.piAndLpoClose?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    Date/Time
                  </Typography>
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DateTimePicker
                      ampm={false}
                      format="DD/MM/YYYY HH:mm"
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      readOnly
                      value={values.permitClosedByLPIId!==null?dayjs(values.permitClosedByLPIAt):null}
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "outlined",
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: commonCss.inputBorderRadius,
                          backgroundColor:roleAccess.piAndLpoClose?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
                      }}
                      fullWidth
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid2>
              
              <Grid2 container size={{xs:12}} justifyContent={"flex-start"} sx={{display:roleAccess.piAndLpoClose?"none":"default"}}>
              <Button
             // type={values.isLeadPermitIssuerApproved&&values.isPermitIssuerApproved?"submit":"button"}
            variant="contained"
            sx={{
              backgroundColor:commonCss.green,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw"
            }}
            onClick={handlePermitClose}
          >
           Close
          </Button>
              </Grid2>
              </Grid2>
            </Box>
          </Grid2>
        
        </Grid2>
        
       </Grid2>
          </Form>
        )}
        
          
         
       </Formik>
  }
  </>
 )
}

export default PTWForm2;
