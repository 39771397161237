
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   loginInfo:{},
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginDetail: (state,actions) => {
      state.loginInfo = actions.payload
    },
    updateAccessToken: (state, actions) => {
      state.loginInfo.jwtToken = actions.payload.jwtToken;
      state.loginInfo.refreshToken = actions.payload.refreshToken; 
    },
    logout: (state) => {
      state.loginInfo = {}; 
    },


   
  },
})

 export const { loginDetail } = authSlice.actions
 export const { updateAccessToken } = authSlice.actions
 export const { logout } = authSlice.actions

export const authReducers= authSlice.reducer