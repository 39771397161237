import React from "react";
import Chart from "react-apexcharts";

const PermitByStatusDaily = ({data}) => {
    console.log(data)
    const state = {
          
        series: data?.permitByStatusDateWise?.yAxis,
        options: {
          chart: {
            type: 'bar',
            // height: 350,
          
            stacked: true,
          
          },
          colors: [
            "#1395CF",
            "#EAC644",
            "#5DB75E",
            "#EA3922",
            "#98D19A",
            "#D77A22",
            "#F9ADA8",
            "#A76CFB",
            "#A71D48",
            "#FE8B7E",
            "#453191",
            "#B4BFDD",
            "#3D5EAC",
            "#62B146",
            "#D0DD37",
            "#F3EC3A",
            "#F9BD19",
            "#F99B1E",
            "#F15523",
            "#7C3697"
          ],
          plotOptions: {
            bar: {
              horizontal: true,
              // dataLabels: {
              //   total: {
              //     // enabled: true,
              //     offsetX: 0,
              //     style: {
              //       fontSize: '13px',
              //       fontWeight: 900
              //     }
              //   }
              // }
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: 1,
            colors: ['#fff']
          },
          title: {
            text: 'Permit Of Work Daily'
          },
          xaxis: {
            categories: data?.permitByStatusDateWise?.xAxis,
            title:{
              text:"Count"
            }
            // labels: {
            //   formatter: function (val) {
            //     return val + "K"
            //   }
            // }
          },
          yaxis: {
            title: {
              text: "Date"
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val 
              }
            }
          },
          fill: {
            opacity: 1
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          }
        },
      
      
      };
  return (
    <Chart
      options={state.options}
      series={state.series}
      type="bar"
      height={data?.permitByStatusDateWise?.yAxis.length
            ? `${32 * data?.permitByStatusDateWise?.yAxis.length + 200}px`
            : "100%"}
      // height={400}
    />
  );
};

export default PermitByStatusDaily;
